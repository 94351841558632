import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import AudioPlayerWithVisualizer from "components/AudioPlayerWithVisualizer";

let score_L = 0;

const PartB = (props) => {
  const {
    currentPart,
    questionsData,
    currentIndex,
    setCurrentIndex,
    getAnswerAndMarksData,
    answersData
  } = props;

  let currQuestionData =
    questionsData && questionsData[currentIndex]?.questions;
  let currAnswersData = answersData?.length !== 0 ? answersData : [];
  const [questions, setQuestions] = useState([]);

  let currQuestionAudio = questionsData && questionsData[currentIndex]?.audio;
  let currQuestionType =
    questionsData && questionsData[currentIndex]?.question_type;
  const [userAnswer, setUserAnswer] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [quesNum, setQuesNum] = useState();
  const localStorageKey = `L_Data`;

  useEffect(() => {
    const savedOptions =
      JSON.parse(localStorage.getItem(localStorageKey)) || {};
    setSelectedOptions(savedOptions);
  }, []);

  useEffect(() => {
    if (selectedOptions && quesNum) {
      lMarksCalculation(userAnswer, quesNum);
    }
  }, [selectedOptions, quesNum, userAnswer]);

  const lMarksCalculation = (userInput, quesNum) => {
    if (Array.isArray(currAnswersData)) {
      currAnswersData?.forEach((element, i) => {
        if (quesNum === i + 1) {
          if (
            element
              ?.toLowerCase()
              ?.split("|")
              ?.includes(userInput.toLowerCase())
          ) {
            score_L = 1;
          } else {
            score_L = 0;
          }
        }
      });
    }

    // Calling function And Get Data in ListeningSection Main File
    getAnswerAndMarksData(
      quesNum,
      userInput,
      // htmlAnswerArray,
      score_L,
      currentPart,
      currQuestionType
    );

    // htmlAnswerArray = [];
  };

  const htmlString = `
  <span>##d#(25) </span> <div class="question-block">   
    <p class="section-description" id="25">     
      You hear an optometrist talking to a patient who’s trying contact lenses for the first time. What is the patient concerned about?   
    </p>   
    <div class="radio-group">     
      <div class="direction-option">       
        <div class="option-container">         
          <div class="option-container2">           
            <div class="option-text">A</div>           
            <div class="option-description">his blurred vision</div>         
          </div>       
        </div>     
      </div>     
      <div class="direction-option">       
        <div class="option-container">         
          <div class="option-container2">           
            <div class="option-text">B</div>           
            <div class="option-description">soreness in his eyes</div>         
          </div>       
        </div>     
      </div>     
      <div class="direction-option">       
        <div class="option-container">         
          <div class="option-container2">           
            <div class="option-text">C</div>           
            <div class="option-description">how to remove the lenses</div>         
          </div>       
        </div>     
      </div>   
    </div> 
  </div>
  <span>##d#(26) </span> <div class="question-block">   
    <p class="section-description" id="26">     
      You hear an optometrist talking to a patient who’s trying contact lenses for the first time. What is the patient concerned about?   
    </p>   
    <div class="radio-group">     
      <div class="direction-option">       
        <div class="option-container">         
          <div class="option-container2">           
            <div class="option-text">A</div>           
            <div class="option-description">his blurred vision</div>         
          </div>       
        </div>     
      </div>     
      <div class="direction-option">       
        <div class="option-container">         
          <div class="option-container2">           
            <div class="option-text">B</div>           
            <div class="option-description">soreness in his eyes</div>         
          </div>       
        </div>     
      </div>     
      <div class="direction-option">       
        <div class="option-container">         
          <div class="option-container2">           
            <div class="option-text">C</div>           
            <div class="option-description">how to remove the lenses</div>         
          </div>       
        </div>     
      </div>   
    </div> 
  </div>
`;

  useEffect(() => {
    const savedOptions =
      JSON.parse(localStorage.getItem(localStorageKey)) || {};
    setSelectedOptions(savedOptions);
  }, []);

  useEffect(() => {
    if (selectedOptions && quesNum) {
      lMarksCalculation(userAnswer, quesNum);
    }
  }, [selectedOptions, quesNum, userAnswer]);

  useEffect(() => {
    const parseQuestions = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(currQuestionData, "text/html");

      const questionBlocks = doc.querySelectorAll(".question-block");
      const parsedQuestions = Array.from(questionBlocks).map((block) => {
        const questionId = block.querySelector(".section-description").id;
        const questionText = block.querySelector(
          ".section-description"
        ).innerText;

        const options = Array.from(
          block.querySelectorAll(".direction-option")
        ).map((option) => {
          const optionText = option.querySelector(".option-text").innerText;
          const optionDescription = option.querySelector(
            ".option-description"
          ).innerText;
          return { text: optionText, description: optionDescription };
        });

        return {
          id: questionId,
          question: questionText,
          options
        };
      });

      setQuestions(parsedQuestions);
    };

    parseQuestions();
  }, [currQuestionData]);

  // Function to handle option selection
  const handleChange = (option, questionId) => {
    const updatedOptions = {
      ...selectedOptions,
      [questionId]: option.description
    };

    setUserAnswer(option.description);
    setSelectedOptions(updatedOptions);
    setQuesNum(Number(questionId));

    localStorage.setItem(localStorageKey, JSON.stringify(updatedOptions));
  };

  return (
    <div className="partB-wrapper">
      {currentIndex === 0 ? (
        <div className="container">
          <h4>Now look at PART B.</h4>
          <div className="part-intro">
            <h3>Part B</h3>
            <p className="section-description">
              In this part of the test, you'll hear six different extracts. In
              each extract, you'll hear people talking in a different healthcare
              setting.
            </p>
            <p className="section-description">
              For <b>questions 25-30</b>, choose the answer (<b>A, B</b> or{" "}
              <b>C</b>) which fits best according to what you hear. You'll have
              time to read each question before you listen. Complete your
              answers as you listen.
            </p>
            <p className="section-description">Now, look at question 25.</p>
          </div>
        </div>
      ) : null}
      <div>
        <AudioPlayerWithVisualizer audioSrc={currQuestionAudio} />
      </div>
      {questions?.map((question, index) => (
        <div key={index} className="question-block">
          <p className="section-description">
            {question?.id} {question?.question}
          </p>
          <div className="radio-group">
            {question?.options?.map((option, key) => (
              <div key={key} className="direction-option">
                <div className="letter">{option.text}</div>
                <div
                  className={`option-container ${
                    selectedOptions[question?.id] === option.description
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleChange(option, question.id)}
                >
                  <div
                    className={`option-container2 ${
                      selectedOptions[question?.id] === option.description
                        ? "selected"
                        : ""
                    }`}
                  >
                    {option.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="last-container">
        <p className="section-description">
          That is the end of Part B.
          <br />
          You will have the remaining Section time to check your answers.
        </p>
      </div>
    </div>
  );
};

export default PartB;
