import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import PartA from "components/WritingSectionQType/PartA";
// import PartB from "components/WritingSectionQType/PartB";
import { AppContext } from "AppContext";
import Footer from "components/Footer";
import CustomAlert from "components/Alert";
import advancedOETDatabase from "db";
import { useLiveQuery } from "dexie-react-hooks";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import AppLoader from "components/CustomLoader";
import PdfViewer from "components/PdfViewer";
import ResumeTest from "components/ResumeTest";
import {
  getAllQuestionsList,

  getAllQuestionsWTemp
} from "../../../redux/action/QuestionAction";
import {
  isTextValid,
  NameRemoveErrorFromArry,
  ParseFloat,
  roundHalf,
  writingGrammarRangeScoreCalculation,
  writingGrammarScoreCalculation,
  writingLexicalResourceScoreCalculation
} from "utilities/CommonFunc";
import axios from "axios";
import { defaultMessages } from "utilities/Messages";
import {
  postAllQuestionsList,
  submitQuestionAnswer
} from "../../../redux/action/AnswerAction";
// import PartC from "components/WritingSectionQType/PartC";

let score_W = 0;
let errorCount = 0;
let grammarError = 0;
let spellingError = 0;
let userAns = "";
let grammarScore = 0;
let spellingScore = 0;
let vocabularyScore = 0;
let writingScore = 0;
let lexicalResourceScore = 0;
let grammar_range = 0;
let punctuationScore = 0;
let score_Wri = 0;
let form = 0;

const WritingSection = (props) => {
  const [userAnswer, setUserAnswer] = useState("");
  const [htmlAnswer, setHtmlAnswer] = useState("");
  const [quesNum, setQuesNum] = useState();
  const [currentPart, setCurrentPart] = useState("PART_A");
  const [answersData, setAnswersData] = useState([]);

  // Get Writing Test Answer of IDB
  const getAnswerWritingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.writingTestAnswer.toArray(),
    []
  );

  // Get Writing Test Question of IDB
  const getQuestionsWritingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.writingTestQuestions.toArray(),
    []
  );
  // LocalStorage variable
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [statusOfShowsections, setStatusOfShowsections] = React.useState("");

  // Props variable
  const {} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Context state variable
  const { contextState, handleDefaultValues } = useContext(AppContext);

  // Get questions Writing  Test Data of IDB

  const stateFormageRelodeVariable = JSON.parse(localStorage.getItem("reload"));

  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [grammarResponseStatus, setGrammarResponseStatus] =
    React.useState(false);
  // const section = "Writing";
  const sectionTime = "Section";
  const [questionsData, setQuestionsData] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  // For Handle session alert modal
  const [apiType, setApiType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");

  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultTestStatus, setDefaultTestStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentSection, setcurrentSection] = useState("WritingSection");
  const [currSection, setcurrSection] = useState("Writing");
  const [nextSection, setnexttSection] = useState("SpeakingSection");
  const [extractIndex, setExtractIndex] = useState(0);
  const [showQuationPart, setShowQuationPart] = useState("");
  const [currPart, setCurrPart] = useState("PART_A");
  const [wordCount, setWordCount] = useState(0);
  const [currQuestion, setCurrQuestion] = useState("");
  const [nextAction, setNextAction] = useState(false);

  const { theme } = useContext(AppContext);

  // Speaking section test and Question time
  const [getCurrTestTime, setGetCurrTestTime] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [sessionTimeOut, setSessionTimeOut] = useState(false);

  const [sectionText, setSectionText] = useState("writing");
  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    if (nextAction === true) {
      handleNextAction();
      setNextAction(false);
    }
  }, [nextAction]);

  useEffect(() => {
    handleTestStartTime();
  }, [currentIndex, currentPage]);

  useEffect(() => {
    const writingTestDataLength = getQuestionsWritingTestDataOfDB?.length || 0;

    if (getQuestionsWritingTestDataOfDB && writingTestDataLength === 0) {
      setLoading(true);
      getQuestionsApi("writing", userInfo?.testStatus);
    }
  }, [getQuestionsWritingTestDataOfDB]);

  useEffect(() => {
    // if (userInfo?.type === "P") {

    if (getQuestionsWritingTestDataOfDB !== undefined) {
      let writing = getQuestionsWritingTestDataOfDB.map((item) => {
        // if (item?.data?.length === 2) {
        return item;
        // }
      });
      if (writing?.length !== 0) {
        if (writing[0]) {
          setQuestionsData(writing[0]?.writingAllQuestions);
          setAnswersData(writing[0]?.writingAllAnswers);
          setLoading(false);
        }
      }
    }
  }, [getQuestionsWritingTestDataOfDB]);

  //for reloading
  useEffect(() => {
    const storedPosition = localStorage.getItem("position");

    if (storedPosition) {
      const { typeOfPartsIndex } = JSON.parse(storedPosition);

      if (typeOfPartsIndex?.startsWith("W-")) {
        const [part, page] = typeOfPartsIndex.split("-").slice(1);
        if (part === "PART_A" && !isNaN(page)) {
          setCurrentPart(part);
          setCurrentPage(parseInt(page, 10));
        }
      }
    } else {
      setCurrentPart("PART_A");
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (currentIndex !== undefined && currentPart !== undefined) {
      const formattedPosition = `W-${currentPart}-${currentPage}`;
      localStorage.setItem(
        "position",
        JSON.stringify({
          typeOfPartsIndex: formattedPosition
        })
      );
    }
  });
  //end for reloading

  const handleNextSection = () => {
    setSessionTimeOut(true);
  };

  const addResumeQueTypeDataToIDB = async (tempObj) => {
    const newData =
      (await getAnswerWritingTestDataOfDB.length) === 0 &&
      (await getAnswerWritingTestDataOfDB.filter(
        (item) => item?.data?.id === tempObj?.data?.id
      ));
    if (newData && newData.length === 0) {
      await advancedOETDatabase.writingTestAnswer.add(tempObj);
    } else if (newData && newData.length !== 0) {
      await advancedOETDatabase.writingTestAnswer.update(
        newData[0]?.id,
        tempObj
      );
    }
  };

  const getQuestionsApi = (sectionType) => {
    const header = {
      Accept: "application/json",
      "user-id": userInfo?.id,
      "api-token": userInfo?.api_token,
      "test-token": userInfo?.test_token
    };

    let dataList = {};
    if (userInfo?.type === "M") {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        section_type: sectionType,
        part: "all",
        test_status: "P",
        internet_speed: userInfo?.internet_speed
      };
    } else if (userInfo?.type === "P") {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        section_type: sectionType,
        part: "all",
        test_status: userInfo?.testStatus,
        internet_speed: userInfo?.internet_speed
      };
    }

    setLoading(true);
    dispatch(
      getAllQuestionsList({
        header,
        dataList,
        onSuccess: (response) => {
          if (response?.data?.statusCode === 200) {
            setDefaultTestStatus(response?.data?.statusCode);
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...userInfo,
                redirect_url: response?.data?.redirect_url
              })
            );
            setLoading(false);
            advancedOETDatabase.writingTestQuestions.add(response?.data?.data);

            if (response?.data?.data?.writing?.student_answers) {
              let allResumeData = [
                ...JSON.parse(
                  response?.data?.data?.writing?.student_answers?.part_1
                ),
                ...JSON.parse(
                  response?.data?.data?.writing?.student_answers?.part_2
                )
              ];

              allResumeData.forEach(async (item, index) => {
                item["userAnswer"] = item["user_answer"];
                item["answer"] = item["user_answer"];
                delete item["user_answer"];
                try {
                  await addResumeQueTypeDataToIDB(item);

                  if (item?.currPart === "PART_A") {
                    // setUserAnswer({
                    //   userAnswer: item?.userAnswer
                    // });
                    // getAnswerAndMarksData(
                    //   (
                    //     quesNum,
                    //     userInput,
                    //     currentPart,
                    //     currentPage,
                    //     wordCount,
                    //     currQuestion
                    //   ) => {
                    //     setQuesNum(item?.quesNum);
                    //     setUserAnswer(userInput);
                    //     setCurrPart(currentPart);
                    //     setCurrentPage(currentPage);
                    //     setWordCount(wordCount);
                    //     setCurrQuestion(currQuestion);
                    //   }
                    // );
                  }
                } catch (error) {}
              });
            }

            if (
              userInfo?.testStatus === "P" &&
              response?.data?.last_question &&
              userInfo?.type === "M"
            ) {
              if (response?.data?.last_question?.split("|")[0] === "W") {
                currentIndex(
                  Number(response?.data?.last_question?.split("|")[1])
                );
              }
              setStatusOfShowsections(
                response?.data?.last_question?.split("|")[0]
              );
            } else if (userInfo?.type === "P") {
              let lastQuestion = JSON.parse(response?.data?.last_question);
              localStorage.setItem("position", JSON.stringify(lastQuestion));
              lastQuestion?.writing && userInfo?.testStatus === "P"
                ? currentIndex(Number(lastQuestion.writing))
                : currentIndex(1);
            }

            if (userInfo?.testStatus === "P" && response?.data?.last_question) {
            }
          }
          if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            setMessageAlert(defaultMessages.sessionTimeOut);
            setApiType(defaultMessages?.logoutApicall);
          }
        },
        onFailure: (error) => {
          setShowAlert(true);
          setDefaultTestStatus(599);
          setMessageAlert(error?.message);
          setApiType(`${defaultMessages?.getQuestionAPI}|${sectionType}|P`);
        }
      })
    );
  };
  // Manage starting test time
  const handleTestStartTime = () => {
    if (
      userInfo &&
      userInfo?.testStatus === "P" &&
      userInfo?.session_time?.charAt(0) === "S"
    ) {
      let getTestTime = userInfo && userInfo?.session_time.split("_");
      let splitTestTime = getTestTime && getTestTime[1].split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    } else {
      const getTimeKey = () => {
        if (currentPage === 0) return "partIntro_Ext1";
        else if (currentPage === 1) return "partA_Ext1";
      };

      if (currentPage !== undefined) {
        const getTestTime = contextState.writingSectionTime[getTimeKey()];
        if (getTestTime) {
          const splitTestTime = getTestTime.split(":");
          if (splitTestTime && splitTestTime.length === 3) {
            setCurrTestTime({
              hours: Math.floor(parseInt(splitTestTime[0])),
              minutes: Math.floor(parseInt(splitTestTime[1])),
              seconds: Math.floor(parseInt(splitTestTime[2]))
            });
            setLoading(false);
          } else {
            console.error("Invalid test time format");
          }
        } else {
          console.error("Test time not found");
        }
      }
    }
  };

  const handleNextAction = async (loader) => {
    setShowAlert(false);
    let PART_A = [];
    let totalScore = 0;
    let band = 0;
    let r_part_A = 0;

    (await getAnswerWritingTestDataOfDB) &&
      getAnswerWritingTestDataOfDB.filter((element, index) => {
        totalScore += element?.score;

        if (element?.currPart === "PART_A") {
          r_part_A += element?.score;
          PART_A.push({
            id: element?.id,
            user_answer: element?.userAnswer,
            score: element?.score,
            question_type: element?.queType,
            currPart: element?.currPart
          });
        }
      });

    // band = listeningScoreCalculation(totalScore);

    let newObject = {};
    // if (userInfo?.type === "M") {
    newObject = {
      // last_question: `L|${selectedIndex}`,
      writting: {
        status: loader === "save_and_exit" ? "P" : "C",
        module_time: currTestTime,
        // answer_data: {
        //   PART_A,
        //   PART_B,
        //   PART_C
        // },
        answer_data: { PART_A },
        score_data: {
          band: band,
          listening_score: totalScore,
          r_part_A: r_part_A
        }
      }
    };
    // }
    // } else {
    //   newObject = {
    //     last_question: { ...positionOfquestions, listening: selectedIndex },
    //     listening: {
    //       audio_time: currentTime.toFixed(2),
    //       status: loader === "save_and_exit" ? "P" : "C",
    //       listening_module_time: userInfo?.session_time,
    //       answer_data: {
    //         PART_A,
    //         PART_B,
    //         PART_C,
    //         PART_D
    //       },
    //       score_data: {
    //         band: band,
    //         correct_answers: totalScore
    //       }
    //     }
    //   };
    // }

    sectionEndApi(newObject, loader);
  };

  const sectionEndApi = (data, loader) => {
    if (loader === "loader" || loader === "save_and_exit") {
      setLoading(true);
    }

    const header = {
      Accept: "application/json",
      "user-id": userInfo?.id,
      "api-token": userInfo?.api_token,
      "test-token": userInfo?.test_token
    };
    let dataList = {};
    if (userInfo?.type === "M") {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        part: "all",
        data: data,
        test_status: "P",

        section_type: "writing",
        resume: loader === "save_and_exit" ? true : false,
        is_continue: loader === "save_and_exit" ? false : true
      };
    } else {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        part: "all",
        data: data,
        test_status: "C",
        section_type: "writing",
        is_continue: loader === "save_and_exit" ? false : true
      };
    }
    dispatch(
      submitQuestionAnswer({
        header,
        dataList,
        onSuccess: (response) => {
          if (response?.data?.statusCode === 200) {
            setTimeout(() => {
              navigate("/oet/IntroductionSection", {
                state: {
                  nextsectionType: nextSection,
                  sectionType: currentSection,
                  sectionText: "Introduction"
                }
              });
              setLoading(false);
            }, 1500);
            if (loader === "loader" && userInfo?.type === "M") {
              setLoading(false);
            } else if (loader === "save_and_exit" || userInfo?.type === "P") {
              if (userInfo?.redirect_url) {
                window.location.replace(userInfo?.redirect_url);
              } else {
                window.location.replace(
                  `${window.origin + "/Student/dashboard"}`
                );
              }
            }
          }

          if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            // setMessageAlert(defaultMessages.sessionTimeOut);
            // setApiType(defaultMessages?.logoutApicall);
          }
        },
        onFailure: (error) => {
          console.log(error);
          setShowAlert(true);
          setDefaultTestStatus(599);
          // setMessageAlert(error?.message);
          // setApiType(defaultMessages?.submitAnswerAPI);
        }
      })
    );
  };

  const handleNext = (status) => {
    setModalShow(true);
  };

  const confirmAction = () => {
    setModalShow(false);
    setTimeout(() => {
      setConfirmModalShow(true);
    }, 1000);
  };

  const nextBtnPopup = () => {
    setModalShow(false);
    setConfirmModalShow(false);
    setSessionTimeOut(false);
    setLoading(true);

    if (currentPage === 0) {
      setTimeout(() => {
        setCurrentPage(1);
        setLoading(false);
      }, 1500);
    } else if (currentPage === 1) {
      setNextAction(true);
    }
  };

  const cancelAction = () => {
    setModalShow(false);
    setConfirmModalShow(false);
  };
  const handleSessionTimeOut = () => {
    nextBtnPopup();
  };

  const getAnswerAndMarksData = (
    quesNum,
    userInput,
    currentPart,
    currentPage,
    wordCount,
    currQuestion
  ) => {
    setQuesNum(quesNum);
    setUserAnswer(userInput);
    setCurrPart(currentPart);
    setCurrentPage(currentPage);
    setWordCount(wordCount);
    setCurrQuestion(currQuestion);
  };

  useEffect(() => {
    if (userAnswer !== undefined && userAnswer !== "") {
      const tempObj = {
        id: quesNum,
        userAnswer: userAnswer,
        currPart: currPart,
        currentPage: currentPage,
        wordCount: wordCount
      };
      storeAnswer(tempObj);
    }
  }, [userAnswer]);

  const storeAnswer = async (tempObj) => {
    const newData = await getAnswerWritingTestDataOfDB.filter(
      (item) => item?.id === tempObj?.id
    );
    try {
      if (newData.length === 0) {
        // If no existing data, add the new answer
        await advancedOETDatabase.writingTestAnswer.add(tempObj);
      } else {
        // If data exists, update the existing entry
        await advancedOETDatabase.writingTestAnswer.update(
          newData[0]?.id,
          tempObj
        );
      }
    } catch (err) {
      console.error("Error storing or updating the answer:", err);
    }
  };

  return (
    <>
      <div className="test-viewer-holder">
        <PdfViewer
          isPdfOpen={isPdfOpen}
          setIsPdfOpen={setIsPdfOpen}
          questionsData={questionsData}
        />
        <div className="test-viewer-main-container container-centre">
          <div className="test-viewer-container">
            <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  You still have time remaining in this <strong>section</strong>
                  .
                  <br />
                  If you choose to <strong>finish this section</strong>, you
                  will not be able to return to this section.
                  <br />
                  Are you sure you would like to{" "}
                  <strong>finish this section</strong>?
                </div>
              }
              modalShow={modalShow}
              setModalShow={setModalShow}
              confirmAction={confirmAction}
              cancelAction={cancelAction}
              confirmText="Yes, I would like to finish this section"
              cancelText="No, I would like to return to this section"
              leftIcon={faCheck}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faClose}
            />
            <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  If you select <b>Finish Section</b>, your answers <br />
                  will be submitted and you will not be able to <br />
                  return to this section.
                </div>
              }
              modalShow={confirmModalShow}
              setModalShow={setConfirmModalShow}
              cancelAction={nextBtnPopup}
              confirmAction={cancelAction}
              confirmText="Cancel"
              cancelText="Finish Section"
              leftIcon={faClose}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faCheck}
            />
            <CustomAlert
              title="Section Time Expired"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  The time for the previous section of the test has <br />
                  expired. Please continue with the new section you're <br />
                  being directed to.
                </div>
              }
              modalShow={sessionTimeOut}
              setModalShow={setSessionTimeOut}
              cancelAction={handleSessionTimeOut}
              cancelText="Close"
              rightBtn={true}
              rightIcon={faCheck}
            />
            <AppLoader modalShow={loading} setModalShow={setLoading} />

            <Header
              defaultTime={
                (currTestTime?.hours < 9
                  ? 0 + "" + currTestTime?.hours
                  : currTestTime?.hours) +
                ":" +
                (currTestTime?.minutes < 9
                  ? 0 + "" + currTestTime?.minutes
                  : currTestTime?.minutes) +
                ":" +
                (currTestTime?.seconds < 9
                  ? 0 + "" + currTestTime?.seconds
                  : currTestTime?.seconds)
              }
              currTestTime={currTestTime}
              currTesthours={currTestTime?.hours}
              currTestMinutes={currTestTime?.minutes}
              currTestSeconds={currTestTime?.seconds}
              setGetCurrTestTime={setGetCurrTestTime}
              sectionTime={sectionTime}
              handleNextSection={handleNextSection}
              sectionText={sectionText}
            />
            <div className="row content ng-scope">
              <div
                style={{ display: "inherit", width: "100%" }}
                className="ng-scope ng-isolate-scope"
              >
                <div className="white-background question-navigation-holder ng-scope">
                  <section>
                    <nav className="question-nav ng-star-inserted">
                      <div className="nav-button-holder">
                        <section className="ng-star-inserted">
                          <ul className="main-nav-list">
                            {[...Array(1)].map((_, index) => (
                              <li key={index} className="ng-star-inserted">
                                <section>
                                  <button
                                    // onClick={() => handleSideBarClick(index)}
                                    style={{
                                      backgroundColor: `${theme.subHeader}`,
                                      color: `${theme.textColor}`,
                                      borderColor: `${theme.subHeader}`
                                    }}
                                    className={`btn nav-button theme-bordered item-selector-1intro nav-active`}
                                    aria-current="true"
                                  >
                                    <div>
                                      <span className="ng-star-inserted">
                                        {index + 1}{" "}
                                      </span>
                                    </div>
                                  </button>
                                </section>
                              </li>
                            ))}
                          </ul>
                        </section>
                      </div>
                    </nav>
                  </section>
                </div>
                <div
                  className="content-container white-background content-padding"
                  style={{ height: "100%" }}
                >
                  <div className="content-holder">
                    {questionsData && (
                      <PartA
                        questionsData={questionsData}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        setIsPdfOpen={setIsPdfOpen}
                        isPdfOpen={isPdfOpen}
                        currentPart={currentPart}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        answersData={answersData}
                      />
                    )}
                  </div>
                </div>
                <div className="content-loading-error-tip ng-isolate-scope"></div>
              </div>
            </div>
            <Footer
              handleNext={handleNext}
              sectionTime={sectionTime}
              maxPage={2}
              currentPage={1}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WritingSection;
