import { ACTION_TYPE } from '../action/Type';

const initialState = {
    loading: false,
    AudioFileData: [],
    AnswerList: [],

}

export const AnswerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                AudioFileData: action.payload
            };
        case ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ACTION_TYPE.ADD_UPDATED_ANSWER_SUBMIT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ACTION_TYPE.ADD_UPDATED_ANSWER_SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                AnswerList: action.payload
            };
        case ACTION_TYPE.ADD_UPDATED_ANSWER_SUBMIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}