import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { AppContext } from "./AppContext";

// View Pages
import advancedOETDatabase from "./db";
import MockTest from "views/MockTest";
import SpeakingSection from "views/PracticeTest/SpeakingSection";
import WritingSection from "views/PracticeTest/WritingSection";
import ReadingSection from "views/PracticeTest/ReadingSection";
import ListeningSection from "views/PracticeTest/ListeningSection";
import InstructionSection from "views/PracticeTest/InstructionSection";
import IntroductionSection from "views/PracticeTest/IntroductionSection";
import KeyCode from "views/KeyCode";

const Routing = (props) => {
  const { showAlert } = props;

  const [contextState, setContextState] = useState({
    introductionForEachSection: {
      timeWithMin: "00:01:00"
    },
    instructionSectionTime: {
      timeWithMin: "00:10:00"
    },
    speakingSectionTime: {
      timeWithMin: "00:20:00",
      partA_Ext1: "00:04:00",
      partB_Ext1: "00:08:00",
      partC_Ext1: "00:08:00"
    },
    writingSectionTime: {
      timeWithMin: "00:45:00",
      partIntro_Ext1: "00:05:00",
      partA_Ext1: "00:40:00"
    },
    readingSectionTime: {
      timeWithMin: "00:60:00",
      partA_Ext1: "06:00:00",
      partB_Ext1: "00:45:00"
    },
    listeningSectionTime: {
      timeWithMin: "00:40:00",
      partA_Ext1: "00:07:00",
      partA_Ext2: "00:07:00",
      partB_Ext1: "00:09:00",
      partC_Ext1: "00:08:00",
      partC_Ext2: "00:09:00"
    }
  });

  const [theme, setTheme] = useState({
    backgroundColor: "#04a49b",
    headerFooter: "#04a49b",
    textColor: "#ffffff",
    btnColor: "#dd9b10",
    subHeader: "#04625d",
    bTextColor: "#000000",
    wTextColor: "#ffffff"
  });

  const toggleTheme = () => {
    setTheme((prevTheme) => ({
      backgroundColor: prevTheme.backgroundColor,
      headerFooter: prevTheme.headerFooter,
      textColor: prevTheme.textColor,
      btnColor: prevTheme.btnColor,
      subHeader: prevTheme.subHeader,
      bTextColor: prevTheme.bTextColor,
      wTextColor: prevTheme.wTextColor
    }));
  };

  // Set default values in context variable
  const handleDefaultTime = () => {
    setContextState({
      ...contextState,
      instructionSectionTime: contextState.instructionSectionTime
    });
  };

  const [questionId, setQuestionId] = useState(0);
  const [checkRepetedQ, setCheckRepetedQ] = useState(false);

  // Remove user answer string
  const handleDefaultValues = () => {
    localStorage.removeItem("spekingAnsString");
    localStorage.removeItem("WritingAnsString");
    localStorage.removeItem("ListeningAnsString");
  };

  // For clear LocalStorage and IndexDB data
  const handleClearLocalStorageData = () => {
    localStorage.clear();
    advancedOETDatabase.speakingTestAnswer.clear();
    advancedOETDatabase.writingTestAnswer.clear();
    advancedOETDatabase.readingTestAnswer.clear();
    advancedOETDatabase.listeningTestAnswer.clear();
    advancedOETDatabase.customTestAnswer.clear();
    advancedOETDatabase.speakingTestQuestions.clear();
    advancedOETDatabase.writingTestQuestions.clear();
    advancedOETDatabase.readingTestQuestions.clear();
    advancedOETDatabase.listeningTestQuestions.clear();
    advancedOETDatabase.customTestQuestions.clear();
    advancedOETDatabase.retryQueTypeData.clear();
  };

  return (
    <AppContext.Provider
      value={{
        contextState,
        setContextState,
        handleDefaultTime,
        handleDefaultValues,
        handleClearLocalStorageData,
        questionId,
        setQuestionId,
        checkRepetedQ,
        setCheckRepetedQ,
        toggleTheme,
        theme,
        setTheme
      }}
    >
      <Routes>
        <Route exact path="/oet" element={""} />
        <Route path="/oet/KeyCode" element={<KeyCode />} />
        <Route path="/oet/MockTest" element={<MockTest />} />
        <Route
          path="/oet/InstructionSection"
          element={<InstructionSection eventTimeOut={showAlert} />}
        />
        <Route
          path="/oet/IntroductionSection"
          element={<IntroductionSection eventTimeOut={showAlert} />}
        />
        <Route
          path="/oet/SpeakingSection"
          element={<SpeakingSection eventTimeOut={showAlert} />}
        />
        <Route
          path="/oet/WritingSection"
          element={<WritingSection eventTimeOut={showAlert} />}
        />
        <Route
          path="/oet/ReadingSection"
          element={<ReadingSection eventTimeOut={showAlert} />}
        />
        <Route
          path="/oet/ListeningSection"
          element={<ListeningSection eventTimeOut={showAlert} />}
        />
      </Routes>
    </AppContext.Provider>
  );
};

export default Routing;
