import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import { AppContext } from "AppContext";
import Footer from "components/Footer";
import IntroductionForEachSection from "components/IntroductionForEachSection";
import CustomAlert from "components/Alert";

import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import AppLoader from "components/CustomLoader";
import advancedOETDatabase from "db";

const IntroductionSection = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const typeOfPartsIndex = JSON.parse(localStorage.getItem("position"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const sectionTime = "Section";
  // const section = "Listening";
  const [section, setSection] = useState("Listening");
  const [sectionText, setSectionText] = useState("Intoduction");
  const [currentSection, setcurrentSection] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const { theme } = useContext(AppContext);

  // Props variable
  const {} = props;

  // Context state variable
  const { contextState, handleDefaultValues } = useContext(AppContext);

  // Speaking section test and Question time
  const [getCurrTestTime, setGetCurrTestTime] = useState();
  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [modalShow, setModalShow] = useState(false);

  const [alertStatus, setAlertStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (
      location?.state?.nextsectionType === "ReadingSection" ||
      typeOfPartsIndex?.typeOfPartsIndex?.includes("ReadingSection")
    ) {
      setcurrentSection("ReadingSection");
      setSection("Reading");
    } else if (
      location?.state?.nextsectionType === "WritingSection" ||
      typeOfPartsIndex?.typeOfPartsIndex?.includes("WritingSection")
    ) {
      setcurrentSection("WritingSection");
      setSection("Writing");
    } else if (
      location?.state?.nextsectionType === "SpeakingSection" ||
      typeOfPartsIndex?.typeOfPartsIndex?.includes("SpeakingSection")
    ) {
      setcurrentSection("SpeakingSection");
      setSection("Speaking");
    } else {
      setcurrentSection("ListeningSection");
      setSection("Listening");
    }
  }, []);

  useEffect(() => {
    if (location?.state?.nextsectionType) {
      localStorage.setItem(
        "position",
        JSON.stringify({
          typeOfPartsIndex: `${location?.state?.nextsectionType}`
        })
      );
    } else {
      localStorage.setItem(
        "position",
        JSON.stringify({
          typeOfPartsIndex: `ListeningSection`
        })
      );
    }
  });

  useEffect(() => {
    handleTestStartTime();
  }, []);

  // Manage starting test time
  const handleTestStartTime = () => {
    if (
      userInfo &&
      userInfo?.testStatus === "P" &&
      userInfo?.session_time?.charAt(0) === "S"
    ) {
      let getTestTime = userInfo && userInfo?.session_time.split("_");
      let splitTestTime = getTestTime && getTestTime[1].split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    } else {
      let getTestTime = contextState.introductionForEachSection.timeWithMin;
      let splitTestTime = getTestTime && getTestTime.split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    }
  };

  const handleNext = (status) => {
    if (status === "") {
    } else {
    }
    setModalShow(true);
  };

  const nextClickPopup = () => {
    setModalShow(false);
    setLoading(true);

    if (location?.state?.nextsectionType === "ReadingSection") {
      setTimeout(() => {
        navigate("/oet/ReadingSection", {
          state: { sectionType: "ReadingSection" }
        });
        setLoading(false);
      }, 1500);
    } else if (location?.state?.nextsectionType === "WritingSection") {
      setTimeout(() => {
        navigate("/oet/WritingSection", {
          state: { sectionType: "WritingSection" }
        });
        setLoading(false);
      }, 1500);
    } else if (location?.state?.nextsectionType === "SpeakingSection") {
      setTimeout(() => {
        navigate("/oet/SpeakingSection", {
          state: { sectionType: "SpeakingSection" }
        });
        setLoading(false);
      }, 1500);
    } else {
      setTimeout(() => {
        localStorage.removeItem("inputValues");
        advancedOETDatabase.listeningTestAnswer.clear();
        navigate("/oet/ListeningSection", {
          state: { sectionType: "ListeningSection" }
        });
        // navigate("/oet/ReadingSection", {
        //   state: { sectionType: "ReadingSection" }

        // });
        setLoading(false);
      }, 1500);
    }
  };

  const cancelBtnPopup = () => {
    setModalShow(false);
  };

  return (
    <>
      <div className="test-viewer-holder">
        <div className="test-viewer-main-container container-centre">
          <div className="test-viewer-container">
            <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  You still have time remaining in this <strong>section</strong>
                  .
                  <br />
                  If you choose to <strong>finish this section</strong>, you
                  will not be able to return to this section.
                  <br />
                  Are you sure you would like to{" "}
                  <strong>finish this section</strong>?
                </div>
              }
              modalShow={modalShow}
              setModalShow={setModalShow}
              confirmAction={nextClickPopup}
              cancelAction={cancelBtnPopup}
              confirmText="Yes, I would like to finish this section"
              cancelText="No, I would like to return to this section"
              leftIcon={faCheck}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faClose}
            />
            <AppLoader modalShow={loading} setModalShow={setLoading} />

            <Header
              defaultTime={
                (currTestTime?.hours < 9
                  ? 0 + "" + currTestTime?.hours
                  : currTestTime?.hours) +
                ":" +
                (currTestTime?.minutes < 9
                  ? 0 + "" + currTestTime?.minutes
                  : currTestTime?.minutes) +
                ":" +
                (currTestTime?.seconds < 9
                  ? 0 + "" + currTestTime?.seconds
                  : currTestTime?.seconds)
              }
              currTestTime={currTestTime}
              currTesthours={currTestTime?.hours}
              currTestMinutes={currTestTime?.minutes}
              currTestSeconds={currTestTime?.seconds}
              setGetCurrTestTime={setGetCurrTestTime}
              handleNextSection={handleNext}
              section={section}
              sectionTime={sectionTime}
              sectionText={sectionText}
            />

            <div className="row content ng-scope">
              <div
                style={{ display: "inherit", width: "100%" }}
                className="ng-scope ng-isolate-scope"
              >
                <div className="white-background question-navigation-holder ng-scope">
                  <section>
                    <nav className="question-nav ng-star-inserted">
                      <div className="nav-button-holder">
                        <section className="ng-star-inserted">
                          <ul className="main-nav-list">
                            {[...Array(1)].map((_, index) => (
                              <li key={index} className="ng-star-inserted">
                                <section>
                                  <button
                                    // onClick={() => handleClick(index)}
                                    style={{
                                      backgroundColor: `${theme.subHeader}`,
                                      color: `${theme.textColor}`,
                                      borderColor: `${theme.subHeader}`
                                    }}
                                    className={`btn nav-button theme-bordered item-selector-1intro ${
                                      index + 1 === currentPage
                                        ? "nav-active"
                                        : ""
                                    }`}
                                    aria-current="true"
                                  >
                                    <div>
                                      <span className="ng-star-inserted">
                                        {" "}
                                        {index + 1}{" "}
                                      </span>
                                    </div>
                                  </button>
                                </section>
                              </li>
                            ))}
                          </ul>
                        </section>
                      </div>
                    </nav>
                  </section>
                </div>
                <div
                  className="content-container white-background content-padding"
                  style={{ height: "100%" }}
                >
                  <div className="content-holder">
                    <IntroductionForEachSection
                      currentSection={currentSection}
                    />
                  </div>
                </div>
                <div className="content-loading-error-tip ng-isolate-scope"></div>
              </div>
            </div>
            <Footer
              handleNext={handleNext}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              sectionTime={sectionTime}
              maxPage={2}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroductionSection;
