import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import OETsection from "../../assets/Images/OET section.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import AudioPlayerWithVisualizer from "components/AudioPlayerWithVisualizer";

const IntroductionSectionType = (props) => {
  const { currentPage } = props;

  const [selectedOption, setSelectedOption] = useState("A");

  const options = [
    { label: "A", text: "organism" },
    { label: "B", text: "population" },
    { label: "C", text: "cell" },
    { label: "D", text: "ecosystem" }
  ];

  const handleSelect = (option) => {
    setSelectedOption(option);
  };
  return (
    <>
      <div className="introduction">
        {currentPage === 1 ? (
          <div>
            <div className="item-frame">
              <h4 className="section-heading">OET Online Test Structure</h4>
              <p className="section-paragraph">
                This is the online version of the Occupational English Test
                (OET). In the online OET, there are 'sections'. A section is a
                timed part of the test that you cannot go back to after you have
                moved onto the next section.
              </p>
              <p>The timed sections for the online OET are:</p>
              <img alt="loading image..." src={OETsection}></img>
              <p className="section-paragraph">
                Use the 'Next' button in the bottom right to progress through
                your test. If you do so at the end of a section, you will be
                asked to confirm that you are ready to start the next section.
              </p>
              <p className="section-paragraph">
                Once you start a new section, you cannot return to a previous
                section. Any questions without responses will be marked as
                incorrect.
              </p>
              <p className="section-paragraph">
                Please note that there is an Introduction landing page before
                the start of each sub-test to ensure you are ready to start each
                sub-test. Click 'Next' to start each sub-test.
              </p>
              <p className="section-paragraph">
                You are not allowed to leave the camera view without first
                notifying the Proctor. Failure to inform the Proctor prior to
                taking a break or failure to return on time, may result in an
                inability to complete the test.
              </p>
              <p className="section-paragraph">
                Ten minutes of break time is scheduled at the end of Part A of
                the Reading sub-test. This is to facilitate candidates who need
                to have a break urgently, and it is not encouraged to use it if
                it is not necessary. You can start the next section if you do
                not need the break by clicking 'Resume Test'.
              </p>
              <p className="section-paragraph">
                Please note that at the end of the scheduled time for the break,
                the next section will automatically start.
              </p>
              <p className="section-paragraph">
                If you choose to take a break and leave the room or leave the
                view of the Remote Proctor, you must go through the check-in
                procedures again.
              </p>
              <b>
                <p className="next-instruction">
                  Click the 'Next' button to continue
                </p>
              </b>
            </div>
          </div>
        ) : currentPage === 2 ? (
          <div className="ng-scope">
            <div className="item-frame">
              <h4 className="section-heading">Welcome to the Tutorial</h4>
              <p className="section-paragraph">
                This tutorial provides a series of screens that orient you to
                the computer testing environment. You will be instructed on how
                to use the mouse and the different parts of the screen.
              </p>
              <p className="section-paragraph">
                Please do not press <strong>‘Start the Test’</strong> unless the
                tutorial has been completed. If you press{" "}
                <strong>‘Start the Test’</strong> now, the Listening section of
                the test will commence.
              </p>
              <br />
              <p className="section-paragraph">
                Notice the timer at the top of the screen. A similar display
                will appear during the actual test. To the left of the screen is
                a numbered list that shows you where you are in the series of
                screens within each section (or in this case, screens of the
                tutorial). Other screen features are described later in the
                tutorial.
              </p>
              <h4 className="time-remaining-heading">Time Remaining</h4>
              <p>
                The most important time display for you as a test taker is the{" "}
                <strong>‘Section Time Remaining’</strong>.
              </p>
              <p className="time-remaining-note">
                The amount of time remaining is displayed at the top of the
                screen.
              </p>
              <div className="time-display">
                <div className="time-icon">
                  <FontAwesomeIcon className="clock-icon" icon={faClock} />
                </div>
                <div className="time-info">
                  <div className="time-text">Section Time Remaining</div>
                  <div className="time-value">01:59:46</div>
                </div>
              </div>
              <p className="section-paragraph">
                Each section of this test is allocated a specific amount of
                time, including the Tutorial.
              </p>
              <p className="alert-note">
                Note that, where applicable, an alert box will appear below the
                test clock to signal when <strong>30 minutes</strong>,{" "}
                <strong>15 minutes</strong>, and <strong>5 minutes</strong>{" "}
                remain in the current section.
              </p>
              <b>
                <p className="next-instruction">
                  Click the 'Next' button to continue
                </p>
              </b>
            </div>
          </div>
        ) : currentPage === 3 ? (
          <div className="third-section">
            <h4 className="section-heading">Using the Mouse</h4>

            <img
              src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P3877293_15/5736P3877293S1C2T1.jpg"
              alt="Mouse Pointer"
            />
            <div>
              <p className="section-paragraph">
                The mouse pointer moves when you move the mouse around on a
                surface. Although it can assume different shapes, the arrow
                shown above is most common. To point with the mouse, move the
                pointer until it rests on the desired object. To click on an
                object, point to it and then quickly press and release the left
                mouse button.
              </p>
              <b>
                <p className="next-instruction">Use the Tab Key </p>
              </b>

              <p>
                You can also use the tab key &nbsp;↹ &nbsp;to move the mouse
                pointer to the next blank or question for you to fill in.
              </p>
              <b>
                <p className="next-instruction">Navigating through the Exam</p>
              </b>
              <p className="section-paragraph">
                Click the <b>'Next'&nbsp;</b>button displayed at the bottom of
                the screen to move to the next screen. Click the{" "}
                <b>'Back'&nbsp;</b>button to move to the previous
                screen.&nbsp;Please try this feature now.
              </p>
              <p className="section-paragraph">
                In addition to the navigation buttons, you can use the numbered
                buttons displayed on the left side of the screen. Depending on
                the number of screens in the section, you may need to click on
                the down arrow to navigate to additional screens.
              </p>
              <p>
                <img
                  alt="breadcrumbs"
                  id="106448-embeddedImageMediaId"
                  src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P3877293_15/5736P3877293S1C3T1.jpg"
                />
              </p>
              <p>
                The numbered buttons change appearance to indicate different
                screen states: Current, Attempted, and Unattempted.
              </p>
              <ul>
                <li>
                  The current screen will be indicated by an arrow-shaped
                  numbered button (see tab 4 in the image above).&nbsp;
                </li>
                <li>
                  For all screens where a question has been answered, the
                  numbered button will have a corner turned over (see tabs 1 – 3
                  in the image above).
                </li>
                <li>
                  For all screens that are yet to have a question answered, the
                  numbered button will remain a rectangle (see tabs 5 – 8 in the
                  image above).&nbsp;
                </li>
              </ul>

              <b>
                <p className="next-instruction">
                  Click the 'Next' button to continue
                </p>
              </b>
            </div>
          </div>
        ) : currentPage === 4 ? (
          <div className="fourth-section">
            <h4 className="section-heading"> Using the PDF Viewer</h4>
            <p className="section-paragraph">
              During the Reading and Writing sub-tests there will be PDFs to
              read. The PDF will open automatically on the left pane of the
              split screen.
            </p>
            <p className="section-paragraph">
              Refer to the image below to assist you in understanding the PDF
              functionality.
            </p>

            {/* Image Section */}
            <div className="option-content-container option-with-image content-align-left">
              <img
                src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P3877313_18/5736P3877313S1C2T1.jpg"
                alt="PDF-bar-final"
              />
            </div>

            {/* Additional Instructions */}
            <h4>Open the PDF Viewer within the Test</h4>
            <p className="section-paragraph">
              The PDF should open automatically when the page opens.
            </p>

            <p className="section-paragraph">
              If the PDF does not automatically open on your device, click on
              the relevant source materials on the right pane of the split
              screen to open the PDF Viewer. For example, you can click the icon
              underneath which links to the Sample page on the left pane (
              <img
                alt="samplePDF"
                src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P3877313_18/5736P3877313S1C3T1.jpg"
              />
              ).
            </p>
            <h4>Navigation through the PDF Viewer</h4>
            <p className="section-paragraph">
              You can use your mouse to scroll down to view all texts in the
              PDF.
            </p>
            <h4>Other Functions of the PDF Viewer</h4>
            <p className="section-paragraph">
              You can use your mouse to scroll down to view all texts in the
              PDF.
            </p>
            <ul>
              <li>
                Pan{" "}
                <img
                  alt="pan"
                  src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P3877313_18/5736P3877313S1C4T1.jpg"
                />{" "}
                - Click and drag to pan around the document.
              </li>
              <li>
                Zoom In/Out{" "}
                <img
                  alt="zoom"
                  src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P3877313_18/5736P3877313S1C5T1.jpg"
                />{" "}
                - Click to zoom in and out of the document. Other predefined
                options available in the drop-down list are: Automatic Zoom,
                Actual Size, Fit Page, and Full Width.
              </li>
              <li>
                Highlight{" "}
                <img
                  alt="highlight"
                  src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P3877313_18/5736P3877313S1C6T1.jpg"
                />{" "}
                - To highlight text, click and drag the mouse cursor over the
                desired text. Click the <b>'Highlight'</b> button that appears
                after releasing the mouse button. To remove the highlight, click
                on any area of the highlighted text.
              </li>
            </ul>

            <p className="section-paragraph">
              Please try these features now to familiarise before the test
              begin.
            </p>
            <b>
              <p className="next-instruction">
                Click the 'Next' button to continue
              </p>
            </b>
          </div>
        ) : currentPage === 5 ? (
          <div className="fifth-section">
            <h4 className="section-heading">
              <span style={{ color: "#FF0000", fontWeight: "bold" }}>
                If you are sitting for OET@Home, please read this thoroughly
              </span>
            </h4>
            <h4 className="section-heading">Optimal Viewing - Side-by-side</h4>

            <p className="section-paragraph">
              When set at 1920 x 1080 resolution, the height and width of the
              computer display will present all exam content for the best
              viewing possible. Below, the PDF case is on the left and the exam
              question is displayed on the right.
            </p>
            <p>
              <img
                alt="updatedTut1"
                id="171431-embeddedImageMediaId"
                src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P7612997_14/5736P7612997S1C2T1.jpg"
                style={{ maxWidth: "100%" }}
              />
            </p>
            <h4 className="section-heading2">
              Below-optimal Viewing - Overlap
            </h4>

            <p className="section-paragraph">
              If the screen resolution is lower than 1920 x 1080 (i.e. 1400 x
              900 or 1366 x 768), the PDF case will overlap and display on top
              of the options. You are able to drag and move the window or close
              the PDF. However, this will occur with each question because the
              display’s width and height are below optimal.
            </p>
            <p>
              <img
                alt="newtut2"
                id="171433-embeddedImageMediaId"
                src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P7612997_14/5736P7612997S1C3T1.jpg"
                style={{ maxWidth: "100%" }}
              />
            </p>
            <h4 className="section-heading2">
              To improve experience | Adjusting Display | 2 steps
            </h4>

            <p className="section-paragraph">
              If your display is below 1920 x 1080, it is highly recommended to
              adjust the Display Zoom in the ProProctor App once you have
              started your exam. The <b>Display Zoom</b> is located on the upper
              left corner:
            </p>

            <p>
              <img
                alt="tut3"
                id="171222-embeddedImageMediaId"
                src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P7612997_14/5736P7612997S1C4T1.jpg"
                style={{ maxWidth: "100%" }}
              />
            </p>
            <li>
              <b>Step 1: Adjust Display Zoom to correct overlap</b>
            </li>

            <p className="section-paragraph">
              If the PDF case overlaps the questions when the exam begins,
              adjust the Display Zoom:
              <img
                alt="newtut4"
                id="171434-embeddedImageMediaId"
                src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P7612997_14/5736P7612997S1C5T1.jpg"
                style={{ maxWidth: "100%" }}
              />
            </p>
            <table style={{ width: "95%", marginTop: "4rem" }}>
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>
                    <b>Before</b>
                  </td>
                  <td style={{ width: "50%" }}>
                    <b>After</b>
                  </td>
                </tr>
                <tr>
                  <td>(Example of overlap)</td>
                  <td>(Optimal content docking 50:50 layout)</td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt="newnewtut5"
                      id="171438-embeddedImageMediaId"
                      src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P7612997_14/5736P7612997S1C6T1.jpg"
                      style={{ maxWidth: "100%" }}
                    />
                  </td>
                  <td>
                    <img
                      alt="newnewtut6"
                      id="171439-embeddedImageMediaId"
                      src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P7612997_14/5736P7612997S1C7T1.jpg"
                      style={{ maxWidth: "100%" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <li style={{ marginTop: "4rem" }}>
              <b>Step 2: Fine tune the PDF zoom</b>
            </li>
            <p className="section-paragraph">
              After adjusting the Display Zoom and docking the content, if the
              PDF case text becomes blurry or harder to read, increase the PDF
              zoom to improve readability:
              <img
                alt="newtut7"
                id="171437-embeddedImageMediaId"
                src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/htmldelivery/../content/5736P7612997_14/5736P7612997S1C8T1.jpg"
                style={{
                  maxWidth: "100%",
                  justifyContent: "flex-start",
                  display: "flex",
                  margin: "10px 0px 50px 0px"
                }}
              />
            </p>
            <p style={{ marginBottom: "1em" }}></p>
            <p>
              <b>Setup Complete:</b> With your display optimized, these settings
              will remain in place for the duration of your exam.
            </p>
            <b>
              <p className="next-instruction">
                Click the 'Next' button to continue
              </p>
            </b>
          </div>
        ) : currentPage === 6 ? (
          <div>
            <div>
              <div>
                <div data-componentid="3" aria-hidden="false">
                  <div>
                    <div>
                      <div style={{ textAlign: "left" }}>
                        <div style={{ fontFamily: "Arial", fontSize: "16px" }}>
                          <p>
                            <b>Highlighting Text</b>
                          </p>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Main Content Block */}
            <div>
              <div>
                <div data-componentid="4" aria-hidden="false">
                  <div>
                    <div>
                      <div style={{ textAlign: "left" }}>
                        <div style={{ fontFamily: "Arial", fontSize: "16px" }}>
                          <table width="99%">
                            <tbody>
                              <tr>
                                <td>
                                  <div style={{ width: "95%" }}>
                                    <p>
                                      During the test, you will be able to
                                      highlight question text that you feel is
                                      important to refer back to as you progress
                                      through the test. The highlight will
                                      remain present as you navigate through the
                                      test, unless you select to remove it.
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                      To highlight text, click and drag the
                                      mouse cursor over the desired text. Click
                                      the <b>'Highlight'</b> button, as shown in
                                      the image below, that appears after
                                      releasing the mouse button.&nbsp;
                                    </p>
                                    <p>
                                      <br />
                                      To remove, click on any area of the
                                      highlighted text and click the{" "}
                                      <b>'Highlight'</b> button again.
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Third Main Content Block with Image */}
            <div>
              <div>
                <div data-componentid="2" aria-hidden="false">
                  <div>
                    <div>
                      <img
                        src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/cbla_sample_test_2/htmldelivery/../content/5736P3877315_11/5736P3877315S1C2T1.jpg"
                        alt="Highlighttt"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Fourth Main Content Block */}
            <div>
              <div>
                <div data-componentid="5" aria-hidden="false">
                  <div>
                    <div>
                      <div style={{ textAlign: "left" }}>
                        <div style={{ fontFamily: "Arial", fontSize: "16px" }}>
                          <table width="99%">
                            <tbody>
                              <tr>
                                <td>
                                  <div style={{ width: "95%" }}>
                                    <p>
                                      The highlight feature cannot be applied to
                                      text within the answer options. Please try
                                      this feature on this text now to
                                      familiarise before the test begins.
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                      You will not be able to use the highlight
                                      feature during the 5-minute ‘reading only’
                                      time of the Writing sub-test.&nbsp;
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>
                                      <b>
                                        Click&nbsp;the 'Next' button to
                                        continue.
                                      </b>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div data-componentid="6">
                <div style={{ textAlign: "left" }}>
                  <span
                    style={{
                      fontFamily: "Arial",
                      fontSize: "16px",
                      color: "#3D505A"
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        ) : currentPage === 7 ? (
          <div>
            <div className="question">
              <div>
                <div>
                  <div data-componentid="5" aria-hidden="false">
                    <div>
                      <div>
                        <div leading="2">
                          <div style={{ textAlign: "left" }}>
                            <div
                              style={{
                                fontFamily: "Arial",
                                fontSize: "16px"
                              }}
                            >
                              <p>
                                <b>Audio Controls</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div data-componentid="6" aria-hidden="false">
                    <div>
                      <div>
                        <div leading="2">
                          <div style={{ textAlign: "left" }}>
                            <div
                              style={{
                                fontFamily: "Arial",
                                fontSize: "16px"
                              }}
                            >
                              <table width="99%">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div style={{ width: "95%" }}>
                                        <p>
                                          This is to familiarise yourself with
                                          the Listening sub-test which has an
                                          audio in each of the five sections.
                                          Each audio will play automatically
                                          when the page opens.&nbsp;
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>
                                          <b>
                                            Please take the time now to ensure
                                            that your audio device is configured
                                            correctly by using this sample audio
                                            file below.&nbsp;
                                          </b>
                                        </p>
                                        <p>&nbsp;</p>
                                        <p style={{ lineHeight: 1.7 }}>
                                          Controls for the audio player are as
                                          follows:
                                          <br />
                                          •&nbsp;&nbsp; &nbsp;Click the{" "}
                                          <b>Play</b> button&nbsp;
                                          <img
                                            alt="play"
                                            src="https://training.prod.prometric.mindgrb.io/cbla-tutorial/cbla_sample_test_2/htmldelivery/../content/5736P3877318_16/5736P3877318S1C2T1.jpg"
                                          />
                                          &nbsp;to play the audio and verify
                                          your speakers.
                                        </p>
                                        <p style={{ lineHeight: 1.7 }}>
                                          •&nbsp;&nbsp; &nbsp;Click the{" "}
                                          <b>Pause</b> button to pause the audio
                                          without returning to the beginning.
                                        </p>
                                        <p style={{ lineHeight: 1.7 }}>
                                          •&nbsp;&nbsp; &nbsp;Click and drag on
                                          the <b>Volume</b> bar to adjust the
                                          volume of the audio. Ensure it is loud
                                          enough to clearly understand the
                                          speaker.
                                        </p>
                                        <p>
                                          <br />
                                          <b>
                                            The Play and Pause buttons have been
                                            disabled for the OET Listening
                                            sub-test.&nbsp;An audio will start
                                            to play automatically at the
                                            beginning of each section. Please
                                            note that there are timed pauses in
                                            the audio.&nbsp;
                                          </b>
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AudioPlayerWithVisualizer audioSrc="" playBtn={true} />

            <div className="answer">
              <div className="answer-text" data-componentid="7">
                <div leading="2">
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontFamily: "Arial", fontSize: "16px" }}>
                      <p>
                        <b>Click&nbsp;the 'Next' button to continue.</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : currentPage === 8 ? (
          <div className="eighth-section">
            <div>
              <div>
                <div
                  className="introduction-text background allow-select"
                  data-componentid="6"
                  aria-hidden="false"
                >
                  <div>
                    <div>
                      <div style={{ textAlign: "LEFT" }}>
                        <div
                          style={{
                            fontFamily: "Arial",
                            fontSize: "16px"
                          }}
                        >
                          <p>
                            <b>Answering Multiple-Choice Questions</b>
                          </p>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div
                  className="introduction-text background allow-select"
                  data-componentid="8"
                  aria-hidden="false"
                >
                  <div>
                    <div>
                      <div style={{ textAlign: "LEFT" }}>
                        <div
                          style={{
                            fontFamily: "Arial",
                            fontSize: "16px"
                          }}
                        >
                          <table width="99%">
                            <tbody>
                              <tr>
                                <td style={{ width: "95%" }}>
                                  <p>
                                    The Listening and Reading sub-tests use
                                    multiple-choice questions. This type of
                                    question has one correct answer.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Click on the option you think is correct.
                                    When you do this, the option appears darker
                                    in colour. To change your option, click on a
                                    different option.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    <b>
                                      Practise answering the multiple-choice
                                      question below.&nbsp;
                                    </b>
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>&nbsp;</p>
                                  <p>
                                    <b>Crossing Out Options</b>
                                  </p>
                                  <p>
                                    <br />
                                    During the test, a cross-out feature is
                                    available for you to eliminate options from
                                    consideration.&nbsp;
                                  </p>
                                  <p>
                                    Right-click on an answer to cross it out.
                                    Right-click again to remove the
                                    crossing-out. You can cross out as many
                                    answers as you like.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    You must select an option as your response
                                    for the multiple-choice questions. If you
                                    cross out three of the four options, it does
                                    not automatically mean that you have
                                    selected the option that you have not
                                    crossed out as your response and hence no
                                    mark will be awarded.
                                  </p>
                                  <p>
                                    <br />
                                    <b>
                                      Practise using the Cross-out feature
                                      below. &nbsp;
                                    </b>
                                    <br />
                                    &nbsp;
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Of the following biological levels of
                                    organisation, which represents the smallest
                                    or lowest level?
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-max-answer-count="" className="answer-options">
              <div id="SelectOnlyOneOption" className="sr-only">
                Select only one option.
              </div>
              <div className="container">
                {options?.map((option, index) => (
                  <div className="container-class">
                    <div className="letter">{option.label}</div>
                    <div
                      key={index}
                      className={`option ${
                        selectedOption === option.label ? "selected" : ""
                      }`}
                      onClick={() => handleSelect(option.label)}
                    >
                      <div
                        className={` ${
                          selectedOption === option.label
                            ? "option-background"
                            : ""
                        }`}
                      >
                        {option.text}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : currentPage === 9 ? (
          <div>
            <div className="question">
              <div>
                <div>
                  <div
                    className="introduction-text background allow-select"
                    data-componentid="2"
                    aria-hidden="false"
                  >
                    <div>
                      <div>
                        <div style={{ leading: 2 }}>
                          <div style={{ textAlign: "left" }}>
                            <div
                              style={{
                                fontFamily: "Arial",
                                fontSize: "16px",
                                kerning: 0,
                                letterSpacing: 0
                              }}
                            >
                              <p>
                                <b>Answering Writing Questions</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div
                    className="introduction-text background allow-select"
                    data-componentid="4"
                    aria-hidden="false"
                  >
                    <div>
                      <div>
                        <div style={{ leading: 2 }}>
                          <div style={{ textAlign: "left" }}>
                            <div
                              style={{
                                fontFamily: "Arial",
                                fontSize: "16px",
                                kerning: 0,
                                letterSpacing: 0
                              }}
                            >
                              <table width="99%">
                                <tbody>
                                  <tr>
                                    <td colSpan="1" rowSpan="1">
                                      <div style={{ width: "95%" }}>
                                        <p>
                                          In your Writing sub-test, you need to
                                          write a letter using the provided case
                                          notes and writing task.
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>
                                          In the test, type your letter in the
                                          text box on the Writing sub-test
                                          screen.&nbsp;
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>
                                          <b>
                                            Practise typing in the text box
                                            below. When you have finished
                                            practising, click the
                                            'Next'&nbsp;button to continue.
                                          </b>
                                          <br />
                                          &nbsp;
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>
                                          <span
                                            style={{
                                              fontFamily:
                                                "courier new,courier,monospace"
                                            }}
                                          >
                                            <b>
                                              Dear Dr Smith,
                                              <br />
                                              Re: Ms Mary Scott, DOB 20.8.1958
                                            </b>
                                          </span>
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>
                                          <span
                                            style={{
                                              fontFamily:
                                                "courier new,courier,monospace"
                                            }}
                                          >
                                            <b>
                                              Thank you for seeing Ms Scott, a
                                              63-year-old shop manager, who
                                              presented today with
                                              a&nbsp;history of diabetes.
                                            </b>
                                          </span>
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="highlight-btn highlight-mark-btn no-select"
                id="highlighter-pen"
                aria-hidden="true"
                role="alert"
                style={{
                  top: "185px",
                  left: "942px",
                  display: "none",
                  opacity: 1,
                  zIndex: 10
                }}
              >
                <img id="markerImg" src="data:image/png;base64,..." alt="" />
                <span className="sr-only">Highlight selected text</span>
              </button>
              <button
                className="highlight-btn highlight-mark-btn no-select"
                id="remove-highlight"
                aria-hidden="true"
                role="alert"
                style={{
                  top: "178px",
                  left: "959px",
                  display: "none",
                  zIndex: 10,
                  outline: "none"
                }}
              >
                <img id="rmvmarkerImg" src="data:image/png;base64,..." alt="" />
                <span className="sr-only">Remove highlight text</span>
              </button>
            </div>

            <div className="answer-options">
              <form className="essay-form-with-message-placeholder">
                <label className="sr-only" htmlFor="myTextArea">
                  Answer text
                </label>
                <textarea
                  className="essay-textarea font-size-16px font-family-arial essay-with-message-placeholder"
                  id="myTextArea"
                  autoComplete="off"
                  maxLength="32767"
                  spellCheck="false"
                  data-contextmenu="true"
                  style={{
                    minHeight: "340px",
                    maxHeight: "340px",
                    maxWidth: "900px",
                    width: "850px"
                  }}
                ></textarea>
                <div className="essay-word-count-area">
                  <div className="font-settings-essay">
                    Word Count:{" "}
                    <span
                      className="wordCountLabel font-settings-essay"
                      aria-live="polite"
                      role="status"
                      aria-label="Word Count 0"
                    >
                      0
                    </span>
                  </div>
                </div>
                <span
                  className="essay-max-characters-alert"
                  role="alert"
                  style={{ display: "none" }}
                ></span>
              </form>
            </div>
          </div>
        ) : currentPage === 10 ? (
          <div>
            <div>
              <div>
                <div data-componentid="2" aria-hidden="false">
                  <div>
                    <div>
                      <div leading="2">
                        <div style={{ textAlign: "left" }}>
                          <div
                            kerning="0"
                            letterspacing="0"
                            style={{
                              fontFamily: "Arial",
                              fontSize: "16px"
                            }}
                          >
                            <p>
                              <b>Ending Test Section</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div data-componentid="3" aria-hidden="false">
                  <div>
                    <div>
                      <div leading="2">
                        <div style={{ textAlign: "left" }}>
                          <div
                            kerning="0"
                            letterspacing="0"
                            style={{
                              fontFamily: "Arial",
                              fontSize: "16px"
                            }}
                          >
                            <table width="99%">
                              <tbody>
                                <tr>
                                  <td>
                                    <div style={{ width: "95%" }}>
                                      <p>
                                        After completing and reviewing all of
                                        the questions within a section, you can
                                        also navigate to the next section by
                                        clicking the '<b>Finish Section</b>'
                                        button in the top-right corner of the
                                        screen.
                                      </p>
                                      <p>
                                        <br />
                                        Once you end a test section, a pop-up
                                        window will appear confirming you want
                                        to finish the section. Select '
                                        <b>
                                          Yes, I would like to finish this
                                          section
                                        </b>
                                        ' to submit your answers for that
                                        section and progress to the next
                                        section.
                                      </p>
                                      <p>&nbsp;</p>
                                      <p>
                                        Writing is the final section. Once you
                                        have completed Writing, you must end
                                        your session by clicking the '
                                        <b>Finish Test</b>' button in the
                                        top-right corner of the screen and
                                        confirming you would like to finish the
                                        test.
                                      </p>
                                      <p>&nbsp;</p>
                                      <p>
                                        <b>
                                          Please note that once you leave a
                                          section, you may not return. Any
                                          questions that are left incomplete
                                          will be marked as incorrect.
                                        </b>
                                      </p>
                                      <p>&nbsp;</p>
                                      <p>
                                        This concludes the tutorial. You can
                                        review the tutorial by clicking on the '
                                        <b>Back</b>' button to back up one
                                        screen at a time.
                                      </p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>
                                        <b>
                                          Click the 'Start the Test' button to
                                          exit the tutorial and begin the test.
                                        </b>
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="ipad-scroll-helper">&nbsp;</div>
      </div>
    </>
  );
};

export default IntroductionSectionType;
