import axios from "axios";
import { ACTION_TYPE } from "./Type";
import { Constants } from "../../utilities/ApiConstant";

// Submit Answer
// export const submitQuestionAnswer =
//   ({ dataList, header, onSuccess, onFailure }) =>
//   async (dispatch) => {
//     const formData = new FormData();
//     console.log(dataList);
//     dataList &&
//       dataList?.answer.map((autdioData) => {
//         if (autdioData?.audio_file) {
//           Object.assign(autdioData?.audio_file, {
//             question_id: autdioData.question_id
//           });
//         }
//         return formData.append(
//           "audio_data[]",
//           new File(
//             [autdioData?.audio_file],
//             `${autdioData.user_id}_${autdioData.test_id}_${autdioData.test_type}_${autdioData.question_id}_recording.ogg`
//           )
//         );
//       });
//     formData.append("test_id", dataList?.test_id);
//     formData.append("type", dataList?.type);
//     formData.append("user_id", dataList?.user_id);
//     formData.append("test_type", dataList?.test_type);

//     dispatch({ type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_REQUEST });
//     try {
//       await axios
//         .post(
//           Constants.apiBaseUrl + Constants.ApiAction.submitAnswer,
//           formData,
//           { headers: header }
//         )
//         .then((response) => {
//           dispatch({
//             type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_SUCCESS,
//             payload: response.data,
//             statusCode: response.statusCode
//           });
//           onSuccess(response);
//         });
//     } catch (error) {
//       dispatch({
//         type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_FAILURE,
//         error: error
//       });
//       onFailure(error);
//     }
//   };
export const submitQuestionAnswer =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    dispatch({ type: ACTION_TYPE.POST_SUBMIT_ANSWER_REQUEST });
    const formData = new FormData();
    console.log(dataList);
    if (dataList?.test_type === "P") {
      formData.append("type", dataList?.type);
      formData.append("data", dataList?.data);
      formData.append("status", dataList?.test_status);
      formData.append("test_id", dataList?.test_id);
      formData.append("user_id", dataList?.user_id);
      formData.append("test_type", dataList?.test_type);
    } else {
      formData.append("type", dataList?.type);
      formData.append("data", JSON.stringify(dataList?.data));
      formData.append("status", dataList?.test_status);
      formData.append("test_id", dataList?.test_id);
      formData.append("user_id", dataList?.user_id);
      formData.append("test_type", dataList?.test_type);
    }

    try {
      await axios
        .post(
          Constants.apiBaseUrl + Constants.ApiAction.submitAnswer,
          formData,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.POST_SUBMIT_ANSWER_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });

          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.POST_SUBMIT_ANSWER_FAILURE,
        error: error
      });

      onFailure(error);
    }
  };

export const postAllQuestionsList =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    dispatch({ type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_REQUEST });
    const formData = new FormData();

    formData.append("test_id", dataList?.test_id);
    formData.append("type", dataList?.type);
    formData.append("user_id", dataList?.user_id);
    formData.append("test_type", dataList?.test_type);

    try {
      await axios
        .post(
          Constants.apiBaseUrl + Constants.ApiAction.submitAnswer,
          formData,
          {
            headers: header
          }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });

          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SUBMIT_FAILURE,
        error: error
      });

      onFailure(error);
    }
  };

// Submit Updated Questions Data by Test Module
export const submitUpdatedDataByTest =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();
    if (dataList?.test_type === "P") {
      formData.append("type", dataList?.type);
      formData.append("data", JSON.stringify(dataList?.data));
      formData.append("status", dataList?.status);
      formData.append("test_id", dataList?.test_id);
      formData.append("user_id", dataList?.user_id);
      formData.append("test_type", dataList?.test_type);
    } else {
      formData.append("type", dataList?.type);
      formData.append("data", dataList?.data);
      formData.append("status", dataList?.status);
      formData.append("test_id", dataList?.test_id);
      formData.append("user_id", dataList?.user_id);
      formData.append("test_type", dataList?.test_type);
      formData.append("speaking", dataList?.speaking);
      formData.append("writing", dataList?.writing);
      formData.append("reading", dataList?.reading);
      formData.append("listening", dataList?.listening);
      formData.append("type_details", dataList?.type_details);
    }

    dispatch({ type: ACTION_TYPE.ADD_UPDATED_ANSWER_SUBMIT_REQUEST });
    try {
      await axios
        .post(
          Constants.apiBaseUrl + Constants.ApiAction.submitModuleScore,
          dataList,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_UPDATED_ANSWER_SUBMIT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_UPDATED_ANSWER_SUBMIT_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

//submit custom Ai submit

export const customAiSubmit =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append("student_id", dataList?.student_id);
    formData.append("test_id", dataList?.test_id);
    formData.append("test_type", dataList?.test_type);
    formData.append("question_type", dataList?.question_type);
    dispatch({ type: ACTION_TYPE.ADD_CUSTOM_AI_ANSWER_SUBMIT_REQUEST });
    try {
      await axios
        .post(
          Constants.apiBaseUrl + Constants.ApiAction.customAiSubmit,
          dataList,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_CUSTOM_AI_ANSWER_SUBMIT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_CUSTOM_AI_ANSWER_SUBMIT_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

//submit FeedBack
export const submitFeedBack =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append("institute_id", dataList?.id);
    formData.append("test_id", dataList?.testID);
    formData.append("feedback", dataList?.feedback);
    formData.append("ratings", dataList?.ratingsFeedBack);
    formData.append("ratings", dataList?.test_type);

    dispatch({ type: ACTION_TYPE.ADD_FEEDBACK_SUBMIT_REQUEST });
    try {
      await axios
        .post(Constants.apiBaseUrl + Constants.ApiAction.feedBack, dataList, {
          headers: header
        })
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_FEEDBACK_SUBMIT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_FEEDBACK_SUBMIT_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

// Submit Updated for speaking section
export const submitSpeakingModuleScore =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();
    if (dataList?.test_type === "P") {
      formData.append("type", dataList?.type);
      formData.append("data", JSON.stringify(dataList?.data));
      formData.append("status", dataList?.status);
      formData.append("test_id", dataList?.test_id);
      formData.append("user_id", dataList?.user_id);
      formData.append("test_type", dataList?.test_type);
    } else {
      formData.append("type", dataList?.type);
      formData.append("data", dataList?.data);
      formData.append("status", dataList?.status);
      formData.append("test_id", dataList?.test_id);
      formData.append("user_id", dataList?.user_id);
      formData.append("test_type", dataList?.test_type);
      formData.append("speaking", dataList?.speaking);
      formData.append("writing", dataList?.writing);
      formData.append("reading", dataList?.reading);
      formData.append("listening", dataList?.listening);
      formData.append("type_details", dataList?.type_details);
    }

    dispatch({
      type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_REQUEST
    });
    try {
      await axios
        .post(
          Constants.apiBaseUrl + Constants.ApiAction.submitSpeakingModuleScore,
          dataList,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

export const checkInstituteActiveStudent =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    dispatch({
      type: ACTION_TYPE.ADD_CHECK_INSTITUTE_ACTIVESTUDENT_REQUEST
    });
    try {
      await axios
        .post(
          Constants.apiBaseUrl +
            Constants.ApiAction.checkInstituteActiveStudent,
          dataList,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_CHECK_INSTITUTE_ACTIVESTUDENT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_CHECK_INSTITUTE_ACTIVESTUDENT_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

export const reportQuestionAction =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();

    dispatch({
      type: ACTION_TYPE.ADD_CHECK_REPORT_REQUEST
    });
    try {
      await axios
        .post(
          Constants.apiBaseUrl + Constants.ApiAction.reportQuestion,
          dataList,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_CHECK_REPORT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_CHECK_REPORT_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

export const repeatedQuestionAction =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    dispatch({
      type: ACTION_TYPE.REPEATED_QUESTION_REQUEST
    });
    try {
      await axios
        .post(
          Constants.apiBaseUrl + Constants.ApiAction.repeatedQuestion,
          dataList,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.REPEATED_QUESTION_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.REPEATED_QUESTION_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

export const submitQuestionWiseTestAction =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    dispatch({
      type: ACTION_TYPE.ADD_CUSTOM_ANSWER_SUBMIT_REQUEST
    });
    try {
      await axios
        .post(
          Constants.apiBaseUrl +
            Constants.ApiAction.submitQuestionWiseTestScore,
          dataList,
          { headers: header }
        )
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.ADD_CUSTOM_ANSWER_SUBMIT_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.ADD_CUSTOM_ANSWER_SUBMIT_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };
