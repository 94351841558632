import React from "react";
import "./style.scss";
import CustomLoader from "./loader.gif"; // Import your loader gif

const AppLoader = (props) => {
  const { title, modalShow } = props;

  if (!modalShow) return null;

  return (
    <div className="custom-loader-overlay">
      <img src={CustomLoader} alt="Loading..." className="custom-loader-gif" />
      <p className="custom-loader-text">{title}</p>
    </div>
  );
};

export default AppLoader;
