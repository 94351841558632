import React, { useContext, useEffect, useState, useRef } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Common Components
import { AppContext } from "AppContext";

const Header = (props) => {
  const {
    currTesthours,
    defaultTime,
    currTestTime,
    currTestSeconds,
    currTestMinutes,
    setGetCurrTestTime,
    questionsData,
    handleNextClick,
    handleNextSection,
    section,
    sectionTime,
    sectionText,
    lastSecApiCall,
    handleSaveExitSection
  } = props;

  const { theme } = useContext(AppContext);

  const Ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [timer, setTimer] = useState(defaultTime);

  useEffect(() => {
    const e = getDeadTime();
    clearTimer(e);

    return () => {
      if (Ref.current) clearInterval(Ref.current);
    };
  }, [currTesthours, currTestMinutes, currTestSeconds]);

  const clearTimer = (deadline) => {
    if (Ref.current) clearInterval(Ref.current);
    Ref.current = setInterval(() => {
      startTimer(deadline);
    }, 1000);
  };

  const startTimer = (deadline) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      const timeString = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      setTimer(timeString);
      setGetCurrTestTime(timeString);
    }
  };

  const getTimeRemaining = (deadline) => {
    const total = Date.parse(deadline) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return { total, hours, minutes, seconds };
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setHours(deadline.getHours() + currTesthours);
    deadline.setMinutes(deadline.getMinutes() + currTestMinutes);
    deadline.setSeconds(deadline.getSeconds() + currTestSeconds);
    return deadline;
  };

  useEffect(() => {
    if (timer === "00:00:01") {
      handleNextSection();
    }
  }, [timer]);

  return (
    <>
      <header
        className="row timer dark-grey"
        style={{ backgroundColor: `${theme.backgroundColor}` }}
      >
        <div className="col-xs-7 candidate-details-area">
          <div className="user-details ng-scope" role="banner">
            <p className="user-details-header-text ng-scope">
              <span className="section-title">
                <span className="translated-text">
                  <strong>Section:</strong> {section} {sectionText}
                </span>
              </span>
            </p>
          </div>
        </div>
        <div className="col-xs-8 ng-scope" ui-view="timer">
          <div className="ng-scope ng-isolate-scope">
            <div className="timer-holder ng-scope">
              <div className="exam-timer-countdown intro-outro-timer">
                <div className="inner-Holder">
                  <button
                    role="timer"
                    data-minutes-left="60"
                    className="timer-button theme-not-hover-reversed"
                  >
                    <FontAwesomeIcon
                      className="fa fa-clock-o clock-ico"
                      icon={faClock}
                    />
                    <span className="total-time-remaining-label">
                      {sectionTime} Time Remaining:
                    </span>
                    <span className="total-time-remaining">
                      {timer ? timer : "00:00:00"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-7 ng-scope" ui-view="timer" style={{display: "flex" , justifyContent: "flex-end"}}>
          <button
            onClick={handleSaveExitSection}
            disabled={lastSecApiCall}
            className="btn btn-primary btn-lg finish-button ng-scope ng-isolate-scope"
            style={{
              backgroundColor: `${theme.btnColor} `,
              color: `${theme.textColor}`
            }}
          >
            <span className="ng-binding">Save & Exit</span>
          </button>
          <button
            onClick={handleSaveExitSection}
            disabled={lastSecApiCall}
            className="btn btn-primary btn-lg finish-button ng-scope ng-isolate-scope"
            style={{
              backgroundColor: `${theme.btnColor} `,
              color: `${theme.textColor}`,
              maxWidth: '100px',
            }}
          >
            <span className="ng-binding">Finish</span>
          </button>
        </div>
        {/* <div className="ng-isolate-scope">
          <div className="row">
            <div className="text-right right-header-holder finish-button-container col-xs-4 ng-scope col-xs-push-3">
              <button
                onClick={handleSaveExitSection}
                disabled={lastSecApiCall}
                className="btn btn-primary btn-lg finish-button ng-scope ng-isolate-scope"
                style={{
                  backgroundColor: `${theme.btnColor} `,
                  color: `${theme.textColor}`
                }}
              >
                <span className="ng-binding">Finish Test</span>
              </button>
              <button
                onClick={handleSaveExitSection}
                disabled={lastSecApiCall}
                className="btn btn-primary btn-lg finish-button ng-scope ng-isolate-scope"
                style={{
                  backgroundColor: `${theme.btnColor} `,
                  color: `${theme.textColor}`
                }}
              >
                <span className="ng-binding">Finish Test</span>
              </button>
            </div>
          </div>
        </div> */}
      </header>
      <div
        className="row secondary-header"
        style={{ color: `${theme.textColor}` }}
      >
        <div>
          <div className="col-md-15 col-sm-15 col-lg-15 exam-details-area">
            <p>
              <span className="translated-text">
                <strong>Test:</strong> OET Practice Test
              </span>
            </p>
          </div>
          <div className="col-md-7 col-sm-7 col-lg-7 candidate-details-area text-right">
            <p style={{ justifyContent: "flex-end", display: "flex" }}>
              <span className="translated-text">
                <strong>Candidate</strong>: USER Demo
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
