import {
  faPause,
  faPlay,
  faVolumeMute,
  faVolumeUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useEffect, useState } from "react";
import "./style.scss";

const AudioPlayerWithVisualizer = ({ audioSrc, playBtn }) => {
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const animationIdRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5); // Initial volume set to 50%
  const timeRef = useRef(0);
  const videoRef = useRef(null);
  const [progress, setProgress] = useState(0);

  const togglePlayPause = () => {};

  useEffect(() => {
    const audioElement = audioRef.current;
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }

    if (audioElement) {
      if (!analyserRef.current) {
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 32;
        dataArrayRef.current = new Uint8Array(
          analyserRef.current.frequencyBinCount
        );

        const source =
          audioContextRef.current.createMediaElementSource(audioElement);
        source.connect(analyserRef.current);
        analyserRef.current.connect(audioContextRef.current.destination);
      }

      const drawVisualizer = () => {
        animationIdRef.current = requestAnimationFrame(drawVisualizer);

        analyserRef.current.getByteFrequencyData(dataArrayRef.current);

        canvasCtx.fillStyle = "rgb(248 248 248)";
        canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

        const numberOfBars = 5;
        const barWidth = canvasElement.width / numberOfBars - 5;
        let barHeight;
        let x = 0;
        const padding = 5;

        timeRef.current += 0.1;

        for (let i = 0; i < numberOfBars; i++) {
          const offset = timeRef.current + i * ((Math.PI * 2) / numberOfBars);
          barHeight = Math.sin(offset) * 15 + dataArrayRef.current[i] / 8;

          barHeight = Math.max(2, barHeight);
          canvasCtx.fillStyle = `rgb(${barHeight * 5 + 50}, ${
            barHeight * 5 + 50
          }, ${barHeight * 5 + 50})
`;

          canvasCtx.fillRect(
            x,
            canvasElement.height - barHeight,
            barWidth,
            barHeight
          );

          x += barWidth + padding;
        }
      };

      if (isPlaying) {
        drawVisualizer();
      } else {
        cancelAnimationFrame(animationIdRef.current);
      }
    }

    return () => {
      cancelAnimationFrame(animationIdRef.current);
    };
  }, [isPlaying]);

  useEffect(() => {
    handlePlay();
  });

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: "rgb(248 248 248)",
        width: "50%",
        border: "1px solid",
        padding: "10% 0 0 0"
      }}
    >
      <canvas
        ref={canvasRef}
        width="100"
        height="30"
        style={{ display: "block", margin: "auto" }}
      />
      <audio ref={audioRef} src={audioSrc} style={{ display: "none" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          backgroundColor: "gray"
        }}
      >
        {playBtn && (
          <button onClick={togglePlayPause}>
            {isPlaying ? (
              <FontAwesomeIcon icon={faPause} />
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )}
          </button>
        )}
        <div
          style={{
            // backgroundColor: "gray",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px"
          }}
        >
          <FontAwesomeIcon
            icon={volume > 0 ? faVolumeUp : faVolumeMute}
            style={{ marginRight: "10px" }}
          />
          <input
            color="black"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            style={{
              width: "100px",
              accentColor: "dodgerblue"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayerWithVisualizer;
