import React, { useContext, useEffect, useState, useRef } from "react";
import "./style.scss";
import { ProgressBar } from "react-bootstrap";
import headPhoneAudioUrl from "../../assets/Audio/headphonecheck.mp3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";

const AudioPlayerPopup = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1); // Volume state
  const [duration, setDuration] = useState("00:00");
  const [currentTime, setCurrentTime] = useState("00:00");

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    const progressPercent = (audio.currentTime / audio.duration) * 100;
    setProgress(progressPercent);
    setCurrentTime(formatTime(audio.currentTime));
  };

  const handleLoadedMetadata = () => {
    const audio = audioRef.current;
    setDuration(formatTime(audio.duration));
  };

  const handleVolumeChange = (e) => {
    const progressBar = e.target.getBoundingClientRect();
    const clickPosition = e.clientX - progressBar.left;
    const newVolume = clickPosition / progressBar.width;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  return (
    <div className="audio-player">
      <button className="play-pause-btn" onClick={togglePlayPause}>
        {isPlaying ? "❚❚" : "►"}
      </button>

      <div className="time">
        <span>{currentTime}</span>
        {/* / <span>{duration}</span> */}
      </div>

      <div className="progress-striped-audio">
        <ProgressBar className="custom-progress-bar" now={progress} />
      </div>

      <div className="volume-control">
        <span role="img" aria-label="Volume">
          <FontAwesomeIcon icon={faVolumeUp} />
        </span>
        <div className="volume-progress" onClick={handleVolumeChange}>
          <ProgressBar
            now={volume * 100} // Volume represented as percentage of 100
            className="volume-progress-bar"
          />
        </div>
      </div>

      <audio
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        preload="auto"
        src={headPhoneAudioUrl}
        id="globalAudioPlayer"
      />
    </div>
  );
};

export default AudioPlayerPopup;
