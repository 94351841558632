import React, { useState, useEffect, useRef, useContext } from "react";
import "./style.scss";
import { AppContext } from "AppContext";
import { renderHTML } from "utilities/CommonFunc";

const PdfViewer = (props) => {
  const { theme } = useContext(AppContext);
  const { setIsPdfOpen, isPdfOpen, questionsData } = props;

  let currPartPDF = questionsData && questionsData[0]?.para;

  // let paragraph = questionsData && questionsData[0]?.paragraph;
  // let title = questionsData && questionsData[0]?.title;

  const [zoomLevel, setZoomLevel] = useState(1);

  const [selectedZoom, setSelectedZoom] = useState("Automatic Zoom");

  const pdfWrapperRef = useRef(null);

  const openModal = () => setIsPdfOpen(true);
  const closeModal = () => setIsPdfOpen(false);

  const zoomIn = () => setZoomLevel((prev) => Math.min(prev + 0.1, 4));
  const zoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.1, 0.5));

  const handleZoomChange = (event) => {
    const zoomValue = event.target.value;
    setSelectedZoom(zoomValue);

    const pdfWrapper = pdfWrapperRef.current;

    switch (zoomValue) {
      case "50%":
        setZoomLevel(0.5);
        break;
      case "75%":
        setZoomLevel(0.75);
        break;
      case "100%":
        setZoomLevel(1);
        break;
      case "125%":
        setZoomLevel(1.25);
        break;
      case "150%":
        setZoomLevel(1.5);
        break;
      case "200%":
        setZoomLevel(2);
        break;
      case "300%":
        setZoomLevel(3);
        break;
      case "400%":
        setZoomLevel(4);
        break;
      case "Actual Size":
        setZoomLevel(1);
        break;
      case "Page Fit":
        if (pdfWrapper) {
          const modalHeight = pdfWrapper.clientHeight;
          const contentHeight = pdfWrapper.scrollHeight;
          const newZoom = modalHeight / contentHeight;
          setZoomLevel(newZoom);
        }
        break;
      case "Page Width":
        if (pdfWrapper) {
          const modalWidth = pdfWrapper.clientWidth;
          const contentWidth = pdfWrapper.scrollWidth;
          const newZoom = modalWidth / contentWidth;
          setZoomLevel(newZoom);
        }
        break;
      default:
        setZoomLevel(1);
    }
  };

  return (
    <div className="Pdf-viewer-container">
      {isPdfOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div
              className="modal-header"
              style={{ backgroundColor: theme.backgroundColor }}
            >
              <div className="modal-title-container">
                <p className="modal-title">PDF Viewer</p>
                <button onClick={closeModal} className="close-btn">
                  X
                </button>
              </div>
              <div className="pdf-viewer-controls">
                <button
                  onClick={zoomIn}
                  className="zoom-btn"
                  style={{ backgroundColor: theme.subHeader }}
                >
                  +
                </button>
                <button
                  onClick={zoomOut}
                  className="zoom-btn"
                  style={{ backgroundColor: theme.subHeader }}
                >
                  -
                </button>

                <select
                  className="zoom-dropdown"
                  value={selectedZoom}
                  onChange={handleZoomChange}
                  style={{ backgroundColor: theme.subHeader }}
                >
                  <option>Automatic Zoom</option>
                  <option>50%</option>
                  <option>75%</option>
                  <option>100%</option>
                  <option>125%</option>
                  <option>150%</option>
                  <option>200%</option>
                  <option>300%</option>
                  <option>400%</option>
                  <option>Actual Size</option>
                  <option>Page Fit</option>
                  <option>Page Width</option>
                </select>
              </div>
            </div>
            <div
              className="modal-subheader"
              style={{ backgroundColor: theme.subHeader }}
            ></div>

            <div className="pdf-viewer-wrapper" ref={pdfWrapperRef}>
              <div className="pdf-viewer-container" ref={pdfWrapperRef}>
                <div
                  className="pdf-content"
                  style={{
                    transform: `scale(${zoomLevel})`,
                    transformOrigin: "top left",
                    width: `${100 / zoomLevel}%`,
                    padding: "10px 0px 10px 0px"
                  }}
                >
                  {/* <h3 className="header">{title}</h3> */}
                  <h4 style={{ justifyContent: "center", display: "flex" }}>
                    {/* {paragraph} */}
                  </h4>
                  {/* {currPartPDF?.map((section, index) => (
                  ))} */}
                  <div className="section-container">
                    {/* <p className="section-text">{section.title}</p> */}
                    <div className="section">
                      <p> {renderHTML(currPartPDF)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
