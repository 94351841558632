import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import reactStringReplace from "react-string-replace";
import { Form } from "react-bootstrap";
import $ from "jquery";
import AudioPlayerWithVisualizer from "components/AudioPlayerWithVisualizer";
import { decodeEntities } from "utilities/CommonFunc";

let score_L = 0;
const PartA = (props) => {
  const {
    currentPart,
    questionsData,
    currentIndex,
    setCurrentIndex,
    getAnswerAndMarksData,
    commenOnClickHandler,
    answersData
  } = props;

  let currQuestionAudio = questionsData && questionsData[currentIndex]?.audio;
  let currQuestionParagraph =
    questionsData && questionsData[currentIndex]?.para;
  let currAnswersData = answersData?.length !== 0 ? answersData : [];
  let currQuestionType =
    questionsData && questionsData[currentIndex]?.question_type;

  const [userAnswer, setUserAnswer] = useState([]);
  const [quesNum, setQuesNum] = useState();
  const [inputs, setInputs] = useState({});
  const localStorageKey = `L_Data`;

  // Decode html entities
  let takeDecodedHtml = decodeEntities(
    questionsData && questionsData[currentIndex]?.questions
  );
  let newhtmlentities =
    takeDecodedHtml && takeDecodedHtml.replaceAll("<p>", "");
  let newhtmlentities1 =
    newhtmlentities && newhtmlentities.replaceAll("</p>", "");
  let newhtmlentities2 =
    newhtmlentities1 && newhtmlentities1.replaceAll("&amp;", "");
  let newhtmlentities3 =
    newhtmlentities2 && newhtmlentities2.replaceAll("nbsp;", "");
  let newhtmlentities4 =
    newhtmlentities3 && newhtmlentities3.replaceAll("&lt;p&gt;", "");
  let newhtmlentities5 =
    newhtmlentities4 && newhtmlentities4.replaceAll("&lt;br/&gt;", "");
  let newhtmlentities6 =
    newhtmlentities5 && newhtmlentities5.replaceAll("<strong>", "");
  let newhtmlentities7 =
    newhtmlentities6 && newhtmlentities6.replaceAll("</strong>", "");
  let currQuestion =
    newhtmlentities7 && newhtmlentities7.replaceAll("<br />", "");

  useEffect(() => {
    const savedInputs = JSON.parse(localStorage.getItem(localStorageKey)) || {};
    setInputs(savedInputs);
  }, []);

  useEffect(() => {
    if (userAnswer && quesNum) {
      lMarksCalculation(userAnswer, quesNum);
    }
  }, [userAnswer, quesNum]);

  const handleChange = (e, number) => {
    const { value, id } = e.target;

    setUserAnswer(value);
    setQuesNum(Number(id));

    setInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs, [number]: value };
      localStorage.setItem(localStorageKey, JSON.stringify(updatedInputs));

      return updatedInputs;
    });
  };

  const lMarksCalculation = (userInput, quesNum) => {
    currAnswersData?.forEach((element, i) => {
      if (quesNum === `${i + 1}`) {
        if (
          element?.toLowerCase()?.split("|")?.includes(userInput.toLowerCase())
        ) {
          score_L = 1;
        } else {
          score_L = 0;
        }
      }
    });

    // Calling function And Get Data in ListeningSection Main File
    getAnswerAndMarksData(
      quesNum,
      userInput,
      // htmlAnswerArray,
      score_L,
      currentPart,
      currQuestionType
    );

    // htmlAnswerArray = [];
  };

  return (
    <div className="partA-wrapper">
      {currentIndex === 0 && (
        <div className="intro-section">
          <div className="listening-test-header">
            <h2>Occupational English Test</h2>
            <h3>Listening Test</h3>

            <p className="test-instructions">
              This is the online version of the Occupational English Test (OET).
              In the online OET, there are 'sections'. A section is a timed part
              of the test that you cannot go back to after you have moved onto
              the next section.
            </p>

            <p className="test-instructions">
              You’ll have time to read the questions before you hear each
              extract and you’ll hear each extract <b>ONCE ONLY</b>. Complete
              your answers as you listen.
            </p>
          </div>

          <div className="part-a-header">
            <h3 className="">Part A</h3>

            <p className="test-instructions">
              In this part of the test, you’ll hear two different extracts. In
              each extract, a health professional is talking to a patient.
            </p>

            <p className="test-instructions">
              For <b>questions 1-24</b>, complete the notes with information
              that you hear.
            </p>

            <p className="test-instructions">
              Now, look at the notes for extract one.
            </p>
          </div>
        </div>
      )}

      <div>
        <div>
          <AudioPlayerWithVisualizer audioSrc={currQuestionAudio} />
        </div>
        <div className="test-content-section">
          {currentIndex === 0 ? (
            <h3>Extract 1: Question 1-12</h3>
          ) : (
            <h3>Extract 2: Question 13-24</h3>
          )}

          <p className="test-instructions">{currQuestionParagraph}</p>

          <p>You now have thirty seconds to look at the notes.</p>
        </div>

        <div className="question-section">
          <span>
            {reactStringReplace(
              currQuestion,
              /(\•|\(\d+\)#DD#|<b>.*?<\/b>)/g,
              (match, i) => {
                if (match === "•") {
                  return <br key={`bullet-${i}`} />;
                }

                if (match.startsWith("<b>") && match.endsWith("</b>")) {
                  const boldText = match.replace(/<\/?b>/g, "");
                  return (
                    <React.Fragment key={`bold-${i}`}>
                      <br />
                      <strong>{boldText}</strong>
                    </React.Fragment>
                  );
                }

                const numberMatch = /\((\d+)\)#DD#/.exec(match);

                if (numberMatch) {
                  const number = numberMatch[1];

                  return (
                    <div key={`group-${i}`} className="input-wrapper">
                      <span>{`(${number})`}</span>

                      <Form.Control
                        type="text"
                        className="answer-input"
                        id={number}
                        onChange={(e) => handleChange(e, number)}
                        spellCheck="false"
                        autoComplete="off"
                        value={inputs[number] || ""}
                      />
                    </div>
                  );
                }

                return match;
              }
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PartA;
