import { combineReducers } from 'redux';
import { LogoutReducer } from "./LogoutReducer"
import { QuestionReducer } from "./QuestionReducer"
import { AnswerReducer } from "./AnswerReducer"

const rootReducer = combineReducers({
  LogoutReducer: LogoutReducer,
  QuestionReducer: QuestionReducer,
  AnswerReducer: AnswerReducer,
});

export default rootReducer;