import React, { useEffect, useState } from "react";
import "./style.scss";

let score_R = 0;

const PartB = (props) => {
  const {
    currentPage,
    currentPart,
    questionsData,
    currentIndex,
    setCurrentIndex,
    getAnswerAndMarksData,
    answersData
  } = props;

  let currQuestionData = questionsData && questionsData[currentPage - 1];
  let currQuestion =
    questionsData && questionsData[currentPage - 1]?.questions[0];

  let currAnswersData = answersData.length !== 0 ? answersData : [];
  let currQuestionType =
    questionsData && questionsData[currentPage - 1]?.question_type;

  const [questions, setQuestions] = useState([]);
  const [userAnswer, setUserAnswer] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [quesNum, setQuesNum] = useState();
  const localStorageKey = `R_Data`;

  useEffect(() => {
    const parseQuestions = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(currQuestion, "text/html");

      const questionBlocks = doc.querySelectorAll(".question-block");
      const parsedQuestions = Array.from(questionBlocks)
      ?.map((block) => {
        const questionId = block.querySelector(".section-description").id;
        const questionText = block.querySelector(
          ".section-description"
        ).innerText;

        const options = Array.from(block.querySelectorAll(".direction-option"))
        ?.map((option) => {
          const optionText = option.querySelector(".option-text").innerText;
          const optionDescription = option.querySelector(
            ".option-description"
          ).innerText;
          return { text: optionText, description: optionDescription };
        });

        return {
          id: questionId,
          question: questionText,
          options
        };
      });

      setQuestions(parsedQuestions);
    };

    parseQuestions();
  }, [currQuestion]);

  useEffect(() => {
    const savedOptions =
      JSON.parse(localStorage.getItem(localStorageKey)) || {};
    setSelectedOptions(savedOptions);
  }, []);

  useEffect(() => {
    if (selectedOptions && quesNum) {
      rMarksCalculation(userAnswer, quesNum);
    }
  }, [selectedOptions, quesNum, userAnswer]);

  const handleChange = (e, questionId) => {
    const updatedOptions = {
      ...selectedOptions,
      [questionId]: e.description
    };

    setUserAnswer(e.description);
    setSelectedOptions(updatedOptions);
    setQuesNum(Number(questionId));
    localStorage.setItem(localStorageKey, JSON.stringify(updatedOptions));
  };

  const rMarksCalculation = (userInput, quesNum) => {
    currAnswersData?.forEach((element, i) => {
      if (quesNum === i + 1) {
        if (
          element?.toLowerCase()?.split("|")?.includes(userInput.toLowerCase())
        ) {
          score_R = 1;
        } else {
          score_R = 0;
        }
      }
    });

    // Calling function And Get Data in ListeningSection Main File
    getAnswerAndMarksData(
      quesNum,
      userInput,
      // htmlAnswerArray,
      score_R,
      currentPart,
      // currQuestion,
      currQuestionType
    );

    // htmlAnswerArray = [];
  };

  return (
    <div className="reading-partB-wrapper">
      {currentPage === 1 ? (
        <div className="part-intro">
          <h3>Part B</h3>
          <p className="section-title">
            In this part of the test, there are two texts about different
            aspects of healthcare. For <b>questions 21-26</b>, choose the answer
            (<b>A</b>, <b>B</b> or <b>C</b>) which you think fits best according
            to the text.
          </p>
        </div>
      ) : (
        <div></div>
      )}
      <div className="paragraph-wrapper">
        <div className="paragraph-container">
          <h4>{currQuestionData?.paragraphTitle}</h4>
          <p className="paragraph">{currQuestionData?.para}</p>
        </div>
        <p className="question">{currQuestionData?.option_title}</p>
      </div>

      <div className="question-block">
        <div className="radio-group">
          {questions?.map((question, index) => (
            <div key={index} className="question-block">
              <p className="section-description">
                {question?.id} {question?.question}
              </p>

              <div className="radio-group">
                {question?.options?.map((option, key) => (
                  <div key={key} className="direction-option">
                    <div
                      className={`option-container ${
                        selectedOptions[question?.id] === option.description
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleChange(option, question?.id, "#")}
                    >
                      <div
                        className={`option-container2 ${
                          selectedOptions[question?.id] === option.description
                            ? "selected"
                            : ""
                        }`}
                      >
                        {option.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartB;
