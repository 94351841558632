import wordsToNumbers from "words-to-numbers";

// Country words dictionary
export const countryWDictionary = [
  {
    USWord: "ise",
    UKWord: "ize"
  },
  {
    USWord: "iza",
    UKWord: "isa"
  },
  {
    USWord: "or",
    UKWord: "our"
  },
  {
    USWord: "ours",
    UKWord: "ors"
  },
  {
    USWord: "lled",
    UKWord: "led"
  },
  {
    USWord: "lling",
    UKWord: "ling"
  },
  {
    USWord: "ellr",
    UKWord: "elr"
  },
  {
    USWord: "ence",
    UKWord: "ense"
  },
  {
    USWord: "ogue",
    UKWord: "og"
  },
  {
    USWord: "of",
    UKWord: "off"
  },
  {
    USWord: "to",
    UKWord: "too"
  },
  {
    USWord: "wearing",
    UKWord: "varying"
  },
  {
    USWord: "galaxy's",
    UKWord: "galaxies"
  },
  {
    USWord: "galaxy",
    UKWord: "galaxies"
  },
  {
    USWord: "galaxys",
    UKWord: "galaxies"
  }
];

// Check type of first character
export const checkIsNumber = (char) => {
  return /^\d$/.test(char);
};

// Convert numeric to word
export const numericToWords = (s) => {
  var th = ["", "thousand", "million", "billion", "trillion"];
  var dg = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine"
  ];
  var tn = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen"
  ];
  var tw = [
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety"
  ];

  s = s.toString();
  s = s.replace(/[\, ]/g, "");
  if (s != parseFloat(s)) return "not a number ";
  var x = s.indexOf(".");
  if (x == -1) x = s.length;
  if (x > 15) return "too big";
  var n = s.split("");
  var str = "";
  var sk = 0;
  for (var i = 0; i < x; i++) {
    if ((x - i) % 3 == 2) {
      if (n[i] == "1") {
        str += tn[Number(n[i + 1])] + " ";
        i++;
        sk = 1;
      } else if (n[i] != 0) {
        str += tw[n[i] - 2] + " ";
        sk = 1;
      }
    } else if (n[i] != 0) {
      str += dg[n[i]] + " ";
      if ((x - i) % 3 == 0) str += "hundred ";
      sk = 1;
    }
    if ((x - i) % 3 == 1) {
      if (sk) str += th[(x - i - 1) / 3] + " ";
      sk = 0;
    }
  }
  if (x != s.length) {
    var y = s.length;
    str += "point ";
    for (var i = x + 1; i < y; i++) str += dg[n[i]] + " ";
  }
  return str.replace(/\s+/g, " ").trim();
};

// for scale module
export function percentageOFScaleStrict(percent) {
  return eval(((percent / 100) * 15).toFixed(2));
}

// for scale module
export function percentageOFScaleLoss(percent) {
  return eval(((percent / 100) * 10).toFixed(2));
}

// cover 100 into 5
export const calculationScore = (score) => {
  if (score == 0) {
    return 0.0;
  } else if (score <= 5) {
    return 0.2;
  } else if (score <= 10) {
    return 1.0;
  } else if (score <= 15) {
    return 1.7;
  } else if (score <= 20) {
    return 2.0;
  } else if (score < 25) {
    return 2.2;
  } else if (score <= 30) {
    return 2.5;
  } else if (score <= 35) {
    return 2.2;
  } else if (score <= 40) {
    return 2.5;
  } else if (score <= 45) {
    return 2.7;
  } else if (score <= 50) {
    return 3.0;
  } else if (score <= 55) {
    return 3.2;
  } else if (score <= 60) {
    return 3.5;
  } else if (score <= 75) {
    return 3.7;
  } else if (score <= 80) {
    return 4.0;
  } else if (score <= 85) {
    return 4.2;
  } else if (score <= 90) {
    return 4.5;
  } else if (score <= 99) {
    return 4.7;
  } else {
    return 5.0;
  }
};

export const pluralize = (singular) => {
  let nonPural = [
    "deer",
    "sheep",
    "fish",
    "bison",
    "moose",
    "Swine",
    "mircraft",
    "spacecraft",
    "species",
    "offspring",
    "trout",
    "pike",
    "salmon",
    "gtuna",
    "rouse",
    "plankton",
    "barracuda",
    "lingcod",
    "sturgeon",
    "herring",
    "bass",
    "squid",
    "squill",
    "wahoo",
    "guppy",
    "peafowl",
    "hippopotamus",
    "giraffe",
    "koala",
    "kangaroo",
    "okapi",
    "elephant",
    "rhinoceros",
    "platypus",
    "gazelle",
    "orangutan",
    "gorilla",
    "bonito",
    "mackerel",
    "sardine",
    "zebra",
    "meerkat",
    "antelope",
    "elk",
    "ostrich",
    "insect",
    "centipede",
    "cuttlefish",
    "ray",
    "eel",
    "reindeer",
    "lynx",
    "porcupine",
    "hedgehog",
    "badger",
    "mussel",
    "scallop",
    "snail",
    "crab",
    "alpaca",
    "llama",
    "duck",
    "swallow",
    "termite",
    "bee",
    "wasp",
    "hornet",
    "moth",
    "butterfly",
    "mantis",
    "bison",
    "bingo",
    "manatee",
    "armadillo",
    "dhole",
    "prawn",
    "cockroach",
    "pheasant",
    "gnu",
    "baboon",
    "vulture",
    "lobster",
    "geoduck",
    "tortoise",
    "puma",
    "ferret",
    "fox",
    "seal",
    "walrus",
    "chimpanzee",
    "tarsier",
    "squirrel",
    "mongoose",
    "lemur",
    "ocelot",
    "coati",
    "sugar glider",
    "rhea",
    "emu",
    "platypu"
  ];

  const irregulars = {
    man: "men",
    woman: "women",
    child: "children",
    tooth: "teeth",
    foot: "feet",
    person: "people",
    die: "dice",
    criterion: "criterion"
    // Add more irregular forms as needed
  };
  if (nonPural.includes(singular)) {
    // Check for irregular forms
    if (irregulars[singular.toLowerCase()]) {
      return irregulars[singular.toLowerCase()];
    }
    // Add more specific rules as needed
    else if (
      singular.endsWith("y") &&
      !["a", "e", "i", "o", "u"].includes(singular.charAt(singular.length - 2))
    ) {
      return singular.slice(0, -1) + "ies"; // Change 'y' to 'ies'
    } else if (singular.endsWith("ss")) {
      return singular + "es"; // Nouns ending in 'ss' get 'es'
    } else if (
      ["s", "x", "z"].includes(singular.charAt(singular.length - 1)) ||
      ["ch", "sh"].includes(singular.slice(-2))
    ) {
      return singular + "es"; // Nouns ending in s, x, z, ch, or sh get 'es'
    } else if (singular.endsWith("fe")) {
      return singular.slice(0, -2) + "ves"; // Change 'fe' to 'ves'
    } else if (singular.endsWith("f")) {
      return singular.slice(0, -1) + "ves"; // Change 'f' to 'ves'
    } else {
      return singular + "s"; // Default: Add 's' to the end
    }
  } else {
    return singular;
  }
};

export const noSpecialCharacters = (str) => {
  return str.replace(/[^a-zA-Z0-9 ]/g, "");
};
//add by himanshi
export const noSpecialCharactersWord = (str) => {
  return str.replace(/[^a-zA-Z0-9.]/g, "");
};
// end by himanshi

export const getBrowserName = () => {
  let browserInfo = navigator.userAgent;
  let browser;
  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = "Opera";
  } else if (browserInfo.includes("Edg")) {
    browser = "Edge";
  } else if (
    browserInfo.includes("Safari") &&
    !browserInfo.includes("Chrome")
  ) {
    browser = "Safari";
  } else if (browserInfo.includes("Chrome")) {
    browser = "Chrome";
  } else if (browserInfo.includes("Firefox")) {
    browser = "Firefox";
  } else {
    browser = "unknown";
  }
  return browser;
};

export const getCheckDevice = () => {
  let deviceInfo = navigator.userAgent;
  let device;
  if (
    deviceInfo.includes("Linux") ||
    deviceInfo.includes("X11") ||
    deviceInfo.includes("Android") ||
    /Android/i.test(deviceInfo)
  ) {
    device = "Android";
  } else if (
    deviceInfo.includes("iPad") ||
    deviceInfo.includes("iPod") ||
    deviceInfo.includes("iPhone") ||
    /iPad|iPhone|iPod/.test(deviceInfo)
  ) {
    device = "iOS";
  } else if (deviceInfo.includes("Macintosh")) {
    device = "macOS";
  } else {
    device = "unknown";
  }
  return device;
};

export const findWordBeforeLastDot = (str) => {
  let stringNew = str.split(" ").reverse();

  if (str.includes(".")) {
    if (stringNew[stringNew.length - 1].includes(".")) {
      return stringNew[stringNew.length - 1];
    } else {
      let i;
      for (i of stringNew) {
        if (i.includes(".")) {
          let dotArry = i.split(".");
          if (dotArry.length > 2) {
            return `${dotArry[dotArry.length - 2]}.${
              dotArry[dotArry.length - 1]
            }`;
          }
          return i;
        }
      }
      //   const lastDotIndex = str.lastIndexOf('.');
      //   const substring = str.slice(0, lastDotIndex);
      //   const words = substring.split(' ');
      //   const lastWord = words[words.length - 1];
      //   return lastWord;
    }
  } else {
    return str.split(" ")[str.split(" ").length - 1];
  }
};

export const forCheckDubalicetsFormArray = (questions_words) => {
  // Create an object to store word frequencies
  let wordCounts = {};
  let result = [];
  questions_words.forEach((word) => {
    if (word.trim() !== "") {
      wordCounts[word] = (wordCounts[word] || 0) + 1;
      if (wordCounts[word] > 1) {
        result.push(`${word}#${wordCounts[word]}`);
      } else {
        result.push(word);
      }
    }
  });
  return result;
};

//add by himanshi
export let arrayOfReplacement = [
  { k: "perceiving", v: "per saving" },
  { k: "nowhere", v: "now where" },
  { k: "industries", v: "industry is" },
  { k: "technology", v: "technologies" },
  { k: "organizations", v: "organisations" },
  { k: "videotaping", v: "video taping" },
  { k: "unions take", v: "union stake" },
  { k: "i`ve", v: "i have" },
  { k: "text books", v: "textbooks" },
  { k: "1st", v: "first" },
  { k: "2nd", v: "second" },
  { k: "3rd", v: "third" },
  { k: "4th", v: "fourth" },
  { k: "5th", v: "fifth" },
  { k: "6th", v: "sixth" },
  { k: "7th", v: "seventh" },
  { k: "8th", v: "eighth" },
  { k: "9th", v: "ninth" },
  { k: "10th", v: "tenth" },
  { k: "11th", v: "eleventh" },
  { k: "12th", v: "twelfth" },
  { k: "13th", v: "thirteenth" },
  { k: "14th", v: "fourteenth" },
  { k: "15th", v: "fifteenth" },
  { k: "16th", v: "sixteenth" },
  { k: "17th", v: "seventeenth" },
  { k: "18th", v: "eighteenth" },
  { k: "19th", v: "nineteenth" },
  { k: "20th", v: "twentieth" },
  { k: "aim", v: "am" },
  { k: "returned", v: "written" },
  { k: "return", v: "written" },
  { k: "willem de", v: "william day" },
  { k: "were", v: "wear" },
  { k: "sight", v: "site" },
  { k: "archeology ", v: "archaeology" },
  { k: "will ", v: "wheel" },
  { k: "were ", v: "where" },
  { k: "each ", v: "inch" },
  { k: "easy", v: "essay" },
  { k: "organization's", v: "organisations" },
  { k: "traffic's", v: "traffics" },
  { k: "that's", v: "that's" },
  { k: "journal", v: "general" },
  { k: "ev", v: "evie" },
  { k: "email", v: "e mail" },
  { k: "sphere", v: "spear" },
  { k: "analyses", v: "analysis" },
  { k: "topics", v: "tropics" },
  { k: "topics", v: "trophics" },
  { k: "sales", v: "cells" },
  { k: "globalization", v: "globalisation" },
  { k: "east asia", v: "estasia" },
  { k: "were", v: "we are" },
  { k: "continuous", v: "continues" },
  { k: "does not", v: "doesn't" },
  { k: "did not", v: "didn't" },
  { k: "organisations", v: "organisation's" },
  { k: "lunchtime", v: "lunch time" },
  // { k: "to", v: "2" },
  // { k: "too", v: "2" },
  { k: "spent", v: "spend" },
  { k: "organise", v: "organize" },
  { k: "grateful", v: "great full" },
  { k: "moons", v: "moon's" },
  { k: "bones", v: "bone's" },
  { k: "awake", v: "a week" },
  { k: "hour", v: "our" },
  { k: "two", v: "too" },
  { k: "vary", v: "very" },
  { k: "today", v: "2 day" },
  { k: "specially", v: "especially" },
  { k: "no", v: "know" },
  { k: "this", v: "these" },
  { k: "there", v: "their" },
  { k: "man", v: "men" },
  { k: "man", v: "men" },
  { k: "january", v: "jan" },
  { k: "february", v: "feb" },
  { k: "march", v: "mar" },
  { k: "april", v: "april" },
  { k: "may", v: "may" },
  { k: "june", v: "jun" },
  { k: "july", v: "jul" },
  { k: "august", v: "aug" },
  { k: "september", v: "sep" },
  { k: "october", v: "oct" },
  { k: "november", v: "nov" },
  { k: "december", v: "dec" },
  { k: "sun", v: "son" },
  { k: "i am", v: "i'm" },
  { k: "it's", v: "it’s" }, //add
  { k: "it’s", v: "it's" }, //add
  { k: "it's", v: "it's" },
  { k: "it’s", v: "it’s" },
  { k: "it's", v: "its" },
  { k: "it’s", v: "its" },
  { k: "we have", v: "we've" },
  { k: "i have", v: "i've" },
  { k: "jio", v: "geo" },
  { k: "you are", v: "you're" },
  { k: "i'll", v: "i_will" },
  { k: "i will", v: "i_will" },
  { k: " °", v: "degree" },
  { k: " °", v: "degrees" },
  // { k: " °c", v: "degree Celsius" },
  // { k: " °c", v: "degree celsius" },
  { k: "it is", v: "it's" },
  { k: "it is", v: "it is" },
  { k: "it has", v: "it's" },
  { k: "i am", v: "i'm" },
  { k: "you have", v: "you've" },
  { k: "he is", v: "he's" },
  { k: "he has", v: "he's" },
  { k: "she is", v: "she's" },
  { k: "she has", v: "she's" },
  { k: "they are", v: "they're" },
  { k: "we are", v: "we're" },
  { k: "will not", v: "won't" },
  { k: "do not", v: "don't" },
  { k: "cannot", v: "can't" },
  { k: "could not", v: "couldn't" },
  { k: "should not", v: "shouldn't" },
  { k: "is not", v: "isn't" },
  { k: "are not", v: "aren't" },
  { k: "was not", v: "wasn't" },
  { k: "has not", v: "hasn't" },
  { k: "have not", v: "haven't" },
  { k: "that is", v: "that's" },
  { k: "that has", v: "that's" },
  { k: "what is", v: "what's" },
  { k: "what has", v: "what's" },
  { k: "let us", v: "let's" },
  { k: "country", v: "country's" }
];
//for dot

export const apostrophe = (s) => {
  let dotStr = "";
  const regex = /(?<=\w)\'(?=\w)/g;
  const regexB = /(?<=\w)\’(?=\w)/g;
  dotStr = s.replace(regex, "##apostrophe##").replace(regexB, "##apostrophe##");
  return dotStr;
};
//for hyphen
export const processHyphen = (str) => {
  if (/-\w+/.test(str)) {
    return str.replace(/-/g, " ");
  } else {
    return str.replace(/-/g, "");
  }
};
//for special  character remove
export const noSpecialSeparators = (queStr) => {
  const queString = queStr
    .replace(/%/g, " %")
    .replace(/per cent/g, "%")
    .replace(/percentage/g, " %")
    .replace(/i will/g, "i'll")
    .replace(/degree Celsius/g, " °C")
    .replace(/°C/g, " °C")
    .replace(/°/g, " °");

  let modifiedString = "";
  const regex =
    /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2},\s\d{4}\b/g;

  const specialSeparator = /[&\/\\@!+=^()$~`":;*?<>\[\]{}_']/g;
  const dotStr = apostrophe(processDot(queString));
  const hyphenStr = processHyphen(dotStr);
  const removeSpecialSeparator = hyphenStr
    .toLowerCase()
    .replaceAll(specialSeparator, " ");
  const removeCommas = removeSpecialSeparator
    .toLowerCase()
    .replace(/[()]/g, "")
    .replace(/\./g, "")
    .replace(/##apostrophe##/g, "'");
  const removeDoubleSpace = removeCommas.replaceAll("  ", " ");
  const matches = removeDoubleSpace.match(regex);
  if (matches) {
    modifiedString = removeDoubleSpace.replace(regex, (match) =>
      match.replace(",", "##date##")
    );
  } else {
    modifiedString = removeDoubleSpace;
  }

  const removeDoubleSpaceArr = modifiedString.split(",");
  let fStr = " ";
  removeDoubleSpaceArr.map((val) => {
    if (!isNaN(val[0])) {
    } else {
      val = " " + val;
    }
    fStr = fStr + val;
  });
  return fStr;
};

export const noSpecialSeparatorsForUserAns = (userStr) => {
  const specialSeparator = /[&\/\\@!+=^()$~`":;*?<>\[\]{}_]/g;
  const userAnsStr = userStr
    .replace(/%/g, " %")
    .replace(/per cent/g, " %")
    .replace(/percentage/g, " %")
    .replace(/i will/g, "i_will")
    .replace(/degree Celsius/g, " °C")
    .replace(/°/g, " °")
    .replace(/°C/g, " °c")
    .replace(/°c/g, " °c")
    .replace(/###/g, ".");
  const hyphenStr = processHyphen(userAnsStr);
  const removeSpecialSeparator = hyphenStr
    .toLowerCase()
    .replaceAll(specialSeparator, " ");
  const removeCommas = removeSpecialSeparator
    .toLowerCase()
    .replace(/[()]/g, "");
  // .replace(/\./g, "");
  const removeDoubleSpace = removeCommas.replaceAll("  ", " ");
  const removeDoubleSpaceArr = removeDoubleSpace.split(",");
  let fStr = " ";
  removeDoubleSpaceArr.map((val) => {
    if (!isNaN(val[0])) {
    } else {
      val = " " + val;
    }
    fStr = fStr + val;
  });
  return fStr;
};

export const digitToNum = (s) => {
  var th = ["", "thousand", "million", "billion", "trillion"];
  var dg = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine"
  ];
  var tn = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen"
  ];
  var tw = [
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety"
  ];

  s = s.toString();
  s = s.replace(/[\, ]/g, "");
  if (s != parseFloat(s)) return "0";
  var x = s.indexOf(".");
  if (x == -1) x = s.length;
  if (x > 15) return "too big";
  var n = s.split("");
  var str = "";
  var sk = 0;
  for (var i = 0; i < x; i++) {
    if ((x - i) % 3 == 2) {
      if (n[i] == "1") {
        str += tn[Number(n[i + 1])] + " ";
        i++;
        sk = 1;
      } else if (n[i] != 0) {
        str += tw[n[i] - 2] + " ";
        sk = 1;
      }
    } else if (n[i] != 0) {
      str += dg[n[i]] + " ";
      if ((x - i) % 3 == 0) str += "hundred ";
      sk = 1;
    }
    if ((x - i) % 3 == 1) {
      if (sk) str += th[(x - i - 1) / 3] + " ";
      sk = 0;
    }
  }
  if (x != s.length) {
    var y = s.length;
    str += "point ";
    for (var i = x + 1; i < y; i++) str += dg[n[i]] + " ";
  }
  return str.replace(/\s+/g, " ");
}; //for digit to num

//for changing word
export const wordChange = (str) => {
  const splitString = str.split(" ");

  splitString.map((val, index) => {
    // const digitToWords = digitToNum(val);

    arrayOfReplacement.map((value, key) => {
      if (val.slice(-2) === value.v) {
        str = str + " " + value.k + "_2";
      }
      if (val === value.k) {
        str = str + " " + value.v + "_2";
      }
      if (val === value.v) {
        str = str + " " + value.k + "_2";
      }
    });
    // if (digitToWords !== "0") {
    //   str = str + " " + digitToWords;
    // }
  });
  return str;
}; //for changing word

export const addSingular = (str) => {
  const words = str.split(" ");

  const modifiedWords = [];
  words.map((word) => {
    if (word.charAt(word.length - 1) === "s") {
      const modifiedWord = word.slice(0, -1) + "_2";
      modifiedWords.push(modifiedWord);
    }
  });
  return str + " " + modifiedWords.join(" ");
}; // for removing s or es

export const checkDuplicateWithHash = (str) => {
  const questions_words = str.split(" ");
  let wordCounts = {};
  let result = [];
  questions_words.map((word) => {
    if (word.trim() !== "") {
      wordCounts[word] = (wordCounts[word] || 0) + 1;
      if (wordCounts[word] > 1) {
        result.push(`${word}#${wordCounts[word]}`);
      } else {
        result.push(`${word}#${wordCounts[word]}`);
      }
    }
  });

  return result.join(" ");
}; //duplicated with hash

export const roundToQuarter = (number) => {
  return Math.round(number * 4) / 4;
}; // round Quarter of score

// It use to custom 1.24=1 1.25=1.5 1.75=2
export const roundHalf = (number) => {
  return Math.round(number * 2) / 2;
}; // half Quarter of score

export const removeHashAndAfter = (str) => {
  const words = str.split(" ");

  // Use map to iterate through each word and remove "#" and everything after it
  const modifiedWords = words.map(function (word) {
    return word.replace(/#.*/, "");
  });

  const outputString = modifiedWords.join(" ");

  return outputString;
};
export const ParseFloat = (str, val) => {
  if (/(?<=\d)\.(?=\d)/g.test(str)) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  } else {
    return Number(str);
  }
}; //for after dot i want one number only
export const extractNumbersWithN_WordsWithW = (str) => {
  const extractNumWith_N = (str) => {
    var regex =
      /\b(?:zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion)(?:(?:\s+and\s+|\s+)?(?:zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion))*\b/gi;

    var match;
    var modifiedNumber = "";
    while ((match = regex.exec(str)) !== null) {
      var modifiedPhrase = match[0].toLowerCase();
      modifiedNumber =
        modifiedNumber +
        " " +
        wordsToNumbers(modifiedPhrase).toString().replace(/\s+/g, "") +
        "_N";
    }
    return modifiedNumber.trim();
  }; //extractNumbersWith_N

  const extractWordsWith_W = (str) => {
    const matches = str.match(/\d+(\.\d+)?/g);
    if (matches !== null) {
      return matches
        .map(function (number) {
          if (/\./.test(number)) {
            return;
          } else {
            return numericToWords(number).replace(/ /g, "_") + "_W ";
          }
        })
        .join(" ");
    } else {
      return "";
    }
  }; //extractWordsWith_W
  const numbers1 = extractNumWith_N(str);
  const numbers2 = extractWordsWith_W(str);
  const numbers3 = extractNumberPhrases(str);

  return str + " " + numbers1 + " " + numbers2 + " " + numbers3;
}; //for number-to-word & word-to-number
export const StringToNumberConverter = (str) => {
  // const inputStrings = [str];

  // const num = inputStrings
  //   .map((str) => wordsToNumbers(str).toString().replace(/\s+/g, ""))
  //   .join(" ");

  // return num;
  var regex =
    /\b(?:zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion)(?:(?:\s+and\s+|\s+)?(?:zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion))*\b/gi;

  var match;
  while ((match = regex.exec(str)) !== null) {
    var modifiedPhrase = match[0].toLowerCase();

    str = str.replace(
      modifiedPhrase,
      wordsToNumbers(modifiedPhrase).toString().replace(/\s+/g, "")
    );
  }
  return str.trim();
}; //convert strings to number

export const extractNumberPhrases = (inputString) => {
  var regex =
    /\b(?:zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion)(?:(?:\s+and\s+|\s+)?(?:zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion))*\b/gi;
  var match;

  while ((match = regex.exec(inputString)) !== null) {
    var modifiedPhrase = match[0].toLowerCase().replace(/\s+/g, "_");
    inputString = inputString.replace(match[0], modifiedPhrase);
  }
  return inputString;
}; //for word between underscore use
export const numberToWords = (number) => {
  // Arrays defining the words for numbers
  var ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine"
  ];
  var teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen"
  ];
  var tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety"
  ];

  // Function to convert a number less than 1000 into words
  function convertLessThanOneThousand(num) {
    if (num >= 100) {
      return (
        ones[Math.floor(num / 100)] +
        " hundred " +
        convertLessThanOneThousand(num % 100)
      );
    } else if (num >= 20) {
      return tens[Math.floor(num / 10)] + " " + ones[num % 10];
    } else if (num >= 10) {
      return teens[num - 10];
    } else {
      return ones[num];
    }
  }

  // Function to add appropriate suffix for large numbers
  function addSuffix(words, divisor, suffix) {
    return (
      words +
      " " +
      suffix +
      (number % divisor === 0 ? "" : " " + numberToWords(number % divisor))
    );
  }

  // Main function
  if (number === 0) {
    return "zero";
  } else if (number < 0) {
    return "negative " + numberToWords(-number);
  } else {
    var words = "";
    if (number < 1000) {
      words = convertLessThanOneThousand(number);
    } else if (number < 1000000) {
      words = addSuffix(
        numberToWords(Math.floor(number / 1000)),
        1000,
        "thousand"
      );
    } else if (number < 1000000000) {
      words = addSuffix(
        numberToWords(Math.floor(number / 1000000)),
        1000000,
        "million"
      );
    } else {
      words = addSuffix(
        numberToWords(Math.floor(number / 1000000000)),
        1000000000,
        "billion"
      );
    }
    return words.trim();
  }
};

// for reorder - add by himanshi
export const compareArrays = (arr1, arr2) => {
  const result = [];

  for (let i = 0; i < arr1.length; i++) {
    const value1 = arr1[i];
    const value2 = arr2[i];

    if (value2 === "") {
      result.push(`N_${value1}_W`);
    } else if (value1 === value2) {
      result.push(`${value1}_${value2}_R`);
    } else {
      result.push(`${value1}_${value2}_W`);
    }
  }

  return result;
};
//end

export const extractWords = (str) => {
  // Remove HTML tags
  const noHTML = str.replace(/<\/?[^>]+(>|$)/g, "");
  // Remove non-word characters and split by spaces
  const wordsArray = noHTML.match(/\b\w+\b/g);
  // Join the array back into a string of words
  return wordsArray ? wordsArray.join(" ") : "";
};

export const rgbToHex = (rgb) => {
  const [r, g, b] = rgb.match(/\d+/g).map(Number);
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
};

export const decodeEntities = (function () {
  let cache = {},
    character,
    e = document.createElement("div");
  return function (html) {
    return html?.replace(/([&][^&; ]+[;])/g, function (entity) {
      character = cache[entity];
      if (!character) {
        e.innerHTML = entity;
        if (e.childNodes[0])
          character = cache[entity] = e.childNodes[0].nodeValue;
        else character = "";
      }
      return character;
    });
  };
})();

export const cleanQuestionData = (data) => {
  return data.map((item) => {
    let question = item.question || item.option_title || "";
    let decodedHtml = decodeEntities(question);
    let cleanedHtml = decodedHtml
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("&amp;", "&")
      .replaceAll("nbsp;", "")
      .replaceAll("&lt;p&gt;", "")
      .replaceAll("&lt;br/&gt;", "")
      .replaceAll("<strong>", "")
      .replaceAll("</strong>", "")
      .replaceAll("<br />", "");

    return {
      id: item.id,
      cleaned_question: cleanedHtml,
      correct_answer: item.correct_answer
    };
  });
};

export const renderHTML = (htmlString) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

export const isTextValid = (text) => {
  return text != null && text != undefined && text != "";
};

export const isArrayValidAndNotEmpty = (array) => {
  return array != null && Array.isArray(array) && array.length > 0;
};

export const NameRemoveErrorFromArry = [
  "summarize",
  "nonprofit",
  "recognise",
  "center",
  "organize",
  "problem",
  "problems",
  "noc",
  "burdett",
  "cardless",
  "airplanes",
  "globalization",
  "globalizations",
  "ones",
  "forwards",
  "theater",
  "penalize",
  "travelers",
  "traveler",
  "organizations",
  "organization",
  "advancing",
  "base",
  "cashless",
  "cardless",
  "maximize",
  "emphasize",
  "airplane",
  "behaviors",
  "behavior",
  "aspect",
  "life",
  "labor",
  "humor",
  "laster",
  "laffing",
  "tyrannize",
  "persia",
  " ranging",
  "lyubomirsky",
  "diener",
  "coworkers",
  "half way",
  "call-center",
  "nevo",
  "berrin",
  "talya",
  "decision making",
  "eugènebelgrand",
  "vanne",
  "parcmontsouris",
  "ourq",
  "haussmann",
  "alphand",
  "parc",
  "des",
  "buttes-chaumont",
  "night clubs",
  "measur",
  "paleolithic",
  "enlene",
  "prioritise",
  "kahane",
  "technolog",
  "90 hour",
  "workweeks",
  "kengen",
  "olkaria",
  "342°c",
  "90°c",
  "searle",
  "hmong",
  "globalised",
  "koraljka",
  "aleks",
  "trichopoulos",
  "fla",
  "twery",
  "femalesdo",
  "garamszegi",
  "ultracool",
  "eblm",
  "southern africa",
  "semi-desert",
  "khoikhoi",
  "central africa",
  "volstead",
  "livable",
  "ringstrasse",
  "batu",
  "indonesias",
  "sipadan",
  "celebes",
  "kinabalu",
  "sepilok",
  "orang utan",
  "pot belly",
  "someplace",
  "erdogan",
  "its",
  "bio-fuel",
  "labeled",
  "sorte",
  "several",
  "emis",
  "sci",
  "cafes",
  "un-posed",
  "hair style",
  "little bit",
  "affecting",
  "new-borns",
  "new-born",
  "oostenbroek",
  "grayer",
  "expect- that",
  "cells- in",
  "wooliest",
  "xiaohai",
  "policy makers",
  "unep",
  "child-centered",
  "car- your",
  "cutin",
  "forty five",
  "tank",
  "pollitt",
  "pediatrics",
  "green-burgeson",
  "nonfat",
  "hirshburg",
  "they're",
  "tversky",
  "viscereal",
  "arawak",
  "davasobel",
  "futhermore",
  "shark- believed",
  "shiels",
  "sanna",
  "years- possibly",
  "lehner",
  "battista",
  "caviglia",
  "selim",
  "craugastor",
  "carbot",
  "problem solving",
  "pollitt",
  "non fat",
  "pre-schoolers",
  "multigenerational",
  "groundcovers",
  "micro-fauna",
  "ift",
  "rhizobium",
  "generally",
  "lima",
  "fava",
  "amongst",
  "blue- in",
  "mammas",
  "multi-purpose",
  "sheils",
  "greenland sharks",
  "volunteerism",
  "nootka",
  "cratylus",
  "impared",
  "kreider",
  "placeboes",
  "purdon",
  "charnigo",
  "brand new",
  "amongst",
  "ill treatment",
  "berners- lee",
  "double blind",
  "vitamin d",
  "secosteroids",
  "hardi",
  "neuro-imaging",
  "signaling",
  "earle",
  "togetherï¼œthe",
  "shippingï¼œseismic",
  "uea",
  "wormstone",
  "cell-signaling",
  "buildup",
  "tversky",
  "ift",
  "passionfruit",
  "slps",
  "volstead",
  "oostenbroek",
  "arawak",
  "goldc",
  "khoikhoi",
  "unep",
  "lyubomirsky",
  "diener",
  "coworkers",
  "ringstrasse",
  "workingage",
  "reluctants",
  "rhizobium",
  "lima",
  "fava",
  "diasporas",
  "multigenerational",
  "garamszegi",
  "seasonal-to-interannual",
  "craugastor",
  "carbot",
  "hookhams",
  "clich",
  "searle",
  "someplace",
  "asda",
  "babbs",
  "agati",
  "textwritten",
  "widenarrow",
  "chromos",
  "manmade",
  "lexicogrammar",
  "postpubescent",
  "world wide web",
  "ehrhardt",
  "frommer",
  "fahrenalmost",
  "heit",
  "tambora",
  "contaldi",
  "homeroom",
  "wot",
  "sipadan",
  "celebes",
  "sepilok",
  "orang utan",
  "kinabalu",
  "indonesias",
  "batu",
  "pascolena",
  "florry",
  "grayer",
  "paleolithic",
  "enlene",
  "dotcom",
  "olkaria",
  "kengen",
  "nguni",
  "wormstone",
  "uea",
  "hookham",
  "cheri",
  "sorte",
  "beben",
  "benyamin",
  "laborer",
  "aitchison",
  "medi-evil",
  "chanute",
  "concreating",
  "vividity",
  "livable",
  "langone",
  "hardi",
  "alphand",
  "haussmann",
  "buttes-chaumont",
  "parc",
  "montsouris",
  "des",
  "belgrand",
  "vanne",
  "ourq"
];

export const writingGrammarScoreCalculation = (score) => {
  if (score >= 95) {
    return 9;
  } else if (score >= 90) {
    return 8.5;
  } else if (score >= 85) {
    return 8;
  } else if (score >= 80) {
    return 7.5;
  } else if (score >= 75) {
    return 7;
  } else if (score >= 70) {
    return 6.5;
  } else if (score >= 65) {
    return 6;
  } else if (score >= 60) {
    return 5.5;
  } else if (score >= 55) {
    return 5;
  } else if (score >= 50) {
    return 4.5;
  } else if (score >= 45) {
    return 4;
  } else if (score >= 40) {
    return 3.5;
  } else if (score >= 35) {
    return 3;
  } else if (score >= 30) {
    return 2.5;
  } else if (score >= 25) {
    return 2;
  } else if (score >= 20) {
    return 1.5;
  } else if (score >= 10) {
    return 1;
  } else {
    return 0;
  }
};

export const writingLexicalResourceScoreCalculation = (score) => {
  if (100 <= score >= 91) {
    return 9;
  } else if (score >= 87) {
    return 8.5;
  } else if (score >= 85) {
    return 8;
  } else if (score >= 80) {
    return 7.5;
  } else if (score >= 75) {
    return 7;
  } else if (score >= 70) {
    return 6.5;
  } else if (score >= 65) {
    return 6;
  } else if (score >= 60) {
    return 5.5;
  } else if (score >= 55) {
    return 5;
  } else if (score >= 50) {
    return 4.5;
  } else if (score >= 45) {
    return 4;
  } else if (score >= 40) {
    return 3.5;
  } else if (score >= 35) {
    return 3;
  } else if (score >= 30) {
    return 2.5;
  } else if (score >= 25) {
    return 2;
  } else if (score >= 20) {
    return 1.5;
  } else if (score >= 10) {
    return 1;
  } else {
    return 0;
  }
};

export const writingGrammarRangeScoreCalculation = (grade) => {
  if (grade === "Professional") {
    return 9;
  } else if (grade === "College graduate") {
    return 8;
  } else if (grade === "College") {
    return 7;
  } else if (grade === "10th to 12th grade") {
    return 6;
  } else if (grade === "8th & 9th grade" || grade === "8th or 9th grade") {
    return 5;
  } else if (grade === "7th grade") {
    return 4;
  } else if (grade === "6th grade") {
    return 3;
  } else if (grade === "5th grade") {
    return 2;
  } else {
    return 0;
  }
};

export const speakingScoreCalculation = (score) => {
  if (score == 0) {
    return 0.0;
  } else if (score <= 5) {
    return 0.2;
  } else if (score <= 10) {
    return 1.0;
  } else if (score <= 15) {
    return 1.7;
  } else if (score <= 20) {
    return 2.0;
  } else if (score < 25) {
    return 2.2;
  } else if (score <= 30) {
    return 2.5;
  } else if (score <= 35) {
    return 2.2;
  } else if (score <= 40) {
    return 2.5;
  } else if (score <= 45) {
    return 2.7;
  } else if (score <= 50) {
    return 3.0;
  } else if (score <= 55) {
    return 3.2;
  } else if (score <= 60) {
    return 3.5;
  } else if (score <= 75) {
    return 3.7;
  } else if (score <= 80) {
    return 4.0;
  } else if (score <= 85) {
    return 4.2;
  } else if (score <= 90) {
    return 4.5;
  } else if (score <= 99) {
    return 4.7;
  } else {
    return 5.0;
  }
};
export const doNotShowInspect = () => {
  window.document.addEventListener("contextmenu", (e) => {
    e.preventDefault();
  });

  document.onkeydown = function (e) {
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "I") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "C") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "J") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "a") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "A") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "c") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "C") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "v") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "V") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "x") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "X") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "u") {
      e.preventDefault();
      return false;
    }
  };
};

export const formatTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  return `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
};

export const processDotForUsr = (s) => {
  let dotStr = "";
  const regexForPoint = /(\d)\s*point\s*(\d)/g;
  dotStr = s.replace(regexForPoint, "$1.$2");
  return dotStr;
};

export const processDot = (s) => {
  let dotStr = "";
  const regex = /(?<=\d)\.(?=\d)/g;
  dotStr = s.replace(regex, "###");
  return dotStr;
};

export const arrCheckSalutations = [];
export const arrClosingPhrase = [];
export let startIndex = 0;
export let endIndex = 0;
export const checkClosingPhrase = (paragraph) => {
  const splitFromNewline = paragraph.toLowerCase().split("\n");
  let paragraphs = splitFromNewline.filter((element) => element.trim() !== "");

  for (let keyword of closingPhrase) {
    for (let paragraph of paragraphs) {
      if (paragraph.includes(keyword.toLowerCase())) {
        endIndex = paragraphs.findIndex((paragraph) =>
          paragraph.includes(keyword.toLowerCase())
        );
        return true;
      }
    }
  }
  return false;
};
export const salutations = [
  "Dear",
  "Respected",
  "Esteemed",
  "Honored",
  "Beloved",
  "Greetings",
  "Hello",
  "Good morning",
  "Good afternoon",
  "Good evening",
  "To whom it may concern",
  "Dear Sir/Madam",
  "Dear Hiring Manager",
  "Team",
  "Dear Team",
  "Hey",
  "Hi everyone",
  "Good day",
  "Happy",
  "Hello all",
  "Hi team",
  "Dear valued customer",
  "Hi folks"
];

export const closingPhrase = [
  "Thanks and regards",
  "Thanks",
  "Best regards",
  "Sincerely",
  "Warm regards",
  "Yours truly",
  "Cordially",
  "With appreciation",
  "Respectfully",
  "Cheers",
  "Best wishes",
  "Kind regards",
  "Warm greetings",
  "Kind greetings",
  "Kind regards",
  "Best regards",
  "Yours sincerely",
  "Warmest regards",
  "With appreciation",
  "With gratitude",
  "Thank you",
  "Cordially",
  "Cheers",
  "Many thanks",
  "All the best",
  "Take care",
  "Regards",
  "Stay well",
  "Peace",
  "Have a great day",
  "Have a wonderful day",
  "Looking forward to hearing from you",
  "Looking forward to your response",
  "Thank you for your time",
  "Thank you for considering my request",
  "Thank you for your attention to this matter",
  "Thank you for your assistance",
  "I appreciate your help with this",
  "I'm grateful for your support",
  "Many thanks for your help",
  "Thanks in advance",
  "Wishing you the best",
  "Warmest regards",
  "Warm regards",
  "Kindest regards",
  "Yours faithfully",
  "Yours truly",
  "Yours respectfully"
];
export const checkSalutation = (paragraph) => {
  const splitFromNewline = paragraph.toLowerCase().split("\n");
  let paragraphs = splitFromNewline.filter((element) => element.trim() !== "");

  for (let keyword of salutations) {
    for (let paragraph of paragraphs) {
      if (paragraph.includes(keyword.toLowerCase())) {
        startIndex = paragraphs.findIndex((paragraph) =>
          paragraph.includes(keyword.toLowerCase())
        );

        // if (startIndex >= 0) {
        //   arrCheckSalutations.push(startIndex);
        // }
        return true;
      }
    }
  }
  return false;
};

export const doNotShowInspeact = () => {
  window.document.addEventListener("contextmenu", (e) => {
    e.preventDefault();
  });

  document.onkeydown = function (e) {
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "I") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "C") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "J") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "a") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "A") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "c") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "C") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "v") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "V") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "x") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "X") {
      e.preventDefault();
      return false;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "u") {
      e.preventDefault();
      return false;
    }
  };
};