import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import { AppContext } from "AppContext";
import Footer from "components/Footer";
import CustomAlert from "components/Alert";
import advancedOETDatabase from "db";
import { useLiveQuery } from "dexie-react-hooks";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import AppLoader from "components/CustomLoader";
import PartA from "components/SpeakingSectionQType/PartA";
import PartB from "components/SpeakingSectionQType/PartB";
import PartC from "components/SpeakingSectionQType/PartC";
import { getAllQuestionsList } from "../../../redux/action/QuestionAction";
import { submitQuestionAnswer } from "../../../redux/action/AnswerAction";
import { defaultMessages } from "utilities/Messages";

const SpeakingSection = (props) => {
  // Get Speaking Test Answer of IDB
  const getAnswerSpeakingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.speakingTestAnswer.toArray(),
    []
  );

  // Get Speaking Test Question of IDB
  const getQuestionsSpeakingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.speakingTestQuestions.toArray(),
    []
  );
  const getAnswerWritingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.writingTestAnswer.toArray(),
    []
  );
  const getAnswerReadingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.readingTestAnswer.toArray(),
    []
  );
  const getAnswerListeningTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.listeningTestAnswer.toArray(),
    []
  );

  // LocalStorage variable
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Props variable
  const {} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Context state variable
  const { contextState, handleDefaultValues } = useContext(AppContext);
  const [compareLengthForC, setCompareLengthForC] = React.useState();

  // Get questions Speaking  Test Data of IDB

  const stateFormageRelodeVariable = JSON.parse(localStorage.getItem("reload"));
  const staticData = {
    message: "Listing of Test Questions.",
    speakingAllQuestions: [
      {
        PART_A: [
          {
            audio_id: 1,
            title: "Introduction & Interview",
            audio_of_text:
              "The director of gallery was grateful for the anonymous donation.",
            file_path: "20231023120008.mp3"
          },
          {
            audio_id: 2,
            title: "Introduction & Interview",
            audio_of_text:
              "Portfolio is due to the internal review office no later than Tuesday",
            file_path: "20231023115914.mp3"
          },
          {
            audio_id: 3,
            title: "Introduction & Interview",
            audio_of_text:
              "Spiritualism is defined as a system of belief or religious practice.",
            file_path: "20231023115803.mp3"
          }
        ],
        PART_B: [
          {
            audio_id: 4,
            title: "Introduction & Interview",
            audio_of_text: "hi 123",
            file_path: "20231023120008.mp3"
          },
          {
            audio_id: 5,
            title: "Introduction & Interview",
            audio_of_text:
              "Portfolio is due to the internal review office no later than Tuesday",
            file_path: "20231023115914.mp3"
          },
          {
            audio_id: 6,
            title: "Introduction & Interview",
            audio_of_text:
              "Spiritualism is defined as a system of belief or religious practice.",
            file_path: "20231023115803.mp3"
          }
        ],
        PART_C: [
          {
            audio_id: 7,
            title: "Introduction & Interview",
            audio_of_text: "hi 4 5 6",
            file_path: "20231023120008.mp3"
          },
          {
            audio_id: 8,
            title: "Introduction & Interview",
            audio_of_text:
              "Portfolio is due to the internal review office no later than Tuesday",
            file_path: "20231023115914.mp3"
          },
          {
            audio_id: 9,
            title: "Introduction & Interview",
            audio_of_text:
              "Spiritualism is defined as a system of belief or religious practice.",
            file_path: "20231023115803.mp3"
          }
        ]
      }
    ],

    redirect_url:
      "https://app.prep27.com/questionTypeList?test_id=eyJpdiI6IkwxNFJhRkliZjMwOVBqaldSMnJmYlE9PSIsInZhbHVlIjoiNGNhRzFXam9iOHNmekY3VUlEVFRFZz09IiwibWFjIjoiN2UxN2ZjYTFjMGVjMDYyZjJiZjE1ZDAwN2ZkZDMxZWMyZDNmZDFiYzk4NGUzZWQ3ZDczYTE3MGQ2MzY4NWIxNSIsInRhZyI6IiJ9&student_id=eyJpdiI6Ilk2Slc5UDJlYXd2Uy9lMXVNejNHRmc9PSIsInZhbHVlIjoiN1BHQnlMMzd5UzBWSFYwbzBQU0YxZz09IiwibWFjIjoiNzYzZDYxNWRiMDc0NGQzMzgzNjEyY2ExNmFmOThkZTI0ZDQ3ZmYxOWM1YTk3YmVkNjk0NjE0ODA4ZTAyODg5ZiIsInRhZyI6IiJ9&type=eyJpdiI6IkZoS1Q2SVRvSWlMbjNmZ0dJUjdHdXc9PSIsInZhbHVlIjoidm9VSURSMk9uN3cyell0bWp3M0xGQT09IiwibWFjIjoiZjgwZTRjNjIyYzM2ZjhkOWVmZDk4MWQzYzMwZmQ3M2E4YzQ0MmMxMWE5NTUxYzJiMmY1OGFmNmUzNDMxYjcyYSIsInRhZyI6IiJ9",
    answer_data: [],
    section_answer: [],
    last_question: null,
    statusCode: 200
  };

  const sectionTime = "Section";
  const [questionsData, setQuestionsData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [pronounc, setPronounc] = useState(0);
  const [vocab, setVocab] = useState(0);
  const [content, setContent] = useState(0);
  const [quesId, setQuesId] = useState(1);
  const [apiType, setApiType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultTestStatus, setDefaultTestStatus] = useState("");
  const [messageAlert, setMessageAlert] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [extractIndex, setExtractIndex] = useState(0);
  const [showQuationPart, setShowQuationPart] = useState("");
  const [currPart, setCurrPart] = useState("PART_A");
  const [wordCount, setWordCount] = useState(0);
  const [statusOfShowsections, setStatusOfShowsections] = React.useState("");
  const [currQuestion, setCurrQuestion] = useState("");
  const [languageSkill, setLanguageSkill] = useState(0);
  const [contentSkill, setContentSkill] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnIsDisable, setBtnIsDisable] = useState(false);
  const [isHandleNext, setIsHandleNext] = useState(false);
  const { theme } = useContext(AppContext);
  const [counter, setCounter] = useState(3);
  const [counterForProgress, setCounterForProgress] = useState(60);
  const [storeIndexDb, setStoreIndexDb] = useState(false);
  const [userAnswer, setUserAnswer] = useState("");
  const [htmlAnswer, setHtmlAnswer] = useState("");
  const [audioID, setAudioID] = useState("");
  const [title, setTitle] = useState("");
  const [audioOfText, setAudioOfText] = useState("");
  const [filePath, setFilePath] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [currentPart, setCurrentPart] = useState("PART_A");
  const [isRecording, setIsRecording] = useState(false);
  const [micOnOffStatus, setMicOnOffStatus] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [circularLoader, setCircularLoader] = useState(true);
  const [recordedBlob, setRecordedBlob] = useState({});
  const [handleSubmitModeule, setHandleSubmitModeule] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [isActiveProgress, setIsActiveProgress] = useState(false);
  const [isActiveTimer, setIsActiveTimer] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [textRecording, setTextRecording] = useState(false);
  const [getCurrTestTime, setGetCurrTestTime] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [quesData, setQuesData] = useState([]);
  const [sectionText, setSectionText] = useState("speaking");
  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [resume, setResume] = React.useState(false);

  const [nextAction, setNextAction] = useState(false);
  const [handleSubmitModule, setHandleSubmitModule] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [lastQuesApiCall, setLastQuesApiCall] = useState(false);
  const [lastSecApiCall, setLastSecApiCall] = React.useState(false);

  useEffect(() => {
    handleTestStartTime();
  }, [currentPart, currentIndex]);

  useEffect(() => {
    const speakingTestDataLength =
      getQuestionsSpeakingTestDataOfDB?.length || 0;

    if (getQuestionsSpeakingTestDataOfDB && speakingTestDataLength === 0) {
      setLoading(true);
      getQuestionsApi("speaking", userInfo?.testStatus);
    }
  }, [getQuestionsSpeakingTestDataOfDB]);

  useEffect(() => {
    // if (userInfo?.type === "P") {

    if (getQuestionsSpeakingTestDataOfDB !== undefined) {
      let speaking = getQuestionsSpeakingTestDataOfDB.map((item) => {
        // if (item?.data?.length === 2) {
        return item;
        // }
      });

      // if (speaking[0]?.data) {
      //   if (speaking[0]?.data?.length !== 0) {
      //     setQuestionsData(speaking[0]?.data);

      //     const dataLength = speaking[0]?.data?.length;
      //     setTotalQuestions(dataLength);
      //     setLoading(false);
      //   }
      // }
      if (speaking?.length !== 0) {
        if (speaking[0]) {
          setQuestionsData(speaking[0]?.speakingAllQuestions[0]);
          // setAnswersData(speaking[0]?.speakingAllAnswers);
          setLoading(false);
        }
      }
    }
    // }
  }, [getQuestionsSpeakingTestDataOfDB]);

  //for reloading
  useEffect(() => {
    const storedPosition = localStorage.getItem("position");

    if (storedPosition) {
      const { typeOfPartsIndex } = JSON.parse(storedPosition);

      if (typeOfPartsIndex?.startsWith("S-")) {
        const [part, index] = typeOfPartsIndex.split("-").slice(1);
        console.log(part);
        if (!isNaN(index)) {
          setCurrentPart(part);
          setCurrentIndex(parseInt(index, 10));
        }
      }
    } else {
      setCurrentPart("PART_A");
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (currentIndex !== undefined && currentPart !== undefined) {
      const formattedPosition = `S-${currentPart}-${currentIndex}`;
      localStorage.setItem(
        "position",
        JSON.stringify({
          typeOfPartsIndex: formattedPosition
        })
      );
    }
  });
  //end for reloading

  useEffect(() => {
    const fetchData = async () => {
      if (handleSubmitModule === true) {
        setHandleSubmitModule(false);

        if (dataArr.length >= 3) {
          const firstThreeItems = dataArr.slice(0, 3);
          createNewRequestOBJ(firstThreeItems);
          setDataArr((prevQuestions) => prevQuestions.slice(3));
        } else if (
          lastQuesApiCall === true &&
          dataArr.length > 0 &&
          dataArr.length <= 2
        ) {
          setLastQuesApiCall(false);
          createNewRequestOBJ(dataArr);
          setDataArr([]);
        }
      }
    };

    fetchData();
  }, [handleSubmitModule]);

  useEffect(() => {
    if (recordedBlob && micOnOffStatus === true && storeIndexDb) {
      setStoreIndexDb(false);
      handleQuestionAnswer(recordedBlob, "NextQuestion");
      setIsHandleNext(true);
    }
  }, [recordedBlob, micOnOffStatus, storeIndexDb]);

  const handleNextSection = () => {
    setSessionTimeOut(true);
  };

  const createNewRequestOBJ = async (reqObj, loader) => {
    let part_1 = [];
    part_1.push(...reqObj);

    let newObject = {};
    if (userInfo?.type === "M") {
      newObject = {
        // last_question: `S|${selectedIndex}`,
        speaking: {
          audio_time: currTestTime.toFixed(2),
          status: "P",
          module_time: userInfo?.session_time,
          answer_data: { part_1 },
          score_data: {
            band: 0,
            correct_answers: ""
          }
        }
      };
    } else if (userInfo?.type === "P") {
      newObject = {
        // last_question: { speaking: selectedIndex },
        speaking: {
          audio_time: currTestTime.toFixed(2),
          // status: statusEnd === true ? "C" : "P",
          speaking_module_time: userInfo?.session_time,
          answer_data: { part_1 },

          score_data: {
            band: 0,
            correct_answers: ""
          }
        }
      };
    }

    sectionEndApi(newObject, loader);
  };

  const sectionEndApi = (data, loader) => {
    if (loader === "loader" || loader === "save_and_exit") {
      setLoading(true);
    }

    // const header = {
    //   Accept: "application/json",
    //   "user-id": userInfo?.id,
    //   "api-token": userInfo?.api_token,
    //   "test-token": userInfo?.test_token
    // };
    const header = {
      Accept: "application/json",
      "user-id": 15467,
      "api-token":
        "0109f8766c82de2e2a5a7de4fb327193a8582a1a26cd7611189a8e359ed8a24d"
      // "test-token": userInfo?.test_token
    };

    const dataList = {
      user_id: "15467",
      test_id: "1",
      test_type: "M",
      part: "all",
      data: data,
      test_status:
        loader === "loader" || loader === "save_and_exit" ? "P" : "C",
      section_type: "speaking",
      resume: loader === "save_and_exit" ? true : false,
      is_continue: loader === "save_and_exit" ? false : true
    };
    dispatch(
      submitQuestionAnswer({
        header,
        dataList,
        onSuccess: (response) => {
          if (response?.data?.statusCode === 200) {
            if (loader === "loader" && userInfo?.type === "M") {
              setLoading(false);
            } else if (loader === "save_and_exit" || userInfo?.type === "P") {
              if (userInfo?.redirect_url) {
                window.location.replace(userInfo?.redirect_url);
              } else {
                window.location.replace(
                  `${window.origin + "/Student/dashboard"}`
                );
              }
            }
          }

          if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            // setMessageAlert(defaultMessages.sessionTimeOut);
            // setApiType(defaultMessages?.logoutApicall);
          }
        },
        onFailure: (error) => {
          console.log(error);
          setShowAlert(true);
          setDefaultTestStatus(599);
          // setMessageAlert(error?.message);
          // setApiType(defaultMessages?.submitAnswerAPI);
        }
      })
    );
  };
  const addResumeQueTypeDataToIDB = async (tempObj, status) => {
    if (status === "Listening") {
      const newData =
        (await getAnswerListeningTestDataOfDB.length) === 0 &&
        (await getAnswerListeningTestDataOfDB.filter(
          (item) => item?.data?.id === tempObj?.data?.id
        ));
      if (newData && newData.length === 0) {
        await advancedOETDatabase.listeningTestAnswer.add(tempObj);
      } else if (newData && newData.length !== 0) {
        await advancedOETDatabase.listeningTestAnswer.update(
          newData[0]?.id,
          tempObj
        );
      }
    } else if (status === "Reading") {
      const newData =
        (await getAnswerReadingTestDataOfDB.length) === 0 &&
        (await getAnswerReadingTestDataOfDB.filter(
          (item) => item?.data?.id === tempObj?.data?.id
        ));
      if (newData && newData.length === 0) {
        await advancedOETDatabase.readingTestAnswer.add(tempObj);
      } else if (newData && newData.length !== 0) {
        await advancedOETDatabase.readingTestAnswer.update(
          newData[0]?.id,
          tempObj
        );
      }
    } else if (status === "Writing") {
      const newData =
        (await getAnswerWritingTestDataOfDB.length) === 0 &&
        (await getAnswerWritingTestDataOfDB.filter(
          (item) => item?.data?.id === tempObj?.data?.id
        ));
      if (newData && newData.length === 0) {
        await advancedOETDatabase.writingTestAnswer.add(tempObj);
      } else if (newData && newData.length !== 0) {
        await advancedOETDatabase.writingTestAnswer.update(
          newData[0]?.id,
          tempObj
        );
      }
    } else {
      const newData =
        (await getAnswerSpeakingTestDataOfDB.length) === 0 &&
        (await getAnswerSpeakingTestDataOfDB.filter(
          (item) => item?.data?.id === tempObj?.data?.id
        ));
      if (newData && newData.length === 0) {
        await advancedOETDatabase.speakingTestQuestions.add(tempObj);
      } else if (newData && newData.length !== 0) {
        await advancedOETDatabase.speakingTestQuestions.update(
          newData[0]?.id,
          tempObj
        );
      }
    }
  };

  const getQuestionsApi = (sectionType, testStatus) => {
    const header = {
      Accept: "application/json",
      "user-id": userInfo?.id,
      "api-token": userInfo?.api_token,
      "test-token": userInfo?.test_token
    };
    let dataList = {};

    if (userInfo?.type === "M") {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        section_type: sectionType,
        part: "all",
        test_status: "P",
        internet_speed: userInfo?.internet_speed
      };
    } else if (userInfo?.type === "P") {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        section_type: sectionType,
        part: "all",
        test_status: userInfo?.testStatus,
        internet_speed: userInfo?.internet_speed
      };
    }

    dispatch(
      getAllQuestionsList({
        header,
        dataList,
        onSuccess: async (response) => {
          if (response?.data?.statusCode === 200) {
            setDefaultTestStatus(response?.data?.statusCode);
            setLoading(false);
            setBtnLoading(false);

            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...userInfo,
                redirect_url: response?.data?.redirect_url
              })
            );
            localStorage.setItem(
              "reload",
              JSON.stringify({ reloadStatus: "R" })
            );
            const data = response?.data?.data;

            const parsedData = {
              ...data,
              speaking: {
                ...data?.speaking,
                question_data: {
                  part_1: data?.speaking?.question_data?.part_1
                    ? data.speaking.question_data.part_1
                    : null,
                  part_2: data?.speaking?.question_data?.part_2
                    ? data.speaking.question_data.part_2
                    : null,
                  part_3: data?.speaking?.question_data?.part_3
                    ? data.speaking.question_data.part_3
                    : null
                },
                student_answers:
                  data?.speaking?.student_answers === null
                    ? null
                    : {
                        part_1: data?.speaking?.student_answers?.part_1
                          ? data.speaking.student_answers.part_1
                          : null,
                        part_2: data?.speaking?.student_answers?.part_2
                          ? data.speaking.student_answers.part_2
                          : null,
                        part_3: data?.speaking?.student_answers?.part_3
                          ? data.speaking.student_answers.part_3
                          : null
                      }
              }
            };

            setCompareLengthForC(
              parsedData?.speaking?.question_data?.part_3?.length
            );

            if (response?.data?.data?.speaking?.student_answers !== null) {
              const parts = ["part_1", "part_2", "part_3"];
              const [questionOfPart1, questionOfPart2, questionOfPart3] =
                parts.map(
                  (part) => parsedData?.speaking?.question_data?.[part]
                );
              const [answerOfPart1, answerOfPart2, answerOfPart3] = parts.map(
                (part) => parsedData?.speaking?.student_answers?.[part]
              );

              const studentOfPart1 =
                answerOfPart1 === null ? [] : JSON.parse(answerOfPart1);
              const studentOfPart2 =
                answerOfPart2 === null ? [] : JSON.parse(answerOfPart2);
              const studentOfPart3 =
                answerOfPart3 === null ? [] : JSON.parse(answerOfPart3);

              let part1 = [];
              let part2 = [];
              let part3 = [];

              if (questionOfPart2?.length === studentOfPart2?.length) {
                if (studentOfPart3?.length === 0) {
                  // for id
                  const lastId =
                    studentOfPart1?.length + studentOfPart2?.length;
                  currentIndex.current = lastId;
                  localStorage.setItem("CurrentIndex", currentIndex.current);
                  //end

                  part3 = questionOfPart3;
                } else {
                  // for id
                  const lastId =
                    studentOfPart1?.length +
                    studentOfPart2?.length +
                    studentOfPart3?.length;
                  currentIndex.current = lastId;
                  localStorage.setItem("CurrentIndex", currentIndex.current);
                  //end
                  part3 = questionOfPart3.slice(studentOfPart3?.length);
                }
              } else if (questionOfPart1?.length > studentOfPart1?.length) {
                // for id
                const lastId = studentOfPart1?.length;
                currentIndex.current = lastId;
                localStorage.setItem("CurrentIndex", currentIndex.current);
                //end
                part1 = questionOfPart1.slice(studentOfPart1?.length);
                part2 = questionOfPart2;
                part3 = questionOfPart3;
              } else if (questionOfPart1?.length === studentOfPart1?.length) {
                // for id
                const lastId = studentOfPart1?.length;
                currentIndex.current = lastId;
                localStorage.setItem("CurrentIndex", currentIndex.current);
                //end

                part2 = questionOfPart2;
                part3 = questionOfPart3;
              }
              const studentAnsData = {
                ...data,
                speaking: {
                  ...data?.speaking,
                  question_data: {
                    part_1: part1,
                    part_2: part2,
                    part_3: part3
                  },
                  student_answers:
                    response?.data?.data?.speaking?.student_answers === null
                      ? null
                      : {
                          part_1: data?.speaking?.student_answers?.part_1
                            ? JSON.parse(data.speaking.student_answers.part_1)
                            : null,
                          part_2: data?.speaking?.student_answers?.part_2
                            ? JSON.parse(data.speaking.student_answers.part_2)
                            : null,
                          part_3: data?.speaking?.student_answers?.part_3
                            ? JSON.parse(data.speaking.student_answers.part_3)
                            : null
                        }
                }
              };

              advancedOETDatabase.speakingTestQuestions.add(studentAnsData);
            } else {
              setResume(false);
              advancedOETDatabase.speakingTestQuestions.add(parsedData);
            }

            // if (response?.data?.data?.speaking?.student_answers) {
            //   let allResumeData = [
            //     ...JSON.parse(
            //       response?.data?.data?.speaking?.student_answers?.part_1
            //     ),
            //     ...JSON.parse(
            //       response?.data?.data?.speaking?.student_answers?.part_2
            //     ),
            //     ...JSON.parse(
            //       response?.data?.data?.speaking?.student_answers?.part_3
            //     ),
            //   ];
            //   allResumeData.forEach(async (item, index) => {
            //     item["userAnswer"] = item["user_answer"];
            //     item["answer"] = item["user_answer"];
            //     delete item["user_answer"];
            //     try {
            //       await addResumeQueTypeDataToIDB(item);
            //       if (index === 0) {
            //         // setUserAnswer1({
            //         //   score: item?.score,
            //         //   userAnswer: item?.userAnswer,
            //         //   task_achievement: item?.task_achievement,
            //         // });
            //       } else if (index === 1) {
            //         // setUserAnswer2({
            //         //   score: item?.score,
            //         //   userAnswer: item?.userAnswer,
            //         //   task_achievement: item?.task_achievement,
            //         // });
            //       } else if (index === 2) {
            //         // setUserAnswer2({
            //         //   score: item?.score,
            //         //   userAnswer: item?.userAnswer,
            //         //   task_achievement: item?.task_achievement,
            //         // });
            //       }
            //     } catch (error) {}
            //   });
            // }
            if (
              userInfo?.testStatus === "P" &&
              response?.data?.last_question &&
              userInfo?.type === "M"
            ) {
              if (response?.data?.last_question?.split("|")[0] === "W") {
                setCurrPart(
                  Number(response?.data?.last_question?.split("|")[1])
                );
              }
              setStatusOfShowsections(
                response?.data?.last_question?.split("|")[0]
              );

              if (
                response?.data?.section_answer &&
                response?.data?.section_answer?.listening
              ) {
                let allResumeData = [];
                let l_R_part_1 = JSON.parse(
                  response?.data?.section_answer?.listening?.part_1
                );
                let l_R_part_2 = JSON.parse(
                  response?.data?.section_answer?.listening?.part_2
                );
                let l_R_part_3 = JSON.parse(
                  response?.data?.section_answer?.listening?.part_3
                );
                let l_R_part_4 = JSON.parse(
                  response?.data?.section_answer?.listening?.part_4
                );
                l_R_part_1.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 1"
                  });
                });
                l_R_part_2.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 2"
                  });
                });
                l_R_part_3.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 3"
                  });
                });
                l_R_part_4.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 4"
                  });
                });

                allResumeData.forEach(async (item) => {
                  const correctAnswerParts = item?.correct_answer?.split("&");
                  item.correct_answer = correctAnswerParts[0];
                  if (correctAnswerParts.length === 2) {
                    item.subId = correctAnswerParts[1];
                  }
                  try {
                    await addResumeQueTypeDataToIDB(item, "Listening");
                  } catch (error) {}
                });
              }
              if (
                response?.data?.section_answer &&
                response?.data?.section_answer?.reading
              ) {
                let allResumeData = [];
                let r_R_part_1 = JSON.parse(
                  response?.data?.section_answer?.reading?.part_1
                );
                let r_R_part_2 = JSON.parse(
                  response?.data?.section_answer?.reading?.part_2
                );
                let r_R_part_3 = JSON.parse(
                  response?.data?.section_answer?.reading?.part_3
                );

                r_R_part_1.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 1"
                  });
                });
                r_R_part_2.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 2"
                  });
                });
                r_R_part_3.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 3"
                  });
                });

                allResumeData.forEach(async (item) => {
                  const correctAnswerParts = item?.correct_answer?.split("&");
                  item.correct_answer = correctAnswerParts[0];

                  if (correctAnswerParts.length === 2) {
                    item.subId = correctAnswerParts[1];
                  }

                  try {
                    await addResumeQueTypeDataToIDB(item, "Reading");
                  } catch (error) {}
                });
              }
              if (
                response?.data?.section_answer &&
                response?.data?.section_answer?.writing
              ) {
                let allResumeData = [];
                let w_R_part_1 = JSON.parse(
                  response?.data?.section_answer?.writing?.part_1
                );
                let w_R_part_2 = JSON.parse(
                  response?.data?.section_answer?.writing?.part_2
                );

                w_R_part_1.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 1"
                  });
                });
                w_R_part_2.forEach((element) => {
                  allResumeData.push({
                    ...element,
                    showQuationPart: "Part 2"
                  });
                });

                allResumeData.forEach(async (item) => {
                  const correctAnswerParts = item?.correct_answer?.split("&");
                  item.correct_answer = correctAnswerParts[0];

                  if (correctAnswerParts.length === 2) {
                    item.subId = correctAnswerParts[1];
                  }

                  try {
                    await addResumeQueTypeDataToIDB(item, "Writing");
                  } catch (error) {}
                });
              }
            } else if (userInfo?.type === "P") {
              let lastQuestion = JSON.parse(response?.data?.last_question);
              localStorage.setItem("position", JSON.stringify(lastQuestion));
              lastQuestion?.speaking && userInfo?.testStatus === "P"
                ? setCurrPart(Number(lastQuestion.speaking))
                : setCurrPart(1);
            }
          } else if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            setMessageAlert(defaultMessages?.sessionTimeOut);
            setApiType(defaultMessages?.logoutApicall);
            setTimeout(() => {
              window.location.replace(
                `${window.origin + "/Student/dashboard"}`
              );
            }, 10000);
          }
        },
        onFailure: (error) => {
          setShowAlert(true);
          setDefaultTestStatus(599);
          setMessageAlert(error?.message);
          setApiType(`${defaultMessages?.getQuestionAPI}|${sectionType}|S`);
        }
      })
    );
  };
  // Manage starting test time
  const handleTestStartTime = () => {
    if (
      userInfo &&
      userInfo?.testStatus === "P" &&
      userInfo?.session_time?.charAt(0) === "S"
    ) {
      let getTestTime = userInfo && userInfo?.session_time.split("_");
      let splitTestTime = getTestTime && getTestTime[1].split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    } else {
      const getTimeKey = () => {
        if (currentPart === "PART_A") return "partA_Ext1";
        if (currentPart === "PART_B") return "partB_Ext1";
        if (currentPart === "PART_C") return "partC_Ext1";
      };
      if (currentPart && currentIndex !== undefined) {
        const getTestTime = contextState.speakingSectionTime[getTimeKey()];
        const splitTestTime = getTestTime && getTestTime.split(":");

        setCurrTestTime({
          hours: Math.floor(parseInt(splitTestTime[0])),
          minutes: Math.floor(parseInt(splitTestTime[1])),
          seconds: Math.floor(parseInt(splitTestTime[2]))
        });
        setLoading(false);
      }
    }
  };

  const handleNext = (status) => {
    setNextAction(true);
    // setBtnLoading(true);
    setBtnIsDisable(true);

    if (isListening === true) {
      stopRecording();
    } else if (isListening === false) {
      handleQuestionAnswer(recordedBlob, "NextQuestion");
      setIsHandleNext(true);
    }
  };

  const nextBtnPopup = async () => {
    setModalShow(false);
    setConfirmModalShow(false);
    setSessionTimeOut(false);
    setLoading(true);

    if (currentPart === "PART_A") {
      setTimeout(() => {
        setCurrentPart("PART_B");
        setCurrentIndex(0);
        setQuesId(quesId + 1);
        setLoading(false);
        setShowAudio(false);
        setIsActiveTimer(true);
      }, 1500);
    } else if (currentPart === "PART_B") {
      setTimeout(() => {
        setCurrentPart("PART_C");
        setCurrentIndex(0);
        setQuesId(quesId + 1);
        setLoading(false);
        setShowAudio(false);
        setIsActiveTimer(true);
      }, 1500);
    } else if (currentPart === "PART_C") {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const handleSessionTimeOut = () => {
    nextBtnPopup();
  };

  const getAnswerAndMarksData = (
    audio_id,
    title,
    recordedBlobData,
    audio_of_text,
    file_path,
    blobURL,
    transcription
  ) => {
    setAudioID(audio_id);
    setTitle(title);
    setRecordedBlob(recordedBlobData);
    setAudioOfText(audio_of_text);
    setFilePath(file_path);
    setBlobURL(blobURL);
    setUserAnswer(transcription);
  };

  const handleQuestionAnswer = async (blob, status) => {
    let audioFileName = new File(
      [blob],
      `${userInfo?.id}_${userInfo?.testID}_${userInfo?.type}_${audioID}_recording.ogg`
    );

    let tempObj = {
      question_type: showQuationPart,
      id: quesId,
      title: title,
      audioOfText: audioOfText,
      filePath: filePath,
      blobURL: blobURL,
      wordCount:
        userAnswer?.trim() === ""
          ? 0
          : userAnswer?.trim()?.split(/\s+/)?.length,
      userAnswer: userAnswer,
      audioFile: audioFileName?.name,
      audioBlob: blob
    };
    const newData = await getAnswerSpeakingTestDataOfDB.filter(
      (item) => item?.id === tempObj?.id
    );

    if (newData.length === 0) {
      await advancedOETDatabase.speakingTestAnswer.add(tempObj);
      setDataArr((prevDataArray) => [...prevDataArray, tempObj]);
    } else {
      await advancedOETDatabase.speakingTestAnswer.update(
        newData[0]?.id,
        tempObj
      );
    }

    const currentPartData = questionsData[currentPart];

    if (currentIndex === currentPartData.length - 1) {
      setIsActiveTimer(false);
      setModalShow(true);
    } else {
      setBtnLoading(false);
      setIsActiveTimer(true);
      setCounterForProgress(60);
      setCurrentIndex(currentIndex + 1);
      setQuesId(quesId + 1);
    }
    setTextRecording(false);
    setIsActiveProgress(false);
    setCounter(3);
    setShowAudio(false);
    setAudioPlaying(false);
    setShowQuationPart("");
    setUserAnswer("");
    setAudioID("");
    setTitle("");
    setAudioOfText("");
    setFilePath("");
    setBlobURL("");

    setBtnIsDisable(false);
    setHandleSubmitModule(true);
  };

  const startRecording = () => {
    setIsRecording(true);
    setIsListening(true);
    setMicOnOffStatus(false);
  };

  const stopRecording = () => {
    setIsRecording(false);
    setIsListening(false);
    // setMicOnOffStatus(false);
  };

  return (
    <>
      <div className="test-viewer-holder">
        <div className="test-viewer-main-container container-centre">
          <div className="test-viewer-container">
            {/* <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  You still have time remaining in this <strong>section</strong>
                  .
                  <br />
                  If you choose to <strong>finish this section</strong>, you
                  will not be able to return to this section.
                  <br />
                  Are you sure you would like to{" "}
                  <strong>finish this section</strong>?
                </div>
              }
              modalShow={modalShow}
              setModalShow={setModalShow}
              confirmAction={confirmAction}
              cancelAction={cancelAction}
              confirmText="Yes, I would like to finish this section"
              cancelText="No, I would like to return to this section"
              leftIcon={faCheck}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faClose}
            />
            <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  If you select <b>Finish Section</b>, your answers <br />
                  will be submitted and you will not be able to <br />
                  return to this section.
                </div>
              }
              modalShow={confirmModalShow}
              setModalShow={setConfirmModalShow}
              cancelAction={nextBtnPopup}
              confirmAction={cancelAction}
              confirmText="Cancel"
              cancelText="Finish Section"
              leftIcon={faClose}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faCheck}
            /> */}
            <CustomAlert
              title="Section Time Expired"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  The time for the previous section of the test has <br />
                  expired. Please continue with the new section you're <br />
                  being directed to.
                </div>
              }
              modalShow={sessionTimeOut}
              setModalShow={setSessionTimeOut}
              cancelAction={handleSessionTimeOut}
              cancelText="Close"
              rightBtn={true}
              rightIcon={faCheck}
            />
            <CustomAlert
              title="Section Time Expired"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  Your {currentPart} is done.
                  <br />
                  Now, You are moving into another Part.
                </div>
              }
              modalShow={modalShow}
              setModalShow={setModalShow}
              cancelAction={handleSessionTimeOut}
              cancelText="Close"
              rightBtn={true}
              rightIcon={faCheck}
            />
            <AppLoader modalShow={loading} setModalShow={setLoading} />

            <Header
              defaultTime={
                (currTestTime?.hours < 9
                  ? 0 + "" + currTestTime?.hours
                  : currTestTime?.hours) +
                ":" +
                (currTestTime?.minutes < 9
                  ? 0 + "" + currTestTime?.minutes
                  : currTestTime?.minutes) +
                ":" +
                (currTestTime?.seconds < 9
                  ? 0 + "" + currTestTime?.seconds
                  : currTestTime?.seconds)
              }
              currTestTime={currTestTime}
              currTesthours={currTestTime?.hours}
              currTestMinutes={currTestTime?.minutes}
              currTestSeconds={currTestTime?.seconds}
              setGetCurrTestTime={setGetCurrTestTime}
              sectionTime={sectionTime}
              handleNextSection={handleNextSection}
              sectionText={sectionText}
              lastSecApiCall={lastSecApiCall}
            />

            <div className="row content ng-scope">
              <div
                style={{ display: "inherit", width: "100%" }}
                className="ng-scope ng-isolate-scope"
              >
                <div className="white-background question-navigation-holder ng-scope">
                  <section>
                    <nav className="question-nav ng-star-inserted">
                      <div className="nav-button-holder">
                        <section className="ng-star-inserted">
                          <ul className="main-nav-list">
                            {[...Array(1)].map((_, index) => (
                              <li key={index} className="ng-star-inserted">
                                <section>
                                  <button
                                    // onClick={() => handleSideBarClick(index)}
                                    style={{
                                      backgroundColor: `${theme.subHeader}`,
                                      color: `${theme.textColor}`,
                                      borderColor: `${theme.subHeader}`
                                    }}
                                    className={`btn nav-button theme-bordered item-selector-1intro nav-active`}
                                    aria-current="true"
                                  >
                                    <div>
                                      <span className="ng-star-inserted">
                                        {index + 1}{" "}
                                      </span>
                                    </div>
                                  </button>
                                </section>
                              </li>
                            ))}
                          </ul>
                        </section>
                      </div>
                    </nav>
                  </section>
                </div>
                <div
                  className="content-container white-background content-padding"
                  style={{ height: "100%" }}
                >
                  <div className="content-holder">
                    {currentPart === "PART_B" ? (
                      <PartB
                        micOnOffStatus={micOnOffStatus}
                        setMicOnOffStatus={setMicOnOffStatus}
                        isListening={isListening}
                        setIsListening={setIsListening}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        showAudio={showAudio}
                        setShowAudio={setShowAudio}
                        counter={counter}
                        setCounter={setCounter}
                        isActiveProgress={isActiveProgress}
                        setIsActiveProgress={setIsActiveProgress}
                        isActiveTimer={isActiveTimer}
                        setIsActiveTimer={setIsActiveTimer}
                        audioPlaying={audioPlaying}
                        setAudioPlaying={setAudioPlaying}
                        textRecording={textRecording}
                        setTextRecording={setTextRecording}
                        theme={theme}
                        isHandleNext={isHandleNext}
                        setIsHandleNext={setIsHandleNext}
                        counterForProgress={counterForProgress}
                        setCounterForProgress={setCounterForProgress}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        questionsData={questionsData?.PART_B}
                        currentIndex={currentIndex}
                        handleNext={handleNext}
                        setIsRecording={setIsRecording}
                        isRecording={isRecording}
                        storeIndexDb={storeIndexDb}
                        setStoreIndexDb={setStoreIndexDb}
                      />
                    ) : currentPart === "PART_C" ? (
                      <PartC
                        micOnOffStatus={micOnOffStatus}
                        setMicOnOffStatus={setMicOnOffStatus}
                        isListening={isListening}
                        setIsListening={setIsListening}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        showAudio={showAudio}
                        setShowAudio={setShowAudio}
                        counter={counter}
                        setCounter={setCounter}
                        isActiveProgress={isActiveProgress}
                        setIsActiveProgress={setIsActiveProgress}
                        isActiveTimer={isActiveTimer}
                        setIsActiveTimer={setIsActiveTimer}
                        audioPlaying={audioPlaying}
                        setAudioPlaying={setAudioPlaying}
                        textRecording={textRecording}
                        setTextRecording={setTextRecording}
                        theme={theme}
                        isHandleNext={isHandleNext}
                        setIsHandleNext={setIsHandleNext}
                        counterForProgress={counterForProgress}
                        setCounterForProgress={setCounterForProgress}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        questionsData={questionsData?.PART_C}
                        currentIndex={currentIndex}
                        handleNext={handleNext}
                        setIsRecording={setIsRecording}
                        isRecording={isRecording}
                        storeIndexDb={storeIndexDb}
                        setStoreIndexDb={setStoreIndexDb}
                      />
                    ) : (
                      <PartA
                        micOnOffStatus={micOnOffStatus}
                        setMicOnOffStatus={setMicOnOffStatus}
                        isListening={isListening}
                        setIsListening={setIsListening}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        showAudio={showAudio}
                        setShowAudio={setShowAudio}
                        counter={counter}
                        setCounter={setCounter}
                        isActiveProgress={isActiveProgress}
                        setIsActiveProgress={setIsActiveProgress}
                        isActiveTimer={isActiveTimer}
                        setIsActiveTimer={setIsActiveTimer}
                        audioPlaying={audioPlaying}
                        setAudioPlaying={setAudioPlaying}
                        textRecording={textRecording}
                        setTextRecording={setTextRecording}
                        theme={theme}
                        isHandleNext={isHandleNext}
                        setIsHandleNext={setIsHandleNext}
                        counterForProgress={counterForProgress}
                        setCounterForProgress={setCounterForProgress}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        questionsData={questionsData?.PART_A}
                        currentIndex={currentIndex}
                        handleNext={handleNext}
                        setIsRecording={setIsRecording}
                        isRecording={isRecording}
                        storeIndexDb={storeIndexDb}
                        setStoreIndexDb={setStoreIndexDb}
                      />
                    )}
                  </div>
                </div>
                <div className="content-loading-error-tip ng-isolate-scope"></div>
              </div>
            </div>

            <Footer
              handleNext={handleNext}
              sectionTime={sectionTime}
              maxPage={2}
              currentPage={1}
              btnLoading={btnLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SpeakingSection;
