import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import AudioPlayerWithVisualizer from "components/AudioPlayerWithVisualizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import reactStringReplace from "react-string-replace";
import { cleanQuestionData } from "utilities/CommonFunc";

let score_R = 0;

const PartC = (props) => {
  const {
    currentPart,
    questionsData,
    currentIndex,
    setCurrentIndex,
    getAnswerAndMarksData,
    setIsPdfOpen,
    isPdfOpen,
    currentPage,
    answersData
  } = props;

  let currQuestionData =
    questionsData && questionsData[currentIndex]?.questions;
  let currAnswersData = answersData.length !== 0 ? answersData : [];
  let currQuestionType =
    questionsData && questionsData[currentIndex]?.question_type;
  const [questions, setQuestions] = useState([]);
  const [userAnswer, setUserAnswer] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [quesNum, setQuesNum] = useState();
  const localStorageKey = `R_Data`;

  useEffect(() => {
    const parseQuestions = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(currQuestionData, "text/html");

      const questionBlocks = doc.querySelectorAll(".question-block");
      const parsedQuestions = Array.from(questionBlocks)?.map((block) => {
        const questionId = block.querySelector(".section-description").id;
        const questionText = block.querySelector(
          ".section-description"
        ).innerText;

        const options = Array.from(
          block.querySelectorAll(".direction-option")
        )?.map((option) => {
          const optionText = option.querySelector(".option-text").innerText;
          const optionDescription = option.querySelector(
            ".option-description"
          ).innerText;
          return { text: optionText, description: optionDescription };
        });

        return {
          id: questionId,
          question: questionText,
          options
        };
      });

      setQuestions(parsedQuestions);
    };

    parseQuestions();
  }, [currQuestionData]);

  useEffect(() => {
    const savedOptions =
      JSON.parse(localStorage.getItem(localStorageKey)) || {};
    setSelectedOptions(savedOptions);
  }, []);

  useEffect(() => {
    if (selectedOptions && quesNum) {
      rMarksCalculation(userAnswer, quesNum);
    }
  }, [selectedOptions, quesNum, userAnswer]);

  useEffect(() => {
    setIsPdfOpen(true);
  }, [currentPage]);

  const handleChange = (e, questionId) => {
    const updatedOptions = {
      ...selectedOptions,
      [questionId]: e.description
    };

    setUserAnswer(e.description);
    setSelectedOptions(updatedOptions);
    setQuesNum(Number(questionId));
    localStorage.setItem(localStorageKey, JSON.stringify(updatedOptions));
  };

  const rMarksCalculation = (userInput, quesNum) => {
    currAnswersData?.forEach((element, i) => {
      if (quesNum === i + 1) {
        if (
          element?.toLowerCase()?.split("|")?.includes(userInput.toLowerCase())
        ) {
          score_R = 1;
        } else {
          score_R = 0;
        }
      }
    });

    // Calling function And Get Data in ListeningSection Main File
    getAnswerAndMarksData(
      quesNum,
      userInput,
      // htmlAnswerArray,
      score_R,
      currentPart,
      // currQuestionData,
      currQuestionType
    );

    // htmlAnswerArray = [];
  };

  const handlePdfOpen = () => {
    setIsPdfOpen(true);
  };

  return (
    <div className="reading-partC-wrapper">
      {currentPage === 8 ? (
        <div>
          <div
            className="part-intro"
            style={{ padding: "2px 10px 2px 10px", fontSize: "18px" }}
          >
            <p className="section-title">
              <b>Text 2: Questions 35-42</b>
            </p>
          </div>

          {questions?.map((question, index) => (
            <div key={index} className="question-block">
              <p className="section-description">
                {question?.id} {question?.question}
              </p>
              <div className="radio-group">
                <p
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <span
                    kerning="0"
                    letterspacing="0"
                    style={{
                      fontFamily: "Arial",
                      fontSize: "16px",
                      color: "#000000",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px"
                    }}
                  >
                    <span
                      className="icon-container"
                      style={{ cursor: "pointer" }}
                      onClick={handlePdfOpen}
                    >
                      <FontAwesomeIcon icon={faFilePdf} aria-hidden="true" />
                    </span>
                    Reading Part C Texts
                  </span>
                </p>
              </div>
              <div className="radio-group">
                {question?.options?.map((option, key) => (
                  <div key={key} className="direction-option">
                    <div
                      className={`option-container ${
                        selectedOptions[question?.id] === option.description
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleChange(option, question?.id)}
                    >
                      <div
                        className={`option-container2 ${
                          selectedOptions[question?.id] === option.description
                            ? "selected"
                            : ""
                        }`}
                      >
                        {option.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="last-container">
            <p className="section-description">END OF READING TEST</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="part-intro">
            <h3>Part C</h3>
            <p className="section-title">
              In this part of the test, there are two texts about different
              aspects of healthcare. For <b>questions 27-42</b>, choose the
              answer (<b>A</b>, <b>B</b>, <b>C</b> or <b>D</b>) which you think
              fits best according to the text.
            </p>
          </div>
          <div
            className="part-intro"
            style={{
              margin: "40px 0px",
              padding: "2px 10px 2px 10px",
              fontSize: "18px"
            }}
          >
            <p className="section-title">
              <b>Text 1: Questions 27-34</b>
            </p>
          </div>

          {questions?.map((question, index) => (
            <div key={index} className="question-block">
              <p className="section-description">
                {question?.id} {question?.question}
              </p>
              <div className="radio-group">
                <p
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <span
                    kerning="0"
                    letterspacing="0"
                    style={{
                      fontFamily: "Arial",
                      fontSize: "16px",
                      color: "#000000",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px"
                    }}
                  >
                    <span
                      className="icon-container"
                      style={{ cursor: "pointer" }}
                      onClick={handlePdfOpen}
                    >
                      <FontAwesomeIcon icon={faFilePdf} aria-hidden="true" />
                    </span>
                    Reading Part C Texts
                  </span>
                </p>
              </div>
              <div className="radio-group">
                {question?.options?.map((option, key) => (
                  <div key={key} className="direction-option">
                    <div
                      className={`option-container ${
                        selectedOptions[question?.id] === option.description
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleChange(option, question?.id)}
                    >
                      <div
                        className={`option-container2 ${
                          selectedOptions[question?.id] === option.description
                            ? "selected"
                            : ""
                        }`}
                      >
                        {option.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PartC;
