import React, { useContext } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "AppContext";
import { Spinner } from "react-bootstrap";

const Footer = (props) => {
  const {
    currentPage,
    setCurrentPage,
    sectionTime,
    handleNext,
    maxPage,
    btnLoading
  } = props;

  const { theme } = useContext(AppContext);

  const buttonStyle = {
    backgroundColor: "#04625d"
  };

  const disabledStyle = {
    opacity: 0.65
  };

  return (
    <>
      <footer
        role="navigation"
        className="row dark-grey no-animate ng-scope"
        style={{ backgroundColor: `${theme.backgroundColor}` }}
      >
        <div className="ng-scope ng-isolate-scope">
          <div className="col-xs-9 text-left left-button-bar"></div>
          <div className="col-xs-13 text-right">
            <div
              className="ng-isolate-scope"
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-end"
              }}
            >
              <button
                style={{
                  color: `${theme.textColor}`,
                  backgroundColor: `${theme.subHeader}`,
                  ...(currentPage === 1 ? disabledStyle : buttonStyle)
                }}
                disabled={currentPage === 1}
                className="btn btn-secondary btn-lg no-animate btn-with-icon ng-scope ng-isolate-scope"
                onClick={() => handleNext("Previous")}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="fa fa-chevron-left"
                />
                <span>
                  <span className="translated-text">Back</span>
                </span>
              </button>
              <button
                style={{
                  color: `${theme.textColor}`,
                  backgroundColor: `${theme.subHeader}`,
                  ...(currentPage === maxPage || btnLoading
                    ? disabledStyle
                    : buttonStyle)
                }}
                disabled={currentPage === maxPage || btnLoading}
                className="btn btn-secondary btn-lg no-animate btn-with-icon ng-scope ng-isolate-scope"
                onClick={() => handleNext("Next")}
              >
                <span className="translated-text">
                  {/* {btnLoading && <Spinner animation="border" />} */}
                  Next
                </span>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="fa fa-chevron-right"
                />
              </button>
              {sectionTime === "Introduction" && (
                <button
                  style={{
                    color: `${theme.textColor}`,
                    backgroundColor: `${theme.subHeader}`
                  }}
                  className="btn btn-secondary btn-lg no-animate btn-with-icon ng-scope ng-isolate-scope"
                  onClick={() => handleNext()}
                >
                  <span>
                    <span className="translated-text">Start the Test</span>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fa fa-chevron-right"
                    />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
