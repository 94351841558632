import React, { useContext, useState } from "react";
import "./style.scss";
import AudioPlayerPopup from "components/AudioPlayerPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import VideoPlayerPopup from "components/VideoPlayerPopup";
import { AppContext } from "AppContext";

const SystemCheck = (props) => {
  const { theme, setTheme } = useContext(AppContext);

  const [isTabAudioActive, setIsTabAudioActive] = useState(true);
  const [isTabVideoActive, setIsTabVideoActive] = useState(false);
  // const [isTabAudioActive, setIsTabAudioActive] = useState(false);
  // const [isTabAudioActive, setIsTabAudioActive] = useState(false);
  const [activeButton, setActiveButton] = useState("audio");

  const { openModal, modalOpen, setModalOpen, setConfirmDetailsOpen } = props;

  const handleClose = () => {
    setModalOpen(false);
    setConfirmDetailsOpen(false);
  };

  const handleAudioShow = () => {
    setActiveButton("audio");
    setIsTabVideoActive(false);
    setIsTabAudioActive(true);
  };
  const handleVideoShow = () => {
    setActiveButton("video");
    setIsTabAudioActive(false);
    setIsTabVideoActive(true);
  };
  const handleFLVVideoShow = () => {
    setActiveButton("flvVideo");
    setIsTabVideoActive(false);
    setIsTabAudioActive(false);
  };
  const handleInternetShow = () => {
    setActiveButton("internet");
    setIsTabVideoActive(false);
    setIsTabAudioActive(false);
  };
  return (
    <>
      {openModal && (
        <div className="screen2-container">
          <div className="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing" />
          <div
            className="cdk-global-overlay-wrapper"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex"
            }}
          >
            <div
              className="cdk-overlay-pane system-check no-padding-dialog modal-dialog"
            >
              <div
                tabIndex={0}
                className="cdk-visually-hidden cdk-focus-trap-anchor"
              />
              <mat-dialog-container
                tabIndex={-1}
                className="mat-dialog-container cdk-dialog-container ng-tns-c79-5 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
                style={{ transform: "none" }}
              >
                <ng-component className="ng-star-inserted">
                  <pas-dialog-header className="pas-dialog-header">
                    <div className="mat-dialog-title modal-header">
                      <div className="modal-title-wrapper">
                        <h1 className="modal-title">
                          <pas-translated-text>
                            <span className="translated-text">
                              System Check
                            </span>
                          </pas-translated-text>
                        </h1>
                        <div className="modal-title-additional-info" />
                      </div>
                    </div>
                  </pas-dialog-header>
                  <mat-dialog-content className="mat-dialog-content modal-body">
                    <div className="system-check-content">
                      <div className="tab-button-holder">
                        <button
                          className={`tab-button ${
                            activeButton === "audio" ? "active " : ""
                          } ng-star-inserted`}
                          style={{ width: "25%" }}
                          onClick={handleAudioShow}
                        >
                          Audio
                        </button>
                        <button
                          className={`tab-button ${
                            activeButton === "video" ? "active " : ""
                          }ng-star-inserted`}
                          style={{ width: "25%" }}
                          onClick={handleVideoShow}
                        >
                          Mp4 Video
                        </button>
                        <button
                          className={`tab-button ${
                            activeButton === "flvVideo" ? "active " : ""
                          }ng-star-inserted`}
                          style={{ width: "25%" }}
                          onClick={handleFLVVideoShow}
                        >
                          FLV Video
                        </button>
                        <button
                          className={`tab-button ${
                            activeButton === "internet" ? "active " : ""
                          }ng-star-inserted`}
                          style={{ width: "25%" }}
                          onClick={handleInternetShow}
                        >
                          Internet
                        </button>
                      </div>
                      <div className="tab-content">
                        {isTabAudioActive && (
                          <div className="ng-star-inserted">
                            <p className="text-above-audio">
                              To check the audio, select the <b>Play</b>
                              button to hear a short audio clip.
                            </p>
                            <span className="mejs__offscreen">
                              Audio Player
                            </span>
                            <div className="mejs__container-d mejs__container-keyboard-inactive center-element audioCheckOnly theme-special mejs__audio">
                              <AudioPlayerPopup />
                            </div>
                            <p className="text-above-audio">
                              If you couldn’t hear the audio clip at all, or if
                              the volume level was not satisfactory, please
                              adjust your audio settings and try again.
                            </p>
                          </div>
                        )}
                        {isTabVideoActive && (
                          <div className="ng-star-inserted">
                            <div className="mejs__container-d mejs__container-keyboard-inactive center-element audioCheckOnly theme-special mejs__audio">
                              <VideoPlayerPopup />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </mat-dialog-content>
                  <center style={{ padding: "20px 0" }}>
                    <mat-dialog-actions className="mat-dialog-actions">
                      <pas-flat-button className="pas-flat">
                        <button
                          className="mat-focus-indicator mat-tooltip-trigger mat-flat-button mat-button-base pas-flat-button pas-button-lg mat-primary"
                          type="button"
                          onClick={handleClose}
                        >
                          <span className="mat-button-wrapper">
                            <FontAwesomeIcon icon={faClose} />
                            <pas-translated-text className="ng-star-inserted">
                              <span className="translated-text">Close</span>
                            </pas-translated-text>
                          </span>
                          <span className="mat-ripple mat-button-ripple" />
                          <span className="mat-button-focus-overlay" />
                        </button>
                      </pas-flat-button>
                    </mat-dialog-actions>
                  </center>
                </ng-component>
              </mat-dialog-container>
              <div
                tabIndex={0}
                className="cdk-visually-hidden cdk-focus-trap-anchor"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SystemCheck;
