export const Constants = {
  apiBaseUrl: "https://app.prep27.com/api/v1", // It is for the Production
  // apiBaseUrl: "http://127.0.0.1:8000/api/v1", // it is for the Local
  // apiBaseUrl: "http://192.168.1.18:8000/api/v1",
  apiBaseAudio: "https://app.prep27.com/audio/",
  ApiAction: {
    logout: "/logout",
    questions: "/oet_questions",
    questionsForL: "/oet-mock-test",
    submitAnswerL: "/submit-answers",
    questionsForW: "/mcq-letter-question",
    assignTests: "/assign_tests",
    submitAnswer: "/oet_submit_answer",
    getCustomTest: "/getCustomTest",
    getCustomTestQuestion: "/getCustomTestQuestion",
    submitModuleScore: "/submit_module_score",
    customAiSubmit: "/custom_ai_submit",
    feedBack: "/feedback",
    upcomingQuestions: "/upcoming_questions",
    submitSpeakingModuleScore: "/submit_speaking_module_score",
    checkInstituteActiveStudent: "/checkInstituteActiveStudent",
    reportQuestion: "/reportQuestion",
    repeatedQuestion: "/repeatedQuestion",
    submitQuestionWiseTestScore: "/submitQuestionWiseTestScore"
  }
};
