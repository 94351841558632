import React, { useContext, useEffect, useState } from "react";
import "./style.scss"; // For the SCSS styling
import { AppContext } from "AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { faClockFour } from "@fortawesome/free-regular-svg-icons";
import { formatTime } from "utilities/CommonFunc";
import { useNavigate } from "react-router-dom";
import TermsDetails from "views/TermsDetails";
import CustomAlert from "components/Alert";
import { faClock as faRegClock } from "@fortawesome/free-regular-svg-icons";

const ConfirmDetails = (props) => {
  let navigate = useNavigate();

  const { openModal, modalOpen, setModalOpen, setSystemCheckOpen } = props;
  const { theme, setTheme } = useContext(AppContext);
  const [timeLeft, setTimeLeft] = useState(120); // Timer starts at 120 seconds
  //   const [modalShow, setModalShow] = useState(false);
  const [isTermsDetailsOpen, setIsTermsDetailsOpen] = useState(false);
  const [isSystemTimeAlertOpen, setIsSystemTimeAlertOpen] = useState(false);
  const [isSystemCancelAlertOpen, setIsSystemCancelAlertOpen] = useState(false);

  useEffect(() => {
    if (openModal && !isTermsDetailsOpen) {
      setTimeLeft(120);

      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setIsSystemTimeAlertOpen(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [openModal, isTermsDetailsOpen]);

  const handleConfirm = () => {
    setTimeLeft(0);
    setIsTermsDetailsOpen(true);
  };
  const handleClose = () => {
    setIsSystemCancelAlertOpen(true);
  };
  const handleOk = () => {
    setIsSystemTimeAlertOpen(false);
    setModalOpen(false);
    setSystemCheckOpen(false);
    setIsSystemCancelAlertOpen(false);
  };
  const handleCancelBtn = () => {
    setIsSystemCancelAlertOpen(false);
  };

  if (!openModal) return null;

  return (
    <>
      <div className="confirm-details-overlay">
        <CustomAlert
          title="Time Expired"
          message={
            <div style={{ lineHeight: "1.6" }}>
              Your time has expired for confirming your details.
            </div>
          }
          icon={faRegClock}
          modalShow={isSystemTimeAlertOpen}
          setModalShow={setIsSystemTimeAlertOpen}
          confirmAction={handleOk}
          confirmText="OK"
          leftIcon={faCheck}
          leftBtn={true}
        />

        <CustomAlert
          title="Confirm Incorrect Details"
          message={
            <div style={{ lineHeight: "1.6" }}>
              Are you sure the details are incorrect?
            </div>
          }
          icon={faRegClock}
          modalShow={isSystemCancelAlertOpen}
          setModalShow={setIsSystemCancelAlertOpen}
          confirmAction={handleOk}
          confirmText="Yes, my details are incorrect"
          cancelText="No, my details are correct"
          cancelAction={handleCancelBtn}
          leftIcon={faCheck}
          leftBtn={true}
          rightIcon={faClose}
          rightBtn={true}
        />

        <TermsDetails
          modalOpen={isTermsDetailsOpen}
          setModalOpen={setIsTermsDetailsOpen}
          handleCloseModal={setModalOpen}
        />
        {!isTermsDetailsOpen && (
          <div className="confirm-details-container">
            <div
              className="header-content"
              style={{ backgroundColor: theme.backgroundColor }}
            >
              <p style={{ color: theme.textColor }}>Confirm Details</p>
              <p style={{ color: theme.textColor }}>
                <FontAwesomeIcon icon={faClockFour} /> {formatTime(timeLeft)}
              </p>
            </div>
            <div className="user-details-container">
              <div className="details-section">
                <table className="details-table">
                  <tbody>
                    <tr>
                      <td>Last name:</td>
                      <td>
                        <strong>USER</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>First name:</td>
                      <td>
                        <strong>Demo</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Test Name:</td>
                      <td>
                        <strong>OET Practice Test</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Language:</td>
                      <td>
                        <strong>English</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="confirmation-text">
                  Are the details above correct?
                </p>
              </div>
              <div className="confirm-details-footer">
                <button
                  onClick={handleConfirm}
                  style={{ backgroundColor: theme.subHeader }}
                  className="confirm-button"
                >
                  <FontAwesomeIcon icon={faCheck} />
                  Confirm
                </button>
                <button
                  onClick={handleClose}
                  style={{ backgroundColor: theme.subHeader }}
                  className="cancel-button"
                >
                  <FontAwesomeIcon icon={faClose} />
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConfirmDetails;
