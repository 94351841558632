import React, { useState, useEffect, useRef } from "react";
import { ReactMic } from "react-mic";
import { Constants } from "utilities/ApiConstant";
import CircularProgressBar from "../../CircularProgressBar";
import personSpeakGif from "../../../assets/Gif/personSpeak.gif";
import CustomAlert from "components/Alert";
import "./style.scss";
import { getCheckDevice } from "utilities/CommonFunc";
import { defaultMessages } from "utilities/Messages";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

let deviceCheck = getCheckDevice();
let transcription = "";
let title = "";
let audio_of_text = "";
let file_path = "";
let requiredWords = "";
let humanVoiceDetect = true;

let SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
let mic = new SpeechRecognition();

mic.continuous = true;
if (deviceCheck !== "Android") {
  mic.interimResults = true;
}
mic.lang = "en-US";

const PartA = (props) => {
  const {
    micOnOffStatus,
    setMicOnOffStatus,
    isListening,
    setIsListening,
    startRecording,
    stopRecording,
    questionsData,
    currentIndex,
    getAnswerAndMarksData,
    showAudio,
    setShowAudio,
    counter,
    setCounter,
    counterForProgress,
    setCounterForProgress,
    isActiveProgress,
    setIsActiveProgress,
    isActiveTimer,
    setIsActiveTimer,
    audioPlaying,
    setAudioPlaying,
    textRecording,
    setTextRecording,
    theme,
    isHandleNext,
    setIsHandleNext,
    handleNext,
    setIsRecording,
    isRecording,
    setStoreIndexDb,
    storeIndexDb
  } = props;

  const [isAudioEnded, setIsAudioEnded] = useState(false);
  const audioRef = useRef(null);
  const [blobURL, setBlobURL] = useState("");
  const [recordedBlobData, setRecordedBlobData] = useState("");
  const [pauseArray, setPauseArray] = useState([]);
  const [questionLinks, setQuestionLinks] = useState([]);
  const [timerForCircular, setTimerForCircular] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [transcriptionTimeAlert, setTranscriptionTimeAlert] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleUserInteraction = () => {
      audioRef?.current?.play().catch((error) => {
        console.log("Audio playback failed:", error);
      });

      window.removeEventListener("click", handleUserInteraction);
    };

    window.addEventListener("click", handleUserInteraction);

    return () => {
      window.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    if (isHandleNext === true) {
      setKey((prevKey) => prevKey + 1);
      setIsHandleNext(false);
      title = "";
      audio_of_text = "";
      file_path = "";
      transcription = "";
      setBlobURL("");
      setRecordedBlobData("");
      setPauseArray([]);

      requiredWords = "";

      humanVoiceDetect = true;
    }
  }, [isHandleNext]);

  useEffect(() => {
    if (micOnOffStatus === true && recordedBlobData) {
      let audioToTextString = pauseArray?.join(" ");

      pauseArray?.forEach((item, index) => {
        if (audioToTextString?.includes("network error") === true) {
          let getIndex = pauseArray?.indexOf("network error");
          if (pauseArray?.length - 1 > getIndex) {
            transcription =
              pauseArray[getIndex - 1] +
              " " +
              pauseArray[getIndex] +
              " " +
              pauseArray[getIndex + 1];
          } else {
            transcription =
              pauseArray[getIndex - 1] + " " + pauseArray[getIndex];
          }
        } else {
          // when some time count audio
          let newString = "";
          const conCateTextAnswer = () => {
            pauseArray.filter((i, index) => {
              if (pauseArray[index - 1] !== undefined) {
                let pacesCount = i.split(" ").length - 1;
                let pacesDifference = Math.abs(
                  pacesCount - (pauseArray[index - 1].split(" ").length - 1)
                );
                if (
                  ((pacesCount === 0 && index !== 0) ||
                    (pacesCount === 1 && index !== 1)) &&
                  pacesDifference > 3
                ) {
                  if (pauseArray[index - 1].indexOf("error") === -1) {
                    if (pauseArray[index - 1] !== undefined) {
                      if (pauseArray[index - 1].split(" ").length - 1 === 0) {
                        if (pauseArray[index - 2] !== undefined) {
                          newString = newString + pauseArray[index - 2] + " ";
                        }
                      } else {
                        newString = newString + pauseArray[index - 1] + " ";
                      }
                    }
                  } else {
                    if (pauseArray[index - 2] !== undefined) {
                      newString = newString + pauseArray[index - 2] + " ";
                    }
                  }
                }
              }
            });
            return newString;
          };
          let newtextAnswer = conCateTextAnswer();
          transcription =
            newtextAnswer === undefined
              ? pauseArray[pauseArray.length - 1]
              : newtextAnswer + "" + pauseArray[pauseArray.length - 1];
        }
      });

      if (deviceCheck === "Android") {
        transcription = pauseArray.join(" ");
      }

      if (humanVoiceDetect === true) {
        getAnswerAndMarksData(
          title,
          recordedBlobData,
          audio_of_text,
          file_path,
          blobURL,
          transcription
        );
        setStoreIndexDb(true);
      } else {
        getAnswerAndMarksData("", "", "", "", "", "", 0, 0, 0, 0);
        setStoreIndexDb(true);
      }
    }
  }, [micOnOffStatus, recordedBlobData]);

  useEffect(() => {
    if (questionsData) {
      setQuestionLinks(questionsData);
    }
  }, [questionsData]);

  useEffect(() => {
    if (questionLinks[currentIndex]) {
      title = questionLinks[currentIndex]?.title;
      audio_of_text = questionLinks[currentIndex]?.audio_of_text;
      file_path = questionLinks[currentIndex]?.file_path;
      requiredWords = questionLinks[currentIndex]?.related_word;
    }
  }, [questionLinks, currentIndex]);

  useEffect(() => {
    if (timerForCircular === 56 && transcription === "") {
      mic.stop();
      // setIsRecording(false)
      setIsActiveProgress(false);
      setTranscriptionTimeAlert(true);
      humanVoiceDetect = false;
      setIsListening(false);
    }
  }, [timerForCircular]);

  const handleCloseAlert = () => {
    setKey((prevKey) => prevKey + 1);
    setCounterForProgress(60);
    setTranscriptionTimeAlert(false);

    title = "";
    audio_of_text = "";
    file_path = "";
    transcription = "";

    setBlobURL("");
    setRecordedBlobData("");
    setPauseArray([]);
    setIsListening(true);
    setIsRecording(true);

    requiredWords = "";
    humanVoiceDetect = true;
    setIsActiveProgress(true);
  };

  useEffect(() => {
    if (audioPlaying && audioRef.current) {
      setAudioPlaying(true);
      audioRef.current.play().catch((error) => {
        console.error("Audio playback error: ", error);
      });
    }
  }, [audioPlaying]);

  useEffect(() => {
    if (isAudioEnded) {
      startRecording();
      setIsAudioEnded(false);
    }
  }, [isAudioEnded]);

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    mic.stop();
    if (deviceCheck === "Android" && userInfo?.phone === "Y") {
      setMicOnOffStatus(false);
    } else {
      if (isListening) {
        mic.start();
        mic.onend = () => {
          mic.start();
        };
        mic.onstart = () => {};
        mic.onresult = (event) => {
          const transcript = Array.from(event.results)
            .map((result) => result[0])
            .map((result) => result.transcript)
            .join("");

          transcription = transcript;

          pauseArray.push(transcription);
        };
        mic.onerror = (event) => {
          console.error("Microphone error:", event.error);
        };
      } else {
        mic.stop();
        mic.onend = () => {
          if (humanVoiceDetect) {
            setMicOnOffStatus(true);
          }
        };
      }
    }
  };

  const handleAudioEnd = () => {
    setAudioPlaying(false);
    setIsAudioEnded(true);
    setIsActiveProgress(true);
    setTextRecording(true);
  };

  const onStop = async (recordedBlob) => {
    let blob = await fetch(recordedBlob.blobURL).then((r) => r.blob());
    setRecordedBlobData(recordedBlob);
    setBlobURL(blob);

    const reader = new FileReader();
    reader.readAsDataURL(recordedBlob.blob);
  };

  const handleCompleteTimer = () => {
    setIsActiveTimer(false);
    setShowAudio(true);
    setAudioPlaying(true);
  };

  const handleCompleteProgressBar = () => {
    setIsActiveProgress(false);
    setTextRecording(false);
    handleNext();
    setRecordedBlobData();
  };

  return (
    <>
      {!showAudio && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh"
          }}
        >
          <CircularProgressBar
            countDownSize={100}
            strokeWidth={5}
            countDownColor={theme.backgroundColor}
            setCounter={setCounter}
            initialCounter={counter}
            image={false}
            handleComplete={handleCompleteTimer}
            setIsActive={setIsActiveTimer}
            isActive={isActiveTimer}
            theme={theme}
          />
        </div>
      )}
      {showAudio && (
        <div className="main-speaking-section">
          <h4 className="main-header">
            {questionLinks[currentIndex]?.title || "Introduction & Interview"}
          </h4>
          <CustomAlert
            title={defaultMessages.queTimeOutTitle}
            message={defaultMessages.questionTimeOut}
            modalShow={transcriptionTimeAlert}
            setModalShow={setTranscriptionTimeAlert}
            cancelAction={handleCloseAlert}
            cancelText="OK"
            rightBtn={true}
            rightIcon={faCheck}
          />
          <div className="audio-container">
            <div className="box video-box">
              <img
                src={personSpeakGif}
                alt="Person Speaking"
                style={{ width: "100px", height: "100px" }}
              />
            </div>

            <div className="box text-box">
              <p className="description">
                {questionLinks[currentIndex]?.audio_of_text}
              </p>
            </div>
          </div>

          <audio
            ref={audioRef}
            src={`${Constants.apiBaseAudio}${questionLinks[currentIndex]?.file_path}`}
            onEnded={handleAudioEnd}
            style={{ display: "none" }}
          />
          {/* <div className="circular-img-container">
            <CircularProgressBar
              key={key}
              countDownSize={50}
              strokeWidth={4}
              countDownColor={theme.backgroundColor}
              setCounter={setCounterForProgress}
              initialCounter={counterForProgress}
              image={true}
              handleComplete={handleCompleteProgressBar}
              setIsActive={setIsActiveProgress}
              isActive={isActiveProgress}
              onTimeUpdate={(time) => {
                setTimerForCircular(time);
              }}
              theme={theme}
            />
          </div> */}
        </div>
      )}
      <div
        className="react-mic-main"
        style={{ textAlign: "center", display: !showAudio ? "none" : "block" }}
      >
        <div className="circular-img-container">
          <CircularProgressBar
            key={key}
            countDownSize={50}
            strokeWidth={4}
            countDownColor={theme.backgroundColor}
            setCounter={setCounterForProgress}
            initialCounter={counterForProgress}
            image={true}
            handleComplete={handleCompleteProgressBar}
            setIsActive={setIsActiveProgress}
            isActive={isActiveProgress}
            onTimeUpdate={(time) => {
              setTimerForCircular(time);
            }}
            theme={theme}
          />
        </div>
        <ReactMic
          visualSetting="sinewave"
          record={isRecording}
          className="sound-wave"
          onStop={onStop}
          strokeColor="#000000"
          mimeType={
            deviceCheck === "iOS" || deviceCheck === "macOS"
              ? "audio/mp3"
              : "audio/ogg"
          }
        />

        {audioPlaying && <p>Audio is playing...</p>}
        {textRecording && <p>Recording...</p>}
      </div>
    </>
  );
};

export default PartA;
