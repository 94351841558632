import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {
  checkClosingPhrase,
  checkSalutation,
  endIndex,
  isTextValid,
  NameRemoveErrorFromArry,
  ParseFloat,
  roundHalf,
  roundToQuarter,
  startIndex,
  writingGrammarRangeScoreCalculation,
  writingGrammarScoreCalculation,
  writingLexicalResourceScoreCalculation
} from "utilities/CommonFunc";
import axios from "axios";

let score_W = 0;
let errorCount = 0;
let grammarError = 0;
let spellingError = 0;
let userAns = "";
let grammarScore = 0;
let spellingScore = 0;
let vocabularyScore = 0;
let writingScore = 0;
let lexicalResourceScore = 0;
let grammar_range = 0;
let punctuationScore = 0;
let score_Wri = 0;
let form = 0;

const PartA = (props) => {
  const {
    currentPart,
    questionsData,
    currentIndex,
    setCurrentIndex,
    getAnswerAndMarksData,
    setIsPdfOpen,
    isPdfOpen,
    currentPage,
    answersData
  } = props;

  let relatedWords = questionsData[currentIndex]?.related_words;
  let currQuestion = questionsData[currentIndex]?.questions;
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const localStorageKey = `W_Data`;

  const [userAnswer, setUserAnswer] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [userTotalWord, setUserTotalWord] = React.useState(0);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem(localStorageKey)) || {};

    if (storedData) {
      const { userAnswer, wordCount } = storedData;
      setUserAnswer(userAnswer);
      setWordCount(wordCount);
    }
  }, []);

  useEffect(() => {
    if (userAnswer) {
      wMarksCalculation(userAnswer, wordCount, 1);
    }
  }, [userAnswer]);

  useEffect(() => {
    if (currentPage === 1) {
      setIsPdfOpen(true);
    }
  }, [currentPage]);

  const handleChange = (e) => {
    const inputText = e.target.value;
    let wordsArray = inputText.replaceAll("\n", " ").split(" ").filter(Boolean);
    let wordsLength = wordsArray.length;
    setUserAnswer(inputText);
    setUserTotalWord(wordsLength);
    const count = wordsArray.filter((word) => word.length > 0).length;
    setWordCount(count);

    const dataToStore = {
      userAnswer: inputText,
      wordCount: count
    };

    localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
  };

  const wMarksCalculation = (userInput, wordCount, quesNum) => {
    getAnswerAndMarksData(
      quesNum,
      userInput,
      currentPart,
      currentPage,
      wordCount,
      currQuestion
    );
  };

  const handlePdfOpen = () => {
    setIsPdfOpen(true);
  };

  return (
    <div className="writing-partA-wrapper">
      {currentPage === 0 ? (
        <div>
          <div className="part-intro">
            <p className="section-description">
              You now have 5 minutes to read the Case Notes and the Writing
              Task. You will not be able to use the highlight function on the
              PDF during this section. You will be able to use the highlight
              function on the PDF in the next section.
            </p>
            <p className="section-description">
              After 5 minutes you will then progress to the next section where
              you will have the Case Notes and the Writing Task, and 40 minutes
              to write your answer in a text box.
            </p>
            <p className="section-description">
              Please click<b> 'Next' </b>and then
              <b> 'Yes, I would like to finish this section' </b> to end this
              'reading only' section and begin the Writing section of the test.
            </p>
          </div>
          <div className="radio-group">
            <p
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center"
              }}
            >
              <span
                kerning="0"
                letterspacing="0"
                style={{
                  fontFamily: "Arial",
                  fontSize: "16px",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <span
                  className="icon-container"
                  style={{ cursor: "pointer" }}
                  onClick={handlePdfOpen}
                >
                  <FontAwesomeIcon icon={faFilePdf} aria-hidden="true" />
                </span>
                Writing Case Notes
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="part-intro">
            <p className="section-description" style={{ fontWeight: "bold" }}>
              Writing Task
            </p>
            <p className="section-description">{currQuestion}</p>
            <p style={{ fontWeight: "bold" }}>
              In your answer:
              <ul style={{ padding: "0px 0px 0px 15px" }}>
                <li> Expand the relevant notes into complete sentences</li>
                <li>
                  Do <u>not</u> use note form
                </li>
                <li> Use letter format</li>
              </ul>
              The body of the letter should be approximately 180-200 words.
            </p>
          </div>
          <div className="radio-group">
            <p
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center"
              }}
            >
              <span
                kerning="0"
                letterspacing="0"
                style={{
                  fontFamily: "Arial",
                  fontSize: "16px",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <span
                  className="icon-container"
                  style={{ cursor: "pointer" }}
                  onClick={handlePdfOpen}
                >
                  <FontAwesomeIcon icon={faFilePdf} aria-hidden="true" />
                </span>
                Writing Case Notes
              </span>
            </p>
          </div>
          <div className="textarea-container">
            <textarea
              value={userAnswer}
              onChange={handleChange}
              className="textarea"
              placeholder="Start typing..."
            />
            <div className="footer">
              <span>Word Count: {wordCount}</span>
            </div>
          </div>
          <div className="part-intro">
            <p
              className="section-description"
              style={{
                fontWeight: "bold",
                marginBottom: 0,
                color: "red",
                padding: "20px 0 0 0"
              }}
            >
              THAT IS THE END OF THE WRITING TEST{" "}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartA;
