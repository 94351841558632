import React, { useContext, useEffect, useState } from "react";

// dependencies
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Api

// Common Components
import advancedOETDatabase from "../../db";
import CustomAlert from "../../components/Alert";
import AppLoader from "../../components/CustomLoader";
// import SpeckingSection from "../PracticeTest/SpeakingSection";
// import WritingSection from "../PracticeTest/WritingSection";
// import ReadingSection from "../PracticeTest/ReadingSection";
// import ListeningSection from "../PracticeTest/ListeningSection";
import {
  defaultMessages,
  questionSectionTypesTitle
} from "../../utilities/Messages";
import { useLiveQuery } from "dexie-react-hooks";

import Header from "components/Header";
import PartA from "components/ListeningSectionQType/PartA";
import PartB from "components/ListeningSectionQType/PartB";
import PartC from "components/ListeningSectionQType/PartC";
import { AppContext } from "AppContext";

// Globle Values
let previousPage = "";
const questionDetails = questionSectionTypesTitle;

const MockTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // For Handle session alert modal
  const [apiType, setApiType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultTestStatus, setDefaultTestStatus] = useState("");
  const handleCloseAlert = () => {
    setApiType("");
    setAlertTitle("");
    setAlertMessage("");
    setShowAlert(false);
    setDefaultTestStatus("");
  };

  // Questions States
  const [loading, setLoading] = useState(false);
  const [reDirectLoading, setReDirectLoading] = useState(false);
  const [currentQuestionData, setCurrentQuestionData] = useState({
    questionNum: "",
    sectionType: "",
    nextSectionType: questionDetails.speakingSection.sectionType
  });
  const [stadusOfcallApi, setStadusOfcallApi] = useState(false);

  // // Speaking Section Question States
  const [speakingQuesData, setSpeakingQuesData] = useState([]);
  const [speakingTotalQues, setSpeakingTotalQues] = useState();
  const [resumeSpeakingQuesAnsData, setResumeSpeakingQuesAnsData] = useState(
    []
  );

  // // Writing Section Question States
  const [writingQuesData, setWritingQuesData] = useState([]);
  const [writingTotalQues, setWritingTotalQues] = useState();
  const [resumeWritingQuesAnsData, setResumeWritingQuesAnsData] = useState([]);

  // // Reading Section Question States
  const [readingQuesData, setReadingQuesData] = useState([]);
  const [readingTotalQues, setReadingTotalQues] = useState();
  const [resumeReadingQuesAnsData, setResumeReadingQuesAnsData] = useState([]);

  // // Listening Section Question States
  const [listeningQuesData, setListeningQuesData] = useState([]);
  const [listeningTotalQues, setListeningTotalQues] = useState();
  const [resumeListeningQuesAnsData, setResumeListeningQuesAnsData] = useState(
    []
  );

  // get user Data and answerText of localStorage
  previousPage = localStorage.getItem("previousPage");
  const stateFormageRelodeVariable = JSON.parse(localStorage.getItem("reload"));
  const questionData = JSON.parse(localStorage.getItem("questionData"));
  const redirectUrl = JSON.parse(localStorage.getItem("redirect_url"));

  useEffect(() => {}, []);

  // LocalStorage variable
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Context state variable
  const { contextState, handleDefaultValues } = useContext(AppContext);

  // Speaking section test and Question time
  // const [currTestTime, setCurrTestTime] = useState("");
  const [getCurrTestTime, setGetCurrTestTime] = useState();
  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  useEffect(() => {
    handleTestStartTime();
  }, []);
  // Manage starting test time
  const handleTestStartTime = () => {
    if (
      userInfo &&
      userInfo?.testStatus === "P" &&
      userInfo?.session_time?.charAt(0) === "S"
    ) {
      let getTestTime = userInfo && userInfo?.session_time.split("_");
      let splitTestTime = getTestTime && getTestTime[1].split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    } else {
      let getTestTime = contextState.instructionSectionTime.timeWithMin;
      let splitTestTime = getTestTime && getTestTime.split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    }
  };
  useEffect(() => {
    navigate("/oet/InstructionSection");
  }, []);

  return (
    // <>
    //   {loading === true && reDirectLoading === false ? (
    //     <AppLoader title={defaultMessages.examStart} />
    //   ) : loading === false && reDirectLoading === true ? (
    //     <AppLoader
    //       title={
    //         !redirectUrl?.includes("dashboard")
    //           ? defaultMessages.redirect
    //           : defaultMessages.redirectDashboard
    //       }
    //     />
    //   ) : currentQuestionData &&
    //     currentQuestionData.nextSectionType ===
    //       questionDetails.speakingSection.sectionType &&
    //     speakingQuesData.length !== 0 ? (
    //     <SpeckingSection
    //       mockTestID={location?.state?.testID}
    //       testStatus={location?.state?.test_status}
    //       currentTestType={questionDetails.mockTest}
    //       typeBySecQuestionData={speakingQuesData}
    //       typeBySecTotalQue={speakingTotalQues}
    //       currentQuestionData={currentQuestionData}
    //       typeByResumeQuesAnsData={resumeSpeakingQuesAnsData}
    //       setCurrentQuestionData={setCurrentQuestionData}
    //       setSelectsectionForRelad={setSpeakingTotalQues}
    //       pageReloadStatus={location?.state?.PageReloadStatus === "R"}
    //     />
    //   ) : currentQuestionData &&
    //     currentQuestionData.nextSectionType ===
    //       questionDetails.writingSection.sectionType &&
    //     writingQuesData.length !== 0 ? (
    //     <WritingSection
    //       mockTestID={location?.state?.testID}
    //       testStatus={location?.state?.test_status}
    //       currentTestType={questionDetails.mockTest}
    //       typeBySecQuestionData={writingQuesData}
    //       typeBySecTotalQue={writingTotalQues}
    //       currentQuestionData={currentQuestionData}
    //       typeByResumeQuesAnsData={resumeWritingQuesAnsData}
    //       setCurrentQuestionData={setCurrentQuestionData}
    //       setSelectsectionForRelad={setWritingTotalQues}
    //     />
    //   ) : currentQuestionData &&
    //     currentQuestionData.nextSectionType ===
    //       questionDetails.readingSection.sectionType &&
    //     readingQuesData.length !== 0 ? (
    //     <ReadingSection
    //       mockTestID={location?.state?.testID}
    //       testStatus={location?.state?.test_status}
    //       currentTestType={questionDetails.mockTest}
    //       typeBySecQuestionData={readingQuesData}
    //       typeBySecTotalQue={readingTotalQues}
    //       currentQuestionData={currentQuestionData}
    //       typeByResumeQuesAnsData={resumeReadingQuesAnsData}
    //       setCurrentQuestionData={setCurrentQuestionData}
    //       setSelectsectionForRelad={setReadingTotalQues}
    //     />
    //   ) : currentQuestionData &&
    //     currentQuestionData.nextSectionType ===
    //       questionDetails.listeningSection.sectionType &&
    //     listeningQuesData.length !== 0 ? (
    //     <ListeningSection
    //       mockTestID={location?.state?.testID}
    //       testStatus={location?.state?.test_status}
    //       currentTestType={questionDetails.mockTest}
    //       typeBySecQuestionData={listeningQuesData}
    //       typeBySecTotalQue={listeningTotalQues}
    //       currentQuestionData={currentQuestionData}
    //       typeByResumeQuesAnsData={resumeListeningQuesAnsData}
    //       setCurrentQuestionData={setCurrentQuestionData}
    //       setSelectsectionForRelad={setListeningTotalQues}
    //     />
    //   ) : null}
    // </>
    <>
      {/* <Header
        defaultTime={
          (currTestTime?.hours < 9
            ? 0 + "" + currTestTime?.hours
            : currTestTime?.hours) +
          ":" +
          (currTestTime?.minutes < 9
            ? 0 + "" + currTestTime?.minutes
            : currTestTime?.minutes) +
          ":" +
          (currTestTime?.seconds < 9
            ? 0 + "" + currTestTime?.seconds
            : currTestTime?.seconds)
        }
        currTestTime={currTestTime}
        currTesthours={currTestTime?.hours}
        currTestMinutes={currTestTime?.minutes}
        currTestSeconds={currTestTime?.seconds}
        setGetCurrTestTime={setGetCurrTestTime}
      /> */}

      {/* <PartA />
      <PartB />
      <PartC /> */}
    </>
  );
};

export default MockTest;
