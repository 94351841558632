import React, { useRef, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faRotateLeft,
  faStepBackward,
  faStepForward,
  faVolumeUp
} from "@fortawesome/free-solid-svg-icons";
import { ProgressBar } from "react-bootstrap";
import videoForTest from "../../assets/Video/videoForTest.mp4";

const VideoPlayerPopup = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [speed, setSpeed] = useState("1.00x");
  const [speedDropdownOpen, setSpeedDropdownOpen] = useState(false);
  const [volumeControlOpen, setVolumeControlOpen] = useState(false);
  const [fpsDropdownOpen, setFPSDropdownOpen] = useState(false);
  const [fps, setFPS] = useState("30FPS");

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    const progressPercent = (video.currentTime / video.duration) * 100;
    setProgress(progressPercent);
    setCurrentTime(formatTime(video.currentTime));
  };

  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    setCurrentTime(formatTime(video.duration));
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    setSpeedDropdownOpen(false);

    const playbackRates = {
      "1.00x": 1.0,
      "0.25x": 0.25,
      "0.50x": 0.5
    };

    videoRef.current.playbackRate = playbackRates[newSpeed];
  };

  const handleFPSChange = (newSpeed) => {
    setFPS(newSpeed);
    setFPSDropdownOpen(false);
  };
  return (
    <div className="video-player">
      <video
        ref={videoRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        preload="metadata"
        src={videoForTest}
        width="100%"
        height="auto"
      />

      <div className="controls">
        <button onClick={togglePlayPause} className="play-pause-btn">
          {isPlaying ? (
            <FontAwesomeIcon icon={faPause} />
          ) : (
            <FontAwesomeIcon icon={faPlay} />
          )}
        </button>
        <button className="backward-btn">
          <FontAwesomeIcon icon={faStepBackward} />
        </button>
        <button className="forward-btn">
          <FontAwesomeIcon icon={faStepForward} />
        </button>
        <div
          className="dropdown-container-fps"
          onMouseEnter={() => setFPSDropdownOpen(true)}
          onMouseLeave={() => setFPSDropdownOpen(false)}
        >
          <button className="speed-btn">
            <span className="speed">{fps}</span>
          </button>
          {fpsDropdownOpen && (
            <div className="dropdown-menu-speed">
              <button onClick={() => handleFPSChange("60FPS")}>60FPS</button>
              <button onClick={() => handleFPSChange("30FPS")}>30FPS</button>
            </div>
          )}
        </div>
        <span className="time">{currentTime}</span>

        <div className="progress-container">
          <ProgressBar
            className="custom-progress-bar"
            now={progress}
            onClick={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const newProgress = (x / rect.width) * 100;
              videoRef.current.currentTime =
                (newProgress / 100) * videoRef.current.duration;
              setProgress(newProgress);
            }}
          />
        </div>

        <button className="reload-btn" style={{ transform: "rotate(90deg)" }}>
          <FontAwesomeIcon icon={faRotateLeft} />
        </button>
        <div
          className="dropdown-container-speed"
          onMouseEnter={() => setSpeedDropdownOpen(true)}
          onMouseLeave={() => setSpeedDropdownOpen(false)}
        >
          <button className="speed-btn">
            <span className="speed">{speed}</span>
          </button>
          {speedDropdownOpen && (
            <div className="dropdown-menu-speed">
              <button onClick={() => handleSpeedChange("1.00x")}>1.00x</button>
              <button onClick={() => handleSpeedChange("0.25x")}>0.25x</button>
              <button onClick={() => handleSpeedChange("0.50x")}>0.50x</button>
            </div>
          )}
        </div>
        <div className="volume-control">
          <button
            className="volume-icon"
            onClick={() => setVolumeControlOpen(!volumeControlOpen)}
          >
            <FontAwesomeIcon icon={faVolumeUp} />
          </button>
          {volumeControlOpen && (
            <div className="volume-slider-container">
              <input
                type="range"
                className="volume-slider"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerPopup;
