import React from "react";
import "./style.scss";
import OETsection from "../../assets/Images/OET section.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faMousePointer } from "@fortawesome/free-solid-svg-icons";

const IntroductionForEachSection = (props) => {
  const { currentSection } = props;

  return (
    <>
      <div className="introduction">
        {currentSection === "ListeningSection" ? (
          <div className="main-content-wrapper">
            <div className="main-content">
              <div
                className="introduction-text background allow-select"
                data-componentid="2"
                aria-hidden="false"
              >
                <div className="no-label">
                  <div className="option-content-container">
                    <div style={{ leading: "2" }}>
                      <div style={{ textAlign: "left" }}>
                        <div
                          className="text-with-size"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "16px",
                            letterSpacing: "0",
                            kerning: "0"
                          }}
                        >
                          <p>
                            You are about to start the Listening test. This test
                            will take approximately 40 minutes. As soon as you
                            click <b>'Next' </b> below, the audio will begin to
                            play automatically, and you will not be able to
                            pause or replay the recording.
                          </p>
                          <p>
                            <br />
                            One mark will be granted for each correct answer.
                          </p>
                          <p>
                            <br />
                            Answer <b>ALL </b>questions. Marks are <b>NOT </b>
                            deducted for incorrect answers.
                          </p>
                          <p>
                            <br />
                            When you complete the Listening test, you will need
                            to click <b> 'Next' </b> to go to the next section.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            The Listening sub-test can take several seconds to
                            load as it is a large audio file. Be patient.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            Please click <b>'Next' </b> and then
                            <b>'Yes, I would like to finish this section'</b> to
                            end this section and begin the Listening sub-test.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : currentSection === "ReadingSection" ? (
          <div className="reading-section-intro">
            <p className="test-instructions">
              You are about to start<b> Part A </b>of the Reading test. You must
              answer the questions within the 15-minute time limit.
            </p>
            <p className="test-instructions">
              Once you begin, you must answer the questions presented on the
              right, using the texts presented on the left.
            </p>
            <p className="test-instructions">
              One mark will be granted for each correct answer. Answer
              <b> ALL </b>questions. Marks are<b> NOT </b>deducted for incorrect
              answers.
            </p>
            <p className="test-instructions">
              As soon as you click<b> 'Next' </b>below, the 15-minute timer will
              begin counting down.
            </p>
            <p className="test-instructions">
              Please note that there is a 10-minute scheduled break after the
              end of Part A of the Reading sub-test. As soon as you click
              <b> 'Next' </b>at the end of Part A, the scheduled break starts.
              If you choose to take a break and leave the room or leave the view
              of the Remote Proctor, you must go through the check-in procedures
              again.
            </p>
            <p className="test-instructions">
              At the end of the 10-minute scheduled break, or when you click
              <b> 'Next' </b>during the scheduled break, the 45-minute timer
              will begin counting down for Parts B and C of the Reading
              sub-test.
            </p>
            <p className="test-instructions">
              Please click<b> 'Next' </b>and then
              <b> 'Yes, I would like to finish this section' </b>to end this
              section and begin the Reading sub-test.
            </p>
          </div>
        ) : currentSection === "WritingSection" ? (
          <div className="writing-section-intro">
            <p className="test-instructions">
              You are about to start the Writing test.
            </p>
            <p className="header-instructions">
              <h4>TIME ALLOWED</h4>
              <ul style={{ padding: "15px" }}>
                <li>
                  <b>READING TIME: 5 MINUTES</b>
                </li>
                <li>
                  <b>WRITING TIME: 40 MINUTES</b>
                </li>
              </ul>
            </p>
            <p className="test-instructions">
              <b>Reading time: 5 minutes</b>
              <br />
              During this time you may study the writing task and notes. You
              will not be able to highlight the text or type any notes.
            </p>
            <p className="test-instructions">
              <b>Reading time: 5 minutes</b>
              <br />
              During this time, you will be able to type your response to the
              task.
            </p>
            <p className="test-instructions">
              As soon as you click 'Next' below, the 5-minute timer for Reading
              Time will begin counting down, followed immediately by the
              40-minute Writing Time.
            </p>
            <p className="test-instructions">
              Once you complete the test, you need to click 'Finish Test' on the
              top right-hand side of the screen and you will not be able to
              return to the test.
            </p>
            <p className="test-instructions">
              Please click 'Next' and then "Yes, I would like to finish this
              section" to end this section and begin the Writing sub-test.
            </p>
          </div>
        ) : currentSection === "SpeakingSection" ? (
          <div className="writing-section-intro">
            <p className="test-instructions">
              You are about to start the Speaking test.
            </p>
            <p className="header-instructions">
              <h4>TIME ALLOCATE</h4>
              <ul style={{ padding: "15px" }}>
                <li>
                  <b style={{ margin: 0 }}> Part A </b>
                  <p style={{ marginTop: 0 }}>Duration: 3-4 minutes.</p>
                </li>
                <li>
                  <b style={{ margin: 0 }}> Part B </b>
                  <p style={{ margin: 0 }}> Preparation Time: 3 minutes.</p>
                  <p style={{ marginTop: 0 }}>Role-play Duration: 5 minutes.</p>
                </li>
                <li>
                  <b style={{ margin: 0 }}> Part C </b>
                  <p style={{ margin: 0 }}> Preparation Time: 3 minutes.</p>
                  <p style={{ marginTop: 0 }}>Role-play Duration: 5 minutes.</p>
                </li>
              </ul>
            </p>

            <p className="test-instructions">
              As soon as you click 'Next' below, the 5-minute timer for Speaking
              Time will begin counting down, followed immediately by the
              40-minute Speaking Time.
            </p>
            <p className="test-instructions">
              Once you complete the test, you need to click 'Finish Test' on the
              top right-hand side of the screen and you will not be able to
              return to the test.
            </p>
            <p className="test-instructions">
              Please click 'Next' and then "Yes, I would like to finish this
              section" to end this section and begin the Speaking sub-test.
            </p>
          </div>
        ) : (
          <></>
        )}
        <div className="ipad-scroll-helper">&nbsp;</div>
      </div>
    </>
  );
};

export default IntroductionForEachSection;
