import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import reactStringReplace from "react-string-replace";
import { decodeEntities } from "utilities/CommonFunc";

let score_R = 0;
const PartA = (props) => {
  const {
    currentPart,
    questionsData,
    currentIndex,
    setCurrentIndex,
    getAnswerAndMarksData,
    setIsPdfOpen,
    isPdfOpen,
    answersData
  } = props;

  let currQuestionDataA =
    questionsData && questionsData[currentIndex]?.questions;
  let currQuestionDataB = questionsData && questionsData[1]?.questions;
  let currQuestionDataC = questionsData && questionsData[2]?.questions;
  let currQuestionType =
    questionsData && questionsData[currentIndex]?.question_type;
  let currAnswersData = answersData.length !== 0 ? answersData : [];

  // Decode html entities

  const [userAnswer, setUserAnswer] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [quesNum, setQuesNum] = useState();
  const [inputs, setInputs] = useState({});
  const [questions, setQuestions] = useState([]);

  const localStorageKey = `R_Data`;
  useEffect(() => {
    const parseQuestions = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(currQuestionDataA, "text/html");

      const questionBlocks = doc.querySelectorAll(".question-block");
      const parsedQuestions = Array.from(questionBlocks)
        ?.slice(0, 7)
        ?.map((block) => {
          const questionId = block.querySelector(".section-description").id;
          const questionText = block.querySelector(
            ".section-description"
          ).innerText;

          const options = Array.from(
            block.querySelectorAll(".direction-option")
          )
            ?.slice(0, 7)
            ?.map((option) => {
              const optionText = option.querySelector(".option-text").innerText;
              const optionDescription = option.querySelector(
                ".option-description"
              ).innerText;
              return { text: optionText, description: optionDescription };
            });

          return {
            id: questionId,
            question: questionText,
            options
          };
        });

      setQuestions(parsedQuestions);
    };

    parseQuestions();
  }, [currQuestionDataA]);

  useEffect(() => {
    const savedOptions =
      JSON.parse(localStorage.getItem(localStorageKey)) || {};
    setSelectedOptions(savedOptions);
    setInputs(savedOptions);
  }, []);

  useEffect(() => {
    if (selectedOptions && quesNum) {
      rMarksCalculation(userAnswer, quesNum);
    }
  }, [selectedOptions, quesNum, userAnswer]);

  const handleChange = (e, questionId, key) => {
    if (key === "#") {
      const updatedOptions = {
        ...selectedOptions,
        [questionId]: e.description
      };

      setUserAnswer(e.description);
      setSelectedOptions(updatedOptions);
      setQuesNum(Number(questionId));
      localStorage.setItem(localStorageKey, JSON.stringify(updatedOptions));
    } else {
      const { value, id } = e.target;
      setUserAnswer(value);
      setQuesNum(Number(id));
      setInputs((prevInputs) => {
        const updatedInputs = { ...prevInputs, [questionId]: value };
        localStorage.setItem(localStorageKey, JSON.stringify(updatedInputs));

        return updatedInputs;
      });
    }
  };

  const rMarksCalculation = (userInput, quesNum) => {
    // currAnswersData?.forEach((element, i) => {
    //   if (quesNum === i + 1) {
    //     if (
    //       element?.toLowerCase()?.split("|")?.includes(userInput.toLowerCase())
    //     ) {
    //       score_R = 1;
    //     } else {
    //       score_R = 0;
    //     }
    //   }
    // });

    // Calling function And Get Data in ListeningSection Main File
    getAnswerAndMarksData(
      quesNum,
      userInput,
      // htmlAnswerArray,
      score_R,
      currentPart,
      // currQuestion,
      currQuestionType
    );

    // htmlAnswerArray = [];
  };

  const handlePdfOpen = () => {
    setIsPdfOpen(true);
  };

  return (
    <div className="reading-partA-wrapper">
      <div className="part-intro">
        <h3>Part A</h3>
        <p className="section-title">
          <b>TIME:</b> 15 minutes
        </p>
        <p className="section-description">
          <li>
            Look at the four texts, <b>A-D</b>, in the
            <b> PDF on the left screen.</b>
          </li>
          <li>
            For each question, <b>1-20</b>, look through the texts, <b>A-D</b>{" "}
            to find the relevant information.
          </li>
          <li>
            Complete your answers in the spaces provided <b>below</b>.
          </li>
          <li>Answer all the questions within the 15-minute time limit.</li>
          <li>
            Your answers should <b>only</b> be taken from texts <b>A-D</b> and
            must be correctly spelt.
          </li>
        </p>
      </div>
      <div className="part-intro" style={{ margin: "50px 0px" }}>
        <p className="section-title">
          <b>Questions 1-7</b>
        </p>
        <p className="section-description">
          For each question, 1-7, decide which text (A, B, C or D) the
          information comes from. You may use any letter more than once.
        </p>
      </div>

      {questions?.map((question, index) => (
        <div key={index} className="question-block">
          <p className="section-description">
            {question?.id} {question?.question}
          </p>
          <div className="radio-group">
            <p
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center"
              }}
            >
              <span
                kerning="0"
                letterspacing="0"
                style={{
                  fontFamily: "Arial",
                  fontSize: "16px",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <span
                  className="icon-container"
                  style={{ cursor: "pointer" }}
                  onClick={handlePdfOpen}
                >
                  <FontAwesomeIcon icon={faFilePdf} aria-hidden="true" />
                </span>
                Reading Part A Texts
              </span>
            </p>
          </div>
          <div className="radio-group">
            {question?.options?.map((option, key) => (
              <div key={key} className="direction-option">
                <div
                  className={`option-container ${
                    selectedOptions[question?.id] === option.description
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleChange(option, question?.id, "#")}
                >
                  <div
                    className={`option-container2 ${
                      selectedOptions[question?.id] === option.description
                        ? "selected"
                        : ""
                    }`}
                  >
                    {option.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="part-intro" style={{ margin: "50px 0px 0px 0px" }}>
        <p className="section-title">
          <b>Questions 8-13</b>
        </p>
        <p className="section-description">
          Answer each of the questions, 8-13, with a word or short phrase from
          one of the texts. Each answer may include words, numbers or both.
        </p>
      </div>

      <div className="radio-group">
        <p
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center"
          }}
        >
          <span
            kerning="0"
            letterspacing="0"
            style={{
              fontFamily: "Arial",
              fontSize: "16px",
              color: "#000000",
              display: "flex",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <span
              className="icon-container"
              style={{ cursor: "pointer" }}
              onClick={handlePdfOpen}
            >
              <FontAwesomeIcon icon={faFilePdf} aria-hidden="true" />
            </span>
            Reading Part A Texts
          </span>
        </p>
      </div>

      <div className="question-section">
        <span>
          {reactStringReplace(
            currQuestionDataB,
            /(\•|\(\d+\)#DD#|<b>.*?<\/b>)/g,
            (match, i) => {
              if (match === "•") {
                return <br key={`bullet-${i}`} />;
              }

              if (match.startsWith("<b>") && match.endsWith("</b>")) {
                const boldText = match.replace(/<\/?b>/g, "");
                return (
                  <React.Fragment key={`bold-${i}`}>
                    <br />
                    <strong>{boldText}</strong>
                  </React.Fragment>
                );
              }

              const numberMatch = /\((\d+)\)#DD#/.exec(match);

              if (numberMatch) {
                const number = numberMatch[1];

                return (
                  <div key={`group-${i}`} className="input-wrapper">
                    <Form.Control
                      type="text"
                      className="answer-input"
                      id={number}
                      onChange={(e) => handleChange(e, number)}
                      spellCheck="false"
                      autoComplete="off"
                      value={inputs[number] || ""}
                    />
                  </div>
                );
              }

              return match;
            }
          )}
        </span>
      </div>
      <div className="part-intro" style={{ margin: "50px 0px 0px 0px" }}>
        <p className="section-title">
          <b>Questions 14-20</b>
        </p>
        <p className="section-description">
          Complete each of the sentences, 14-20, with a word or short phrase
          from one of the texts. Each answer may include words, numbers or both.
        </p>
      </div>
      <div className="radio-group">
        <p
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center"
          }}
        >
          <span
            kerning="0"
            letterspacing="0"
            style={{
              fontFamily: "Arial",
              fontSize: "16px",
              color: "#000000",
              display: "flex",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <span
              className="icon-container"
              style={{ cursor: "pointer" }}
              onClick={handlePdfOpen}
            >
              <FontAwesomeIcon icon={faFilePdf} aria-hidden="true" />
            </span>
            Reading Part A Texts
          </span>
        </p>
      </div>

      <div className="question-section">
        <span>
          {reactStringReplace(
            currQuestionDataC,
            /(\•|\(\d+\)#DD#|<b>.*?<\/b>)/g,
            (match, i) => {
              if (match === "•") {
                return <br key={`bullet-${i}`} />;
              }

              if (match.startsWith("<b>") && match.endsWith("</b>")) {
                const boldText = match.replace(/<\/?b>/g, "");
                return (
                  <React.Fragment key={`bold-${i}`}>
                    <br />
                    <strong>{boldText}</strong>
                  </React.Fragment>
                );
              }

              const numberMatch = /\((\d+)\)#DD#/.exec(match);

              if (numberMatch) {
                const number = numberMatch[1];

                return (
                  <div key={`group-${i}`} className="input-wrapper">
                    <Form.Control
                      type="text"
                      className="answer-input"
                      id={number}
                      onChange={(e) => handleChange(e, number)}
                      spellCheck="false"
                      autoComplete="off"
                      value={inputs[number] || ""}
                    />
                  </div>
                );
              }

              return match;
            }
          )}
        </span>
      </div>
      <div className="last-container">
        <p className="section-description">That is the end of Part A.</p>
      </div>
    </div>
  );
};

export default PartA;
