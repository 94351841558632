import React, { useContext } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "AppContext";

const CustomAlert = (props) => {
  const {
    title,
    message,
    icon,
    modalShow,
    setModalShow,
    confirmAction,
    cancelAction,
    confirmText = "Yes",
    cancelText = "No",
    leftBtn,
    rightBtn,
    leftIcon,
    rightIcon
  } = props;
  const { theme } = useContext(AppContext);

  // If modal is not visible, return null
  if (!modalShow) return null;

  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-container">
        <div
          className="custom-alert-header"
          style={{
            backgroundColor: theme.backgroundColor,
            height: "50px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "3%",
            fontWeight: "bold",
            color: theme.textColor,
            borderRadius: "6px 6px 0 0"
          }}
        >
          {title}
        </div>

        <div className="custom-alert-body text-center">
          {icon && <FontAwesomeIcon icon={icon} size="4x" color="#f1c40f" />}

          <p className="mt-3 custom-line-height">{message}</p>
        </div>

        <div className="custom-alert-footer">
          {leftBtn && (
            <button
              style={{
                backgroundColor: theme.subHeader,
                color: theme.textColor
              }}
              className="custom-alert-btn"
              onClick={confirmAction}
            >
              {leftIcon && (
                <>
                  <FontAwesomeIcon
                    icon={leftIcon}
                    className="custom-alert-fa-check"
                  />
                  {confirmText}
                </>
              )}
            </button>
          )}
          {rightBtn && (
            <button
              style={{
                backgroundColor: theme.subHeader,
                color: theme.textColor
              }}
              className="custom-alert-btn"
              onClick={cancelAction}
            >
              {rightIcon && (
                <>
                  <FontAwesomeIcon
                    icon={rightIcon}
                    className="custom-alert-fa-check"
                  />
                  {cancelText}
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
