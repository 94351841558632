//Network Constants

export const ACTION_TYPE = {
  // User LogOut
  LOGOUT_USER_REQUEST: "LOGOUT_USER_REQUEST",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAILURE: "LOGOUT_USER_FAILURE",

  // Questions Type
  GET_AllQUESTIONS_LIST_REQUEST: "GET_AllQUESTIONS_LIST_REQUEST",
  GET_AllQUESTIONS_LIST_SUCCESS: "GET_AllQUESTIONS_LIST_SUCCESS",
  GET_AllQUESTIONS_LIST_FAILURE: "GET_AllQUESTIONS_LIST_FAILURE",

  // Submit Answer Type
  ADD_AUDIOFILE_BY_TYPE_SUBMIT_REQUEST: "ADD_AUDIOFILE_BY_TYPE_SUBMIT_REQUEST",
  ADD_AUDIOFILE_BY_TYPE_SUBMIT_SUCCESS: "ADD_AUDIOFILE_BY_TYPE_SUBMIT_SUCCESS",
  ADD_AUDIOFILE_BY_TYPE_SUBMIT_FAILURE: "ADD_AUDIOFILE_BY_TYPE_SUBMIT_FAILURE",

  // Submit Updated Questions Data by Test Module
  ADD_UPDATED_ANSWER_SUBMIT_REQUEST: "ADD_UPDATED_ANSWER_SUBMIT_REQUEST",
  ADD_UPDATED_ANSWER_SUBMIT_SUCCESS: "ADD_UPDATED_ANSWER_SUBMIT_SUCCESS",
  ADD_UPDATED_ANSWER_SUBMIT_FAILURE: "ADD_UPDATED_ANSWER_SUBMIT_FAILURE",

  // Submit Custome test and Question wise Test customAiSubmit
  ADD_CUSTOM_AI_ANSWER_SUBMIT_REQUEST: "ADD_CUSTOM_AI_ANSWER_SUBMIT_REQUEST",
  ADD_CUSTOM_AI_ANSWER_SUBMIT_SUCCESS: "ADD_CUSTOM_AI_ANSWER_SUBMIT_SUCCESS",
  ADD_CUSTOM_AI_ANSWER_SUBMIT_FAILURE: "ADD_CUSTOM_AI_ANSWER_SUBMIT_FAILURE",

  // Submit Feedback
  ADD_FEEDBACK_SUBMIT_REQUEST: "ADD_FEEDBACK_SUBMIT_REQUEST",
  ADD_FEEDBACK_SUBMIT_SUCCESS: "ADD_FEEDBACK_SUBMIT_SUCCESS",
  ADD_FEEDBACK_SUBMIT_FAILURE: "ADD_FEEDBACK_SUBMIT_FAILURE",

  //  upcoming api Question
  GET_UPCOMING_QUESTIONS_LIST_REQUEST: "GET_UPCOMING_QUESTIONS_LIST_REQUEST",
  GET_UPCOMING_QUESTIONS_LIST_SUCCESS: "GET_UPCOMING_QUESTIONS_LIST_SUCCESS",
  GET_UPCOMING_QUESTIONS_LIST_FAILURE: "GET_UPCOMING_QUESTIONS_LIST_FAILURE",

  // Speking Submit Answer Type
  ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_REQUEST:
    "ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_REQUEST",
  ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_SUCCESS:
    "ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_SUCCESS",
  ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_FAILURE:
    "ADD_AUDIOFILE_BY_TYPE_SPEKING_SUBMIT_FAILURE",

  // Speking Submit Answer Type
  ADD_CHECK_INSTITUTE_ACTIVESTUDENT_REQUEST:
    "ADD_CHECK_INSTITUTE_ACTIVESTUDENT_REQUEST",
  ADD_CHECK_INSTITUTE_ACTIVESTUDENT_SUCCESS:
    "ADD_CHECK_INSTITUTE_ACTIVESTUDENT_SUCCESS",
  ADD_CHECK_INSTITUTE_ACTIVESTUDENT_FAILURE:
    "ADD_CHECK_INSTITUTE_ACTIVESTUDENT_FAILURE",

  // report Actions Answer Type
  ADD_CHECK_REPORT_REQUEST: "ADD_CHECK_REPORT_REQUEST",
  ADD_CHECK_REPORT_SUCCESS: "ADD_CHECK_REPORT_SUCCESS",
  ADD_CHECK_INSTITUTE_ACTIVESTUDENT_FAILURE: "ADD_CHECK_REPORT_FAILURE",

  // repeated Question Answer Type
  REPEATED_QUESTION_REQUEST: "REPEATED_QUESTION_REQUEST",
  REPEATED_QUESTION_SUCCESS: "REPEATED_QUESTION_SUCCESS",
  REPEATED_QUESTION_FAILURE: "REPEATED_QUESTION_FAILURE",

  // Submit Custome test and Question wise Test customAiSubmit
  ADD_CUSTOM_ANSWER_SUBMIT_REQUEST: "ADD_CUSTOM_ANSWER_SUBMIT_REQUEST",
  ADD_CUSTOM_ANSWER_SUBMIT_SUCCESS: "ADD_CUSTOM_ANSWER_SUBMIT_SUCCESS",
  ADD_CUSTOM_ANSWER_SUBMIT_FAILURE: "ADD_CUSTOM_ANSWER_SUBMIT_FAILURE",

  // Submit Type
  POST_SUBMIT_ANSWER_REQUEST: "POST_SUBMIT_ANSWER_REQUEST",
  POST_SUBMIT_ANSWER_SUCCESS: "POST_SUBMIT_ANSWER_SUCCESS",
  POST_SUBMIT_ANSWER_FAILURE: "POST_SUBMIT_ANSWER_FAILURE"
};
