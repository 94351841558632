import Dexie from "dexie";

const advancedOETDatabase = new Dexie("PREP-OET-27"); // database name
advancedOETDatabase.version(1).stores({
  speakingTestAnswer: "++id"
});
advancedOETDatabase.version(1).stores({
  writingTestAnswer: "++id"
});
advancedOETDatabase.version(1).stores({
  readingTestAnswer: "++id"
});
advancedOETDatabase.version(1).stores({
  listeningTestAnswer: "++id"
});
advancedOETDatabase.version(1).stores({
  customTestAnswer: "++id"
});

advancedOETDatabase.version(1).stores({
  listeningTestQuestions: "++id"
});
advancedOETDatabase.version(1).stores({
  readingTestQuestions: "++id"
});
advancedOETDatabase.version(1).stores({
  writingTestQuestions: "++id"
});
advancedOETDatabase.version(1).stores({
  speakingTestQuestions: "++id"
});

advancedOETDatabase.version(1).stores({
  customTestQuestions: "++id"
});

advancedOETDatabase.version(1).stores({
  retryQueTypeData: "++id"
});
export default advancedOETDatabase;
