import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import PartA from "components/ReadingSectionQType/PartA";
import PartB from "components/ReadingSectionQType/PartB";
import { AppContext } from "AppContext";
import Footer from "components/Footer";
import CustomAlert from "components/Alert";
import advancedOETDatabase from "db";
import { useLiveQuery } from "dexie-react-hooks";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import AppLoader from "components/CustomLoader";
import PdfViewer from "components/PdfViewer";
import ResumeTest from "components/ResumeTest";
import PartC from "components/ReadingSectionQType/PartC";
import {
  getAllQuestionsList,
  getAllQuestionsLTemp
} from "../../../redux/action/QuestionAction";
import { submitQuestionAnswer } from "../../../redux/action/AnswerAction";
import { defaultMessages } from "utilities/Messages";

const ReadingSection = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Get Reading Test Answer of IDB
  const getAnswerReadingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.readingTestAnswer.toArray(),
    []
  );

  // Get Reading Test Question of IDB
  const getQuestionsReadingTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.readingTestQuestions.toArray(),
    []
  );

  // Context state variable
  const { contextState, handleDefaultValues } = useContext(AppContext);
  const myRef = useRef();
  const [newResumeArry, setNewResumeArry] = React.useState([]);

  const sectionTime = "Section";
  const [userAnswer, setUserAnswer] = useState("");
  const [htmlAnswer, setHtmlAnswer] = useState("");
  const [quesNum, setQuesNum] = useState();
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [answersData, setAnswersData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currIndex, setCurrIndex] = useState(currentIndex + 1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [resumeTest, setResumeTest] = useState(false);
  const [onResume, setOnResume] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSection, setcurrentSection] = useState("ReadingSection");
  const [currSection, setcurrSection] = useState("Reading");
  const [nextSection, setnexttSection] = useState("WritingSection");
  const [currentPart, setCurrentPart] = useState("PART_A");
  const [extractIndex, setExtractIndex] = useState(0);
  const [showQuationPart, setShowQuationPart] = useState("");
  const [currPart, setCurrPart] = useState("PART_A");
  const [currentPage, setCurrentPage] = useState(1);
  const [score, setScore] = useState(0);
  const { theme } = useContext(AppContext);
  const [sectionText, setSectionText] = useState(
    currSection + " PartA - " + currIndex
  );
  const [statusOfShowsections, setStatusOfShowsections] = React.useState("");

  // For Handle session alert modal
  const [apiType, setApiType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultTestStatus, setDefaultTestStatus] = useState("");
  const [nextAction, setNextAction] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  // Speaking section test and Question time
  const [getCurrTestTime, setGetCurrTestTime] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    if (nextAction === true) {
      handleNextAction("loader");
      setNextAction(false);
    }
  }, [nextAction]);

  const handleNextAction = async (loader) => {
    setShowAlert(false);
    let PART_A = [];
    let PART_B = [];
    let PART_C = [];
    let totalScore = 0;
    let band = 0;
    let r_part_A = 0;
    let r_part_B = 0;
    let r_part_C = 0;

    (await getAnswerReadingTestDataOfDB) &&
      getAnswerReadingTestDataOfDB.filter((element, index) => {
        totalScore += element?.score;

        if (element?.currPart === "PART_A") {
          r_part_A += element?.score;
          PART_A.push({
            id: element?.id,
            user_answer: element?.userAnswer,
            score: element?.score,
            question_type: element?.queType,
            currPart: element?.currPart
          });
        }
        if (element?.currPart === "PART_B") {
          r_part_B += element?.score;
          PART_B.push({
            id: element?.id,
            user_answer: element?.userAnswer,
            score: element?.score,
            question_type: element?.queType,
            currPart: element?.currPart
          });
        }
        if (element?.currPart === "PART_C") {
          r_part_C += element?.score;
          PART_C.push({
            id: element?.id,
            user_answer: element?.user_answer,
            score: element?.score,
            question_type: element?.queType,
            currPart: element?.currPart
          });
        }
      });

    // band = listeningScoreCalculation(totalScore);

    let newObject = {};
    // if (userInfo?.type === "M") {
    newObject = {
      // last_question: `L|${selectedIndex}`,
      reading: {
        status: loader === "save_and_exit" ? "P" : "C",
        module_time: currTestTime,
        // answer_data: {
        //   PART_A,
        //   PART_B,
        //   PART_C
        // },
        answer_data:
          currentPart === "PART_C"
            ? { PART_C }
            : currentPart === "PART_B"
            ? { PART_B }
            : { PART_A },
        score_data: {
          band: band,
          listening_score: totalScore,
          r_part_A: r_part_A,
          r_part_B: r_part_B,
          r_part_C: r_part_C
        }
      }
    };
    // }
    // } else {
    //   newObject = {
    //     last_question: { ...positionOfquestions, listening: selectedIndex },
    //     listening: {
    //       audio_time: currentTime.toFixed(2),
    //       status: loader === "save_and_exit" ? "P" : "C",
    //       listening_module_time: userInfo?.session_time,
    //       answer_data: {
    //         PART_A,
    //         PART_B,
    //         PART_C,
    //         PART_D
    //       },
    //       score_data: {
    //         band: band,
    //         correct_answers: totalScore
    //       }
    //     }
    //   };
    // }

    sectionEndApi(newObject, loader);
  };

  const sectionEndApi = (data, loader) => {
    if (loader === "loader" || loader === "save_and_exit") {
      setLoading(true);
    }

    const header = {
      Accept: "application/json",
      "user-id": userInfo?.id,
      "api-token": userInfo?.api_token,
      "test-token": userInfo?.test_token
    };

    const dataList = {
      user_id: userInfo?.id,
      test_id: userInfo?.testID,
      test_type: userInfo?.type,
      part: "all",
      data: data,
      test_status:
        loader === "loader" || loader === "save_and_exit" ? "P" : "C",
      section_type: "reading",
      resume: loader === "save_and_exit" ? true : false,
      is_continue: loader === "save_and_exit" ? false : true
    };
    dispatch(
      submitQuestionAnswer({
        header,
        dataList,
        onSuccess: (response) => {
          console.log(response, "res");
          if (response?.data?.statusCode === 200) {
            if (loader === "loader" && userInfo?.type === "M") {
              setLoading(false);
            } else if (loader === "save_and_exit" || userInfo?.type === "P") {
              if (userInfo?.redirect_url) {
                window.location.replace(userInfo?.redirect_url);
              } else {
                window.location.replace(
                  `${window.origin + "/Student/dashboard"}`
                );
              }
            }
          }

          if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            // setMessageAlert(defaultMessages.sessionTimeOut);
            // setApiType(defaultMessages?.logoutApicall);
          }
        },
        onFailure: (error) => {
          console.log(error);
          setShowAlert(true);
          setDefaultTestStatus(599);
          // setMessageAlert(error?.message);
          // setApiType(defaultMessages?.submitAnswerAPI);
        }
      })
    );
  };
  //for reloading
  useEffect(() => {
    const storedPosition = localStorage.getItem("position");

    if (storedPosition) {
      const { typeOfPartsIndex } = JSON.parse(storedPosition);

      if (typeOfPartsIndex?.startsWith("R-")) {
        const [part, page] = typeOfPartsIndex.split("-").slice(1);
        if (!isNaN(page) && ["PART_B", "PART_C"].includes(part)) {
          setCurrentPart(part);
          setCurrentPage(parseInt(page, 10));
        }
      }
    } else {
      setCurrentPart("PART_A");
      setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (
      (currentPart === "PART_B" || currentPart === "PART_C") &&
      currentPage !== undefined
    ) {
      const formattedPosition = `R-${currentPart}-${currentPage}`;
      localStorage.setItem(
        "position",
        JSON.stringify({
          typeOfPartsIndex: formattedPosition
        })
      );
    }
  }, [currentPart, currentPage]);

  useEffect(() => {
    if (currentIndex !== undefined && currentPart === "PART_A") {
      const formattedPosition = `R-${currentPart}-${currentPage}`;
      localStorage.setItem(
        "position",
        JSON.stringify({
          typeOfPartsIndex: formattedPosition
        })
      );
    }
  });
  //end for reloading

  useEffect(() => {
    handleTestStartTime();
    if (currentPart === "PART_B" || currentPart === "PART_C") {
      setSectionText(currSection + " Part B and C ");
    } else {
      setSectionText(currSection + " Part A - " + currIndex);
    }
  }, [currentPart, currentIndex]);

  useEffect(() => {
    if (onResume === true) {
      setLoading(true);
      setOnResume(false);
      setResumeTest(false);
      setTimeout(() => {
        setCurrentPart("PART_B");
        setLoading(false);
      }, 1500);
    }
  }, [onResume, resumeTest, currentPage]);

  useEffect(() => {
    if (currentPage > 6 && currentPart === "PART_B") {
      setCurrentPart("PART_C");
    } else if (currentPage < 7 && currentPart === "PART_C") {
      setCurrentPart("PART_B");
    }
  }, [currentPage, currentPart]);

  useEffect(() => {
    const readingTestDataLength = getQuestionsReadingTestDataOfDB?.length || 0;

    if (getQuestionsReadingTestDataOfDB && readingTestDataLength === 0) {
      setLoading(true);
      getQuestionsApi("reading", userInfo?.testStatus);
    }
  }, [getQuestionsReadingTestDataOfDB]);

  useEffect(() => {
    // if (userInfo?.type === "P") {
    if (getQuestionsReadingTestDataOfDB !== undefined) {
      let reading = getQuestionsReadingTestDataOfDB.map((item) => {
        // if (item?.data?.length === 2) {
        return item;
        // }
      });
      if (reading?.length !== 0) {
        if (reading[0]) {
          setQuestionsData(reading[0]?.readingAllQuestions);
          setAnswersData(reading[0]?.readingAllAnswers);
          setLoading(false);
        }
      }
    }
    // }
  }, [getQuestionsReadingTestDataOfDB]);

  useEffect(() => {
    if (userAnswer !== undefined && userAnswer !== "") {
      const tempObj = {
        id: quesNum,
        userAnswer: userAnswer,
        currPart: currPart,
        score: score
      };
      storeAnswer(tempObj);
    }
  }, [userAnswer]);

  const handleNextSection = () => {
    setSessionTimeOut(true);
  };

  // store resume data in indexDb
  const addResumeQueTypeDataToIDB = async (tempObj) => {
    const newData =
      (await getAnswerReadingTestDataOfDB.length) === 0 &&
      (await getAnswerReadingTestDataOfDB.filter(
        (item) => item?.data?.id === tempObj?.data?.id
      ));
    if (newData && newData.length === 0) {
      await advancedOETDatabase.readingTestAnswer.add(tempObj);
    } else if (newData && newData.length !== 0) {
      await advancedOETDatabase.readingTestAnswer.update(
        newData[0]?.id,
        tempObj
      );
    }
  };

  const getQuestionsApi = (sectionType, testStatus) => {
    const header = {
      Accept: "application/json",
      "user-id": userInfo?.id,
      "api-token": userInfo?.api_token,
      "test-token": userInfo?.test_token
    };
    let dataList = {};
    if (userInfo?.type === "M") {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        section_type: sectionType,
        part: "all",
        test_status: "P",
        internet_speed: userInfo?.internet_speed
      };
    } else if (userInfo?.type === "P") {
      dataList = {
        user_id: userInfo?.id,
        test_id: userInfo?.testID,
        test_type: userInfo?.type,
        section_type: sectionType,
        part: "all",
        test_status: testStatus,
        internet_speed: userInfo?.internet_speed
      };
    }
    dispatch(
      getAllQuestionsList({
        header,
        dataList,
        onSuccess: (response) => {
          if (response?.data?.statusCode === 200) {
            setDefaultTestStatus(response?.data?.statusCode);

            setLoading(false);

            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...userInfo,
                redirect_url: response?.data?.redirect_url
              })
            );
            localStorage.setItem(
              "pageInfo",
              JSON.stringify({ relodePage: "R" })
            );
            if (sectionType === "writing") {
              advancedOETDatabase.writingTestQuestions.add(
                response?.data?.data
              );
            }
            if (sectionType === "reading") {
              advancedOETDatabase.readingTestQuestions.add(
                response?.data?.data
              );
              if (response?.data?.data?.reading?.student_answers) {
                let allReasumeData = [];
                let r_R_part_1 = JSON.parse(
                  response?.data?.data?.reading?.student_answers?.part_1
                );
                let r_R_part_2 = JSON.parse(
                  response?.data?.data?.reading?.student_answers?.part_2
                );
                let r_R_part_3 = JSON.parse(
                  response?.data?.data?.reading?.student_answers?.part_3
                );

                r_R_part_1.forEach((element) => {
                  allReasumeData.push({
                    ...element,
                    showQuationPart: "Part 1"
                  });
                });
                r_R_part_2.forEach((element) => {
                  allReasumeData.push({
                    ...element,
                    showQuationPart: "Part 2"
                  });
                });
                r_R_part_3.forEach((element) => {
                  allReasumeData.push({
                    ...element,
                    showQuationPart: "Part 3"
                  });
                });

                allReasumeData.forEach(async (item) => {
                  const correctAnswerParts = item.correct_answer.split("&");
                  item.correct_answer = correctAnswerParts[0];

                  if (correctAnswerParts.length === 2) {
                    item.subId = correctAnswerParts[1];
                  }
                  try {
                    await addResumeQueTypeDataToIDB(item);
                  } catch (error) {}
                });
              }

              if (
                userInfo?.testStatus === "P" &&
                response?.data?.last_question &&
                userInfo?.type === "M" &&
                response?.data?.last_question?.split("|")[0] === "R"
              ) {
                setCurrIndex(
                  Number(response?.data?.last_question?.split("|")[1])
                );

                setStatusOfShowsections(
                  response?.data?.last_question?.split("|")[0]
                );
              } else if (userInfo?.type === "P") {
                let lastQuestion = JSON.parse(response?.data?.last_question);
                localStorage.setItem("position", JSON.stringify(lastQuestion));
                lastQuestion?.reading && userInfo?.testStatus === "P"
                  ? setCurrIndex(Number(lastQuestion.reading))
                  : setCurrIndex(1);
              }
            }
            if (response?.data?.statusCode === 599) {
              setShowAlert(true);
              setDefaultTestStatus(response?.data?.statusCode);
              setMessageAlert(defaultMessages.sessionTimeOut);
              setApiType(defaultMessages?.logoutApicall);
            }
          }

          if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            setMessageAlert(defaultMessages.sessionTimeOut);
            setApiType(defaultMessages?.logoutApicall);
          }
        },
        onFailure: (error) => {
          // if (error?.status) {
          setShowAlert(true);
          setDefaultTestStatus(599);
          setMessageAlert(error?.message);
          setApiType(`${defaultMessages?.getQuestionAPI}|${sectionType}|P`);
          // }
        }
      })
    );
  };
  // Manage starting test time
  const handleTestStartTime = () => {
    if (
      userInfo &&
      userInfo?.testStatus === "P" &&
      userInfo?.session_time?.charAt(0) === "S"
    ) {
      let getTestTime = userInfo && userInfo?.session_time.split("_");
      let splitTestTime = getTestTime && getTestTime[1].split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    } else {
      // let getTestTime = contextState.readingSectionTime.timeWithMin;
      // let splitTestTime = getTestTime && getTestTime.split(":");
      // setCurrTestTime({
      //   hours: Math.floor(parseInt(splitTestTime[0])),
      //   minutes: Math.floor(parseInt(splitTestTime[1])),
      //   seconds: Math.floor(parseInt(splitTestTime[2]))
      // });
      const getTimeKey = () => {
        if (currentPart === "PART_A")
          return currentIndex === 0 ? "partA_Ext1" : null;
        else if (currentPart === "PART_B" || currentPart === "PART_C")
          return currentIndex === 0 ? "partB_Ext1" : null;
      };
      if (currentPart && currentIndex !== undefined) {
        const getTestTime = contextState.readingSectionTime[getTimeKey()];
        const splitTestTime = getTestTime && getTestTime.split(":");

        setCurrTestTime({
          hours: Math.floor(parseInt(splitTestTime[0])),
          minutes: Math.floor(parseInt(splitTestTime[1])),
          seconds: Math.floor(parseInt(splitTestTime[2]))
        });
        setLoading(false);
      }
    }
  };

  const handleNext = (status) => {
    setLoading(true);
    if (status === "Next") {
      if (currentPage === 8) {
        setTimeout(() => {
          setModalShow(true);
          setLoading(false);
        }, 1000);
      } else {
        if (currentPart === "PART_B" || currentPart === "PART_C") {
          setTimeout(() => {
            setCurrentPage(currentPage + 1);
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setModalShow(true);
            setLoading(false);
          }, 1000);
        }
      }
    } else if (status === "Previous") {
      setTimeout(() => {
        setCurrentPage(currentPage - 1);
        setLoading(false);
      }, 1000);
    }
  };

  const confirmAction = () => {
    setModalShow(false);
    setTimeout(() => {
      setConfirmModalShow(true);
    }, 1000);
  };

  const nextBtnPopup = () => {
    setModalShow(false);
    setConfirmModalShow(false);
    setSessionTimeOut(false);
    setLoading(true);
    localStorage.removeItem("inputValues");

    setNextAction(true);

    if (currentPart === "PART_B" || currentPart === "PART_C") {
      setTimeout(() => {
        navigate("/oet/IntroductionSection", {
          state: {
            nextsectionType: nextSection,
            sectionType: currentSection,
            sectionText: "Introduction"
          }
        });
        setLoading(false);
      }, 1500);
    } else {
      setResumeTest(true);
      setLoading(false);
    }
  };

  const cancelAction = () => {
    setModalShow(false);
    setConfirmModalShow(false);
  };

  const handleSessionTimeOut = () => {
    nextBtnPopup();
  };

  const getAnswerAndMarksData = (
    quesNum,
    userInput,
    // htmlAnswerArray,
    score_R,
    currPart,
    currQuestion
  ) => {
    setQuesNum(quesNum);
    setUserAnswer(userInput);
    // setHtmlAnswer(htmlAnswerArray);
    setScore(score_R);
    setCurrPart(currPart);
  };

  const storeAnswer = async (tempObj) => {
    const newData = await getAnswerReadingTestDataOfDB.filter(
      (item) => item?.id === tempObj?.id
    );
    try {
      if (newData.length === 0) {
        // If no existing data, add the new answer
        await advancedOETDatabase.readingTestAnswer.add(tempObj);
      } else {
        // If data exists, update the existing entry
        await advancedOETDatabase.readingTestAnswer.update(
          newData[0]?.id,
          tempObj
        );
      }
    } catch (err) {
      console.error("Error storing or updating the answer:", err);
    }
  };

  const handleSideBarClick = (index) => {
    setLoading(true);
    setTimeout(() => {
      setCurrentPage(index + 1);
      setLoading(false);
    }, 1500);
  };

  return (
    <>
      {resumeTest ? (
        <ResumeTest onResume={onResume} setOnResume={setOnResume} />
      ) : (
        <div className="test-viewer-holder">
          <PdfViewer
            isPdfOpen={isPdfOpen}
            setIsPdfOpen={setIsPdfOpen}
            questionsData={
              currentPage > 6 ? questionsData?.PART_C : questionsData?.PART_A
            }
          />
          <div className="test-viewer-main-container container-centre">
            <div className="test-viewer-container">
              <CustomAlert
                title="Confirm Finish Section"
                message={
                  <div style={{ lineHeight: "1.6" }}>
                    You still have time remaining in this{" "}
                    <strong>section</strong>
                    .
                    <br />
                    If you choose to <strong>finish this section</strong>, you
                    will not be able to return to this section.
                    <br />
                    Are you sure you would like to{" "}
                    <strong>finish this section</strong>?
                  </div>
                }
                modalShow={modalShow}
                setModalShow={setModalShow}
                confirmAction={confirmAction}
                cancelAction={cancelAction}
                confirmText="Yes, I would like to finish this section"
                cancelText="No, I would like to return to this section"
                leftIcon={faCheck}
                leftBtn={true}
                rightBtn={true}
                rightIcon={faClose}
              />
              <CustomAlert
                title="Confirm Finish Section"
                message={
                  <div style={{ lineHeight: "1.6" }}>
                    If you select <b>Finish Section</b>, your answers <br />
                    will be submitted and you will not be able to <br />
                    return to this section.
                  </div>
                }
                modalShow={confirmModalShow}
                setModalShow={setConfirmModalShow}
                cancelAction={nextBtnPopup}
                confirmAction={cancelAction}
                confirmText="Cancel"
                cancelText="Finish Section"
                leftIcon={faClose}
                leftBtn={true}
                rightBtn={true}
                rightIcon={faCheck}
              />
              <CustomAlert
                title="Section Time Expired"
                message={
                  <div style={{ lineHeight: "1.6" }}>
                    The time for the previous section of the test has <br />
                    expired. Please continue with the new section you're <br />
                    being directed to.
                  </div>
                }
                modalShow={sessionTimeOut}
                setModalShow={setSessionTimeOut}
                cancelAction={handleSessionTimeOut}
                cancelText="Close"
                rightBtn={true}
                rightIcon={faCheck}
              />
              <AppLoader modalShow={loading} setModalShow={setLoading} />

              <Header
                defaultTime={
                  (currTestTime?.hours < 9
                    ? 0 + "" + currTestTime?.hours
                    : currTestTime?.hours) +
                  ":" +
                  (currTestTime?.minutes < 9
                    ? 0 + "" + currTestTime?.minutes
                    : currTestTime?.minutes) +
                  ":" +
                  (currTestTime?.seconds < 9
                    ? 0 + "" + currTestTime?.seconds
                    : currTestTime?.seconds)
                }
                currTestTime={currTestTime}
                currTesthours={currTestTime?.hours}
                currTestMinutes={currTestTime?.minutes}
                currTestSeconds={currTestTime?.seconds}
                setGetCurrTestTime={setGetCurrTestTime}
                sectionTime={sectionTime}
                handleNextSection={handleNextSection}
                sectionText={sectionText}
              />
              <div className="row content ng-scope">
                <div
                  style={{ display: "inherit", width: "100%" }}
                  className="ng-scope ng-isolate-scope"
                >
                  <div className="white-background question-navigation-holder ng-scope">
                    <section>
                      <nav className="question-nav ng-star-inserted">
                        <div className="nav-button-holder">
                          <section className="ng-star-inserted">
                            {currentPart === "PART_A" ? (
                              <ul className="main-nav-list">
                                {[...Array(1)].map((_, index) => (
                                  <li key={index} className="ng-star-inserted">
                                    <section>
                                      <button
                                        // onClick={() => handleClick(index)}
                                        style={{
                                          backgroundColor: `${theme.subHeader}`,
                                          color: `${theme.textColor}`,
                                          borderColor: `${theme.subHeader}`
                                        }}
                                        className={`btn nav-button theme-bordered item-selector-1intro ${
                                          index + 1 === currentPage
                                            ? "nav-active"
                                            : ""
                                        }`}
                                        aria-current="true"
                                      >
                                        <div>
                                          <span className="ng-star-inserted">
                                            {" "}
                                            {index + 1}{" "}
                                          </span>
                                        </div>
                                      </button>
                                    </section>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <ul className="main-nav-list">
                                {[...Array(8)].map((_, index) => (
                                  <li key={index} className="ng-star-inserted">
                                    <section>
                                      <button
                                        onClick={() =>
                                          handleSideBarClick(index)
                                        }
                                        style={{
                                          backgroundColor: `${theme.subHeader}`,
                                          color: `${theme.textColor}`,
                                          borderColor: `${theme.subHeader}`
                                        }}
                                        className={`btn nav-button theme-bordered item-selector-1intro ${
                                          index + 1 === currentPage
                                            ? "nav-active"
                                            : ""
                                        }`}
                                        aria-current="true"
                                      >
                                        <div>
                                          <span className="ng-star-inserted">
                                            {" "}
                                            {index + 1}{" "}
                                          </span>
                                        </div>
                                      </button>
                                    </section>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </section>
                        </div>
                      </nav>
                    </section>
                  </div>
                  <div
                    className="content-container white-background content-padding"
                    style={{ height: "100%" }}
                  >
                    <div className="content-holder">
                      {currentPart === "PART_A" ? (
                        <PartA
                          currentPart={currentPart}
                          questionsData={questionsData?.PART_A}
                          currentIndex={currentIndex}
                          setCurrentIndex={setCurrentIndex}
                          getAnswerAndMarksData={getAnswerAndMarksData}
                          setIsPdfOpen={setIsPdfOpen}
                          isPdfOpen={isPdfOpen}
                          answersData={answersData}
                        />
                      ) : currentPart === "PART_B" && currentPage <= 6 ? (
                        <PartB
                          setCurrentPage={setCurrentPage}
                          currentPage={currentPage}
                          currentPart={currentPart}
                          questionsData={questionsData?.PART_B}
                          currentIndex={currentIndex}
                          setCurrentIndex={setCurrentIndex}
                          getAnswerAndMarksData={getAnswerAndMarksData}
                          setIsPdfOpen={setIsPdfOpen}
                          isPdfOpen={isPdfOpen}
                          answersData={answersData}
                        />
                      ) : currentPart === "PART_C" && currentPage > 6 ? (
                        <PartC
                          setCurrentPage={setCurrentPage}
                          currentPage={currentPage}
                          currentPart={currentPart}
                          questionsData={questionsData?.PART_C}
                          currentIndex={currentIndex}
                          setCurrentIndex={setCurrentIndex}
                          getAnswerAndMarksData={getAnswerAndMarksData}
                          setIsPdfOpen={setIsPdfOpen}
                          isPdfOpen={isPdfOpen}
                          answersData={answersData}
                        />
                      ) : (
                        <PartA
                          currentPart={currentPart}
                          questionsData={questionsData?.PART_A}
                          currentIndex={currentIndex}
                          setCurrentIndex={setCurrentIndex}
                          getAnswerAndMarksData={getAnswerAndMarksData}
                          setIsPdfOpen={setIsPdfOpen}
                          isPdfOpen={isPdfOpen}
                          answersData={answersData}
                        />
                      )}
                    </div>
                  </div>
                  <div className="content-loading-error-tip ng-isolate-scope"></div>
                </div>
              </div>
              <Footer
                handleNext={handleNext}
                // setCurrentPart={setCurrentPart}
                // currentPart={currentPart}
                sectionTime={sectionTime}
                maxPage={9}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReadingSection;
