import React, { useContext, useEffect, useState } from "react";
import "./style.scss"; // For the SCSS styling
import { AppContext } from "AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faExclamationTriangle,
  faMinusCircle,
  faReply,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { faClockFour } from "@fortawesome/free-regular-svg-icons";
import { formatTime } from "utilities/CommonFunc";
import { useNavigate } from "react-router-dom";
import CustomAlert from "components/Alert";
import AppLoader from "components/CustomLoader";

const TermsDetails = (props) => {
  let navigate = useNavigate();

  const [accepted, setAccepted] = useState(false);
  const { modalOpen, setModalOpen, handleCloseModal } = props;
  const { theme, setTheme } = useContext(AppContext);
  const [timeLeft, setTimeLeft] = useState(120);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [timerAlertShow, setTimerAlertShow] = useState(false);
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    if (modalOpen) {
      setTimeLeft(120);

      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            setTimerAlertShow(true);
            setModalShow(false);
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [modalOpen]);

  const handleConfirm = () => {
    if (!accepted) {
      setShowError(true);
      setErrorMessage(
        "You have not accepted the terms. You must accept the terms before you can take the test."
      );
    } else {
      setLoading(true);
      setTimeout(() => {
        navigate("/oet/InstructionSection");
        setLoading(false);
      }, 1500);
    }
  };

  const handleClose = () => {
    setModalShow(true);
  };

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
    setShowError(false); // Hide error when checkbox is ticked
  };
  const nextClickPopup = () => {
    setModalShow(false);
  };
  const cancelBtnPopup = () => {
    setModalOpen(false);
    setModalShow(false);
    handleCloseModal(false);
  };
  if (!modalOpen) return null;

  return (
    <>
      <div className="terms-details-overlay">
        <CustomAlert
          title="Exit Test"
          message={
            <div style={{ lineHeight: "1.6" }}>
              You have chosen to exit the test.
              <br />
              If you exit, you will not be able to take the test.
              <br />
              Do you want to return to the terms or exit?
            </div>
          }
          icon={faExclamationTriangle}
          modalShow={modalShow}
          setModalShow={setModalShow}
          confirmAction={nextClickPopup}
          cancelAction={cancelBtnPopup}
          confirmText="Return to Terms"
          cancelText="Exit"
          leftIcon={faReply}
          leftBtn={true}
          rightBtn={true}
          rightIcon={faTimes}
        />
        <CustomAlert
          title="Time Expired"
          message={
            <div style={{ lineHeight: "1.6" }}>
              Your time has expired for confirming the terms of this test.
            </div>
          }
          icon={faExclamationTriangle}
          modalShow={timerAlertShow}
          setModalShow={setTimerAlertShow}
          confirmAction={cancelBtnPopup}
          confirmText="OK"
          leftIcon={faCheck}
          leftBtn={true}
        />

        <AppLoader modalShow={loading} setModalShow={setLoading} />

        <div className="terms-details-container">
          <div className="confirm-details-container">
            <div
              className="header-content"
              style={{ backgroundColor: theme.backgroundColor }}
            >
              <p style={{ color: theme.textColor }}>Agree to Terms</p>
              <p style={{ color: theme.textColor }}>
                <FontAwesomeIcon icon={faClockFour} /> {formatTime(timeLeft)}
              </p>
            </div>
            <div className="user-details-container">
              <div className="details-section">
                <div className="details-table">
                  <p className="details-before-content">
                    By ticking this box, you agree to the OET Test Regulations
                    and the Candidate Declaration.
                  </p>
                  <p className="details-strong">Candidate Declaration:</p>
                  <p className="details-after-content">
                    You agree not to disclose or use in any way (other than to
                    take the test) or assist any other person to disclose or use
                    any OET test or sub-test content. If you cheat or assist in
                    any cheating, use any unfair practice, break any of the
                    rules or regulations, or ignore any advice or information,
                    you may be disqualified and your results may not be issued
                    at the sole discretion of CBLA. CBLA also reserves its right
                    to take further disciplinary action against you and to
                    pursue any other remedies permitted by law. If a candidate
                    is suspected of and investigated for malpractice, their
                    personal details and details of the investigation may be
                    passed to a third party where required.
                  </p>
                </div>
                <div className="confirmation-text">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={accepted}
                    onChange={handleCheckboxChange}
                  />
                  <label> I accept these terms.</label>
                </div>
              </div>
              {showError && (
                <div className="keycore-alert-container">
                  <div className="keycore-alert">
                    <div className="keycore-icon">
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        style={{ color: "#C04339" }}
                      />
                    </div>
                    <div className="keycore-text">
                      <label
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: 1.5
                        }}
                      >
                        <pas-translated-text className="translated-text">
                          {errorMessage}
                        </pas-translated-text>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="confirm-details-footer">
                <button
                  onClick={handleClose}
                  style={{ backgroundColor: theme.subHeader }}
                  className="cancel-button"
                >
                  <FontAwesomeIcon icon={faClose} />
                  Exit
                </button>
                <button
                  onClick={handleConfirm}
                  style={{ backgroundColor: theme.subHeader }}
                  className="confirm-button"
                >
                  <FontAwesomeIcon icon={faCheck} />
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsDetails;
