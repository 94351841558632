import {
  faPlayCircle,
  faCheck,
  faClose,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { AppContext } from "AppContext";
import ConfirmDetails from "views/ConfirmDetails";
import "./style.scss";
import { formatTime } from "utilities/CommonFunc";

const ResumeTest = (props) => {
  const { onResume, setOnResume } = props;

  const [timeLeft, setTimeLeft] = useState(600);
  const [showModal, setShowModal] = useState(false);
  const { theme } = useContext(AppContext);
  const [isHoveredYes, setIsHoveredYes] = useState(false);
  const [isHoveredNo, setIsHoveredNo] = useState(false);
  const [isClickNo, setIsClickNo] = useState(false);
  const [isClickYes, setIsClickYes] = useState(false);
  const { contextState } = useContext(AppContext);
  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 1) {
      setOnResume(true);
    }
  }, [timeLeft]);

  // Format time as MM:SS

  const handleResumeClick = () => {
    setShowModal(true);
  };

  const handleYesModal = () => {
    setOnResume(true);
    setIsHoveredYes(false);
    setIsHoveredNo(false);
  };
  const handleNoModal = () => {
    setIsHoveredYes(false);
    setIsHoveredNo(false);
    setIsClickNo(true);
  };
  const handleBackModal = () => {
    setIsHoveredYes(false);
    setIsHoveredNo(false);
    setIsClickNo(false);
  };

  return (
    <>
      <div
        className="resume-modal"
        style={{
          backgroundColor: theme.backgroundColor || "#0099cc",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100vw"
        }}
      >
        {!showModal && (
          <Container>
            <Row className="text-center">
              <Col>
                <Button
                  variant="primary"
                  style={{
                    padding: "7px 20px",
                    fontSize: "20px",
                    borderRadius: "5px",
                    color: "#fff",
                    border: "1px solid",
                    margin: "20px"
                  }}
                  onClick={handleResumeClick}
                >
                  <div>
                    <FontAwesomeIcon icon={faPlayCircle} size="2x" />
                  </div>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingTop: "5px"
                    }}
                  >
                    Resume Test
                  </p>
                </Button>
              </Col>
            </Row>
          </Container>
        )}

        {showModal && (
          <div className="resume-details-overlay">
            <div className="confirm-details-container">
              <div
                className="header-content"
                style={{ backgroundColor: theme.backgroundColor }}
              ></div>
              <div className="user-details-container">
                <div className="details-section">
                  <table className="details-table">
                    <tbody>
                      <tr>
                        <td>Last name:</td>
                        <td>
                          <strong>USER</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>First name:</td>
                        <td>
                          <strong>Demo</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Test Name:</td>
                        <td>
                          <strong>OET Practice Test</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Language:</td>
                        <td>
                          <strong>English</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {isClickNo ? (
                    <p className="confirmation-text">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      {"  "}Please contact the invigilator
                    </p>
                  ) : (
                    <p className="confirmation-text">
                      Are the details above correct?
                    </p>
                  )}
                </div>
                {isClickNo ? (
                  <div className="confirm-details-footer">
                    <button
                      onClick={handleBackModal}
                      style={{
                        backgroundColor: isHoveredNo
                          ? theme.subHeader
                          : "transparent"
                      }}
                      onMouseEnter={() => setIsHoveredNo(true)}
                      onMouseLeave={() => setIsHoveredNo(false)}
                      className="confirm-button"
                    >
                      <FontAwesomeIcon icon={faClose} />
                      Back
                    </button>
                  </div>
                ) : (
                  <div className="confirm-details-footer">
                    <button
                      onClick={handleYesModal}
                      style={{
                        backgroundColor: isHoveredYes
                          ? theme.subHeader
                          : "transparent"
                      }}
                      onMouseEnter={() => setIsHoveredYes(true)}
                      onMouseLeave={() => setIsHoveredYes(false)}
                      className="confirm-button"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      Yes
                    </button>
                    <button
                      className="cancel-button"
                      onClick={handleNoModal}
                      style={{
                        backgroundColor: isHoveredNo
                          ? theme.subHeader
                          : "transparent"
                      }}
                      onMouseEnter={() => setIsHoveredNo(true)}
                      onMouseLeave={() => setIsHoveredNo(false)}
                    >
                      <FontAwesomeIcon icon={faClose} />
                      No
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <p
          style={{
            color: "#fff",
            fontSize: "16px",
            marginTop: showModal ? "450px" : "10px"
          }}
        >
          Scheduled Break Time Remaining: {formatTime(timeLeft)}
        </p>
      </div>
    </>
  );
};

export default ResumeTest;
