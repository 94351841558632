import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import PartA from "components/ListeningSectionQType/PartA";
import PartB from "components/ListeningSectionQType/PartB";
import PartC from "components/ListeningSectionQType/PartC";
import { AppContext } from "AppContext";
import Footer from "components/Footer";
import CustomAlert from "components/Alert";
import advancedOETDatabase from "db";
import { useLiveQuery } from "dexie-react-hooks";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import AppLoader from "components/CustomLoader";
import {
  getAllQuestionsList,
  getAllQuestionsLTemp
} from "../../../redux/action/QuestionAction";
import {
  postAllQuestionsList,
  submitQuestionAnswer,
  submitQuestionAnswerL
} from "../../../redux/action/AnswerAction";
import { defaultMessages } from "utilities/Messages";

let key = "";

const ListeningSection = (props) => {
  const myRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Get Listening Test Answer of IDB
  const getAnswerListeningTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.listeningTestAnswer.toArray(),
    []
  );

  // Get Listening Test Question of IDB
  const getQuestionsListeningTestDataOfDB = useLiveQuery(
    () => advancedOETDatabase.listeningTestQuestions.toArray(),
    []
  );

  // Context state variable
  const { contextState, handleDefaultValues } = useContext(AppContext);
  const [newResumeArry, setNewResumeArry] = useState([]);

  const sectionTime = "Section";
  const [statusOfShowsections, setStatusOfShowsections] = React.useState("");
  const [userAnswer, setUserAnswer] = useState("");
  const [quesNum, setQuesNum] = useState();
  const [questionsData, setQuestionsData] = useState([]);
  const [answersData, setAnswersData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currIndex, setCurrIndex] = useState(currentIndex + 1);
  const [loading, setLoading] = useState(false);
  const [currentSection, setcurrentSection] = useState("ListeningSection");
  const [currSection, setcurrSection] = useState("Listening");
  const [nextSection, setnextSection] = useState("ReadingSection");
  const [currentPart, setCurrentPart] = useState("PART_A");
  const [currPart, setCurrPart] = useState("PART_A");
  const [currQueType, setcurrQueType] = useState("");
  const [score, setScore] = useState(0);
  const { theme } = useContext(AppContext);
  const [sectionText, setSectionText] = useState(
    currSection + " PartA - " + currIndex
  );

  // For Handle session alert modal
  const [apiType, setApiType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultTestStatus, setDefaultTestStatus] = useState("");

  // Speaking section test and Question time
  const [getCurrTestTime, setGetCurrTestTime] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [confirmSaveExitModalShow, setConfirmSaveExitModalShow] =
    useState(false);
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [nextAction, setNextAction] = useState(false);
  const [saveExitAction, setSaveExitAction] = useState(false);

  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  //for reloading
  useEffect(() => {
    const storedPosition = localStorage.getItem("position");

    if (storedPosition) {
      const { typeOfPartsIndex } = JSON.parse(storedPosition);

      if (typeOfPartsIndex?.startsWith("L-")) {
        const [part, index] = typeOfPartsIndex.split("-").slice(1);
        if (!isNaN(index)) {
          setCurrentPart(part);
          setCurrentIndex(parseInt(index, 10));
        }
      }
    } else {
      setCurrentPart("PART_A");
      setCurrentIndex(0);
    }
  }, []);

  useEffect(() => {
    if (statusOfShowsections !== "L") {
      if (statusOfShowsections === "R") {
        navigate("/ielts/Instructions", {
          state: {
            sectionType: "Reading"
          }
        });
      } else if (statusOfShowsections === "W") {
        navigate("/ielts/Instructions", {
          state: { sectionType: "Writing" }
        });
      }
    }
  }, [statusOfShowsections]);

  useEffect(() => {
    if (currentIndex !== undefined && currentPart !== undefined) {
      const formattedPosition = `L-${currentPart}-${currentIndex}`;
      localStorage.setItem(
        "position",
        JSON.stringify({
          typeOfPartsIndex: formattedPosition
        })
      );
    }
  });

  //end for reloading
  useEffect(() => {
    if (nextAction) {
      handleNextAction("loader");
      setNextAction(false);
    }
  }, [nextAction]);

  useEffect(() => {
    if (saveExitAction) {
      handleNextAction("save_and_exit");
      setSaveExitAction(false);
    }
  }, [saveExitAction]);

  const handleNextAction = async (loader) => {
    setShowAlert(false);
    let PART_A = [];
    let PART_B = [];
    let PART_C = [];
    let totalScore = 0;
    let band = 0;
    let l_part_A = 0;
    let l_part_B = 0;
    let l_part_C = 0;
    let L_PA1 = [];
    let L_PA2 = [];
    let L_PB = [];
    let L_PC1 = [];
    let L_PC2 = [];
    let l_PA1 = 0;
    let l_PA2 = 0;
    let l_PB = 0;
    let l_PC1 = 0;
    let l_PC2 = 0;

    (await getAnswerListeningTestDataOfDB) &&
      getAnswerListeningTestDataOfDB.filter((element, index) => {
        totalScore += element?.score;

        if (element?.currPart === "PART_A") {
          l_part_A += element?.score;
          if (element?.queType === "L_PA1") {
            l_PA1 += element?.score;
            L_PA1.push({
              id: element?.id,
              user_answer: element?.userAnswer,
              score: element?.score,
              question_type: element?.queType,
              currPart: element?.currPart
            });
          }
          if (element?.queType === "L_PA2") {
            l_PA2 += element?.score;
            L_PA2.push({
              id: element?.id,
              user_answer: element?.userAnswer,
              score: element?.score,
              question_type: element?.queType,
              currPart: element?.currPart
            });
          }
        }
        if (element?.currPart === "PART_B") {
          l_part_B += element?.score;
          if (element?.queType === "L_PB") {
            l_PB += element?.score;
            L_PB.push({
              id: element?.id,
              user_answer: element?.userAnswer,
              score: element?.score,
              question_type: element?.queType,
              currPart: element?.currPart
            });
          }
        }
        if (element?.currPart === "PART_C") {
          l_part_C += element?.score;
          if (element?.queType === "L_PC1") {
            l_PC1 += element?.score;
            L_PC1.push({
              id: element?.id,
              user_answer: element?.userAnswer,
              score: element?.score,
              question_type: element?.queType,
              currPart: element?.currPart
            });
          }
          if (element?.queType === "L_PC2") {
            l_PC2 += element?.score;
            L_PC2.push({
              id: element?.id,
              user_answer: element?.userAnswer,
              score: element?.score,
              question_type: element?.queType,
              currPart: element?.currPart
            });
          }
        }
      });
    PART_A.push({
      L_PA1,
      L_PA2
    });

    PART_B.push({ L_PB });
    PART_C.push({ L_PC1, L_PC2 });

    let newObject = {};
    // if (userInfo?.type === "M") {
    newObject = {
      // last_question: `L|${selectedIndex}`,
      listening: {
        status: loader === "save_and_exit" ? "P" : "C",
        module_time: currTestTime,
        // answer_data: {
        //   PART_A,
        //   PART_B,
        //   PART_C
        // },
        answer_data:
          currentPart === "PART_C"
            ? { PART_C }
            : currentPart === "PART_B"
            ? { PART_B }
            : { PART_A },
        score_data: {
          band: band,
          listening_score: totalScore,
          l_part_A: l_part_A,
          l_part_B: l_part_B,
          l_part_C: l_part_C,
          l_PA1: l_PA1,
          l_PA2: l_PA2,
          l_PB: l_PB,
          l_PC1: l_PC1,
          l_PC2: l_PC2
        }
      }
    };
    // }
    // } else {
    //   newObject = {
    //     last_question: { ...positionOfquestions, listening: selectedIndex },
    //     listening: {
    //       audio_time: currentTime.toFixed(2),
    //       status: loader === "save_and_exit" ? "P" : "C",
    //       listening_module_time: userInfo?.session_time,
    //       answer_data: {
    //         PART_A,
    //         PART_B,
    //         PART_C,
    //         PART_D
    //       },
    //       score_data: {
    //         band: band,
    //         correct_answers: totalScore
    //       }
    //     }
    //   };
    // }

    sectionEndApi(newObject, loader);
  };

  const sectionEndApi = (data, loader) => {
    if (loader === "loader" || loader === "save_and_exit") {
      setLoading(true);
    }

    const header = {
      Accept: "application/json",
      "user-id": userInfo?.id,
      "api-token": userInfo?.api_token,
      "test-token": userInfo?.test_token
    };

    const dataList = {
      user_id: userInfo?.id,
      test_id: userInfo?.testID,
      test_type: userInfo?.type,
      part: "all",
      data: data,
      test_status:
        loader === "loader" || loader === "save_and_exit" ? "P" : "C",
      section_type: "listening",
      resume: loader === "save_and_exit" ? true : false,
      is_continue: loader === "save_and_exit" ? false : true
    };
    dispatch(
      submitQuestionAnswer({
        header,
        dataList,
        onSuccess: (response) => {
          console.log(response, "res");
          if (response?.data?.statusCode === 200) {
            if (loader === "loader" && userInfo?.type === "M") {
              
            } else if (loader === "save_and_exit" || userInfo?.type === "P") {
              if (userInfo?.redirect_url) {
                window.location.replace(userInfo?.redirect_url);
              } else {
                window.location.replace(
                  `${window.origin + "/Student/dashboard"}`
                );
              }
            }
          }

          if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            // setMessageAlert(defaultMessages.sessionTimeOut);
            // setApiType(defaultMessages?.logoutApicall);
          }
        },
        onFailure: (error) => {
          console.log(error);
          setShowAlert(true);
          setDefaultTestStatus(599);
          // setMessageAlert(error?.message);
          // setApiType(defaultMessages?.submitAnswerAPI);
        }
      })
    );
  };

  useEffect(() => {
    if (currentPart === "PART_B") {
      setSectionText(currSection + " Part B");
    } else if (currentPart === "PART_C") {
      setSectionText(currSection + " Part C - " + currIndex);
    } else {
      setSectionText(currSection + " Part A - " + currIndex);
    }
  }, [currentPart, currentIndex]);

  //for timing divided in to part wise.
  useEffect(() => {
    handleTestStartTime();
  }, [currentPart, currentIndex]);

  // useEffect(() => {
  //   const listeningTestDataLength =
  //     getQuestionsListeningTestDataOfDB?.length || 0;

  //   if (getQuestionsListeningTestDataOfDB && listeningTestDataLength === 0) {
  //     setLoading(true);
  //     getQuestionsApi("listening", userInfo?.testStatus);
  //   }
  // }, [getQuestionsListeningTestDataOfDB]);

  useEffect(() => {
    const listeningTestDataLength =
      getQuestionsListeningTestDataOfDB?.length || 0;

    if (getQuestionsListeningTestDataOfDB && listeningTestDataLength === 0) {
      setLoading(true);
      getQuestionsApi("listening", userInfo?.testStatus);
    }
  }, [getQuestionsListeningTestDataOfDB]);

  useEffect(() => {
    // if (userInfo?.type === "P") {
    if (getQuestionsListeningTestDataOfDB !== undefined) {
      let listening = getQuestionsListeningTestDataOfDB.map((item) => {
        // if (item?.data?.length === 2) {
        return item;
        // }
      });
      console.log(listening?.length);

      if (listening?.length !== 0) {
        if (listening[0]) {
          setQuestionsData(listening[0]?.listeningAllQuestions);
          setAnswersData(listening[0]?.listeningAllAnswers);
          setLoading(false);
        }
      }
    }
    // }
  }, [getQuestionsListeningTestDataOfDB]);

  useEffect(() => {
    if (userAnswer !== undefined && userAnswer !== "") {
      const tempObj = {
        id: quesNum,
        userAnswer: userAnswer,
        currPart: currPart,
        score: score,
        queType: currQueType
      };
      storeAnswer(tempObj);
    }
  }, [userAnswer]);

  const handleNextSection = () => {
    setSessionTimeOut(true);
  };

  const addResumeQueTypeDataToIDB = async (tempObj) => {
    const newData =
      (await getAnswerListeningTestDataOfDB.length) === 0 &&
      (await getAnswerListeningTestDataOfDB.filter(
        (item) => item?.data?.id === tempObj?.data?.id
      ));
    if (newData && newData.length === 0) {
      await advancedOETDatabase.listeningTestAnswer.add(tempObj);
    } else if (newData && newData.length !== 0) {
      await advancedOETDatabase.listeningTestAnswer.update(
        newData[0]?.id,
        tempObj
      );
    }
  };

  const getQuestionsApi = (sectionType, testStatus) => {
    const header = {
      Accept: "application/json",
      "user-id": userInfo?.id,
      "api-token": userInfo?.api_token,
      "test-token": userInfo?.test_token
    };
    const dataList = {
      user_id: userInfo?.id,
      test_id: userInfo?.testID,
      test_type: userInfo?.type,
      section_type: sectionType,
      part: "all",
      test_status: testStatus,
      internet_speed: userInfo?.internet_speed
    };

    localStorage.removeItem("flagArryForCheking");
    dispatch(
      getAllQuestionsList({
        header,
        dataList,
        onSuccess: (response) => {
          console.log(response);
          if (response?.data?.statusCode === 200) {
            advancedOETDatabase.listeningTestQuestions.add(
              response?.data?.data
            );
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...userInfo,
                redirect_url: response?.data?.redirect_url
              })
            );
            setLoading(false);
          }
        }
      })
    );
    dispatch(
      getAllQuestionsList({
        header,
        dataList,
        onSuccess: (response) => {
          if (response?.data?.statusCode === 200) {
            setDefaultTestStatus(response?.data?.statusCode);

            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...userInfo,
                redirect_url: response?.data?.redirect_url
              })
            );
            if (sectionType === "listening") {
              advancedOETDatabase.listeningTestQuestions.add(
                response?.data?.data
              );
              if (
                userInfo?.type === "M" &&
                response?.data?.audio_time &&
                response?.data?.last_question?.split("|")[0] === "L"
              ) {
                let timeaudio = response?.data?.audio_time;
                setTimeout(() => {
                  myRef.current.currentTime = Number(timeaudio);
                }, 1000);
              } else if (
                userInfo?.type === "P" &&
                response?.data?.audio_time &&
                response?.data?.last_question
              ) {
                let timeaudio = response?.data?.audio_time;
                setTimeout(() => {
                  myRef.current.currentTime = Number(timeaudio);
                }, 1000);
              }

              if (response?.data?.data?.listening?.student_answers) {
                let allReasumeData = [
                  ...JSON.parse(
                    response?.data?.data?.listening?.student_answers?.part_1
                  ),
                  ...JSON.parse(
                    response?.data?.data?.listening?.student_answers?.part_2
                  ),
                  ...JSON.parse(
                    response?.data?.data?.listening?.student_answers?.part_3
                  ),
                  ...JSON.parse(
                    response?.data?.data?.listening?.student_answers?.part_4
                  )
                ];
                setNewResumeArry(allReasumeData);

                allReasumeData.forEach(async (item) => {
                  const correctAnswerParts = item.correct_answer.split("&");
                  item.correct_answer = correctAnswerParts[0];

                  if (correctAnswerParts.length === 2) {
                    item.subId = correctAnswerParts[1];
                  }

                  try {
                    await addResumeQueTypeDataToIDB(item);
                    // await showAnswer("not_call_API");
                  } catch (error) {}
                });
              }

              if (
                userInfo?.testStatus === "P" &&
                response?.data?.last_question &&
                userInfo?.type === "M"
              ) {
                setCurrentIndex(
                  Number(response?.data?.last_question?.split("|")[1])
                );
                setStatusOfShowsections(
                  response?.data?.last_question?.split("|")[0]
                );
              } else if (
                response?.data?.last_question &&
                userInfo?.type === "P"
              ) {
                let lastQuestion = JSON.parse(response?.data?.last_question);
                localStorage.setItem("position", JSON.stringify(lastQuestion));

                lastQuestion.listening && userInfo?.testStatus === "P"
                  ? setCurrentIndex(Number(lastQuestion.listening))
                  : setCurrentIndex(1);
              }
            }
            if (sectionType === "reading") {
              advancedOETDatabase.readingTestQuestions.add(
                response?.data?.data
              );
            }
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }
          if (response?.data?.statusCode === 599) {
            setShowAlert(true);
            setDefaultTestStatus(response?.data?.statusCode);
            setMessageAlert(defaultMessages?.sessionTimeOut);
            setApiType(defaultMessages?.logoutApicall);
          }
        },
        onFailure: (error) => {
          // if (error?.status) {
          setShowAlert(true);
          setDefaultTestStatus(error?.status);
          setMessageAlert(error.message);
          setApiType(
            `${defaultMessages?.getQuestionAPI}|${sectionType}|${testStatus}`
          );
          // }
        }
      })
    );
  };
  // Manage starting test time
  const handleTestStartTime = () => {
    if (
      userInfo &&
      userInfo?.testStatus === "P" &&
      userInfo?.session_time?.charAt(0) === "S"
    ) {
      let getTestTime = userInfo && userInfo?.session_time.split("_");
      let splitTestTime = getTestTime && getTestTime[1].split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    } else {
      // let getTestTime = contextState.listeningSectionTime.timeWithMin;
      // let splitTestTime = getTestTime && getTestTime.split(":");
      // setCurrTestTime({
      //   hours: Math.floor(parseInt(splitTestTime[0])),
      //   minutes: Math.floor(parseInt(splitTestTime[1])),
      //   seconds: Math.floor(parseInt(splitTestTime[2]))
      // });
      const getTimeKey = () => {
        if (currentPart === "PART_A")
          return currentIndex === 0 ? "partA_Ext1" : "partA_Ext2";
        if (currentPart === "PART_B") return "partB_Ext1";
        if (currentPart === "PART_C")
          return currentIndex === 0 ? "partC_Ext1" : "partC_Ext2";
      };
      if (currentPart && currentIndex !== undefined) {
        const getTestTime = contextState.listeningSectionTime[getTimeKey()];
        const splitTestTime = getTestTime && getTestTime.split(":");

        setCurrTestTime({
          hours: Math.floor(parseInt(splitTestTime[0])),
          minutes: Math.floor(parseInt(splitTestTime[1])),
          seconds: Math.floor(parseInt(splitTestTime[2]))
        });
        setLoading(false);
      }
    }
  };

  const handleNext = (status) => {
    setModalShow(true);
  };

  const handleSaveExitSection = () => {
    key = "saveExit";
    setModalShow(true);
  };

  const confirmAction = () => {
    setModalShow(false);
    if (key === "saveExit") {
      setTimeout(() => {
        setConfirmSaveExitModalShow(true);
      }, 500);
      key = "";
    } else {
      setTimeout(() => {
        setConfirmModalShow(true);
      }, 500);
    }
  };

  const SaveExitBtnPopup = () => {
    setNextAction(true);
    setModalShow(false);
    setConfirmSaveExitModalShow(false);
    setSessionTimeOut(false);
  };
  const nextBtnPopup = () => {
    setNextAction(true);

    setModalShow(false);
    setConfirmModalShow(false);
    setSessionTimeOut(false);

    const currentPartData = questionsData[currentPart];
    setLoading(true);
    localStorage.removeItem("inputValues");

    if (currentIndex < currentPartData?.length - 1) {
      setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 1500);
    } else {
      if (currentPart === "PART_A") {
        setTimeout(() => {
          setCurrentPart("PART_B");
          setCurrentIndex(0);
          setLoading(false);
        }, 1500);
      } else if (currentPart === "PART_B") {
        setTimeout(() => {
          setCurrentPart("PART_C");
          setCurrentIndex(0);
          setLoading(false);
        }, 1500);
      } else if (currentPart === "PART_C") {
        setTimeout(() => {
          navigate("/oet/IntroductionSection", {
            state: {
              nextsectionType: nextSection,
              sectionType: currentSection,
              sectionText: "Introduction"
            }
          });
          setLoading(false);
        }, 1500);
      }
    }
  };

  const cancelAction = () => {
    setModalShow(false);
    setConfirmModalShow(false);
  };

  const handleSessionTimeOut = () => {
    nextBtnPopup();
  };

  const getAnswerAndMarksData = (
    quesNum,
    userInput,
    // htmlAnswerArray,
    score_L,
    currPart,
    currQuestionType
  ) => {
    setQuesNum(quesNum);
    setUserAnswer(userInput);
    // setHtmlAnswer(htmlAnswerArray);
    setScore(score_L);
    setCurrPart(currPart);
    setcurrQueType(currQuestionType);
  };

  const storeAnswer = async (tempObj) => {
    const newData = await getAnswerListeningTestDataOfDB.filter(
      (item) => item?.id === tempObj?.id
    );

    try {
      if (newData.length === 0) {
        // If no existing data, add the new answer
        await advancedOETDatabase.listeningTestAnswer.add(tempObj);
      } else {
        // If data exists, update the existing entry
        await advancedOETDatabase.listeningTestAnswer.update(
          newData[0]?.id,
          tempObj
        );
      }
    } catch (err) {
      console.error("Error storing or updating the answer:", err);
    }
  };

  return (
    <>
      <div className="test-viewer-holder">
        <div className="test-viewer-main-container container-centre">
          <div className="test-viewer-container">
            <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  You still have time remaining in this <strong>section</strong>
                  .
                  <br />
                  If you choose to <strong>finish this section</strong>, you
                  will not be able to return to this section.
                  <br />
                  Are you sure you would like to{" "}
                  <strong>finish this section</strong>?
                </div>
              }
              modalShow={modalShow}
              setModalShow={setModalShow}
              confirmAction={confirmAction}
              cancelAction={cancelAction}
              confirmText="Yes, I would like to finish this section"
              cancelText="No, I would like to return to this section"
              leftIcon={faCheck}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faClose}
            />
            <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  If you select <b>Finish Section</b>, your answers <br />
                  will be submitted and you will not be able to <br />
                  return to this section.
                </div>
              }
              modalShow={confirmSaveExitModalShow}
              setModalShow={setConfirmSaveExitModalShow}
              cancelAction={SaveExitBtnPopup}
              confirmAction={cancelAction}
              confirmText="Cancel"
              cancelText="Finish Section"
              leftIcon={faClose}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faCheck}
            />
            <CustomAlert
              title="Confirm Finish Section"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  If you select <b>Finish Section</b>, your answers <br />
                  will be submitted and you will not be able to <br />
                  return to this section.
                </div>
              }
              modalShow={confirmModalShow}
              setModalShow={setConfirmModalShow}
              cancelAction={nextBtnPopup}
              confirmAction={cancelAction}
              confirmText="Cancel"
              cancelText="Finish Section"
              leftIcon={faClose}
              leftBtn={true}
              rightBtn={true}
              rightIcon={faCheck}
            />
            <CustomAlert
              title="Section Time Expired"
              message={
                <div style={{ lineHeight: "1.6" }}>
                  The time for the previous section of the test has <br />
                  expired. Please continue with the new section you're <br />
                  being directed to.
                </div>
              }
              modalShow={sessionTimeOut}
              setModalShow={setSessionTimeOut}
              cancelAction={handleSessionTimeOut}
              cancelText="Close"
              rightBtn={true}
              rightIcon={faCheck}
            />
            <AppLoader modalShow={loading} setModalShow={setLoading} />

            <Header
              defaultTime={
                (currTestTime?.hours < 9
                  ? 0 + "" + currTestTime?.hours
                  : currTestTime?.hours) +
                ":" +
                (currTestTime?.minutes < 9
                  ? 0 + "" + currTestTime?.minutes
                  : currTestTime?.minutes) +
                ":" +
                (currTestTime?.seconds < 9
                  ? 0 + "" + currTestTime?.seconds
                  : currTestTime?.seconds)
              }
              currTestTime={currTestTime}
              currTesthours={currTestTime?.hours}
              currTestMinutes={currTestTime?.minutes}
              currTestSeconds={currTestTime?.seconds}
              setGetCurrTestTime={setGetCurrTestTime}
              sectionTime={sectionTime}
              handleNextSection={handleNextSection}
              sectionText={sectionText}
              handleSaveExitSection={handleSaveExitSection}
            />
            <div className="row content ng-scope">
              <div
                style={{ display: "inherit", width: "100%" }}
                className="ng-scope ng-isolate-scope"
              >
                <div className="white-background question-navigate-holder ng-scope">
                  <section>
                    <nav className="question-nav ng-star-inserted">
                      <div className="nav-button-holder">
                        <section className="ng-star-inserted">
                          <ul className="main-nav-list">
                            {[...Array(1)].map((_, index) => (
                              <li key={index} className="ng-star-inserted">
                                <section>
                                  <button
                                    // onClick={() => handleClick(index)}
                                    style={{
                                      backgroundColor: `${theme.subHeader}`,
                                      color: `${theme.textColor}`,
                                      borderColor: `${theme.subHeader}`
                                    }}
                                    className={`btn nav-button theme-bordered item-selector-1intro nav-active`}
                                    aria-current="true"
                                  >
                                    <div>
                                      <span className="ng-star-inserted">
                                        {" "}
                                        {index + 1}{" "}
                                      </span>
                                    </div>
                                  </button>
                                </section>
                              </li>
                            ))}
                          </ul>
                        </section>
                      </div>
                    </nav>
                  </section>
                </div>
                <div
                  className="content-container white-background content-padding"
                  style={{ height: "100%" }}
                >
                  <div className="content-holder">
                    {currentPart === "PART_A" ? (
                      <PartA
                        currentPart={currentPart}
                        questionsData={questionsData?.PART_A}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        answersData={answersData}
                      />
                    ) : currentPart === "PART_B" ? (
                      <PartB
                        currentPart={currentPart}
                        questionsData={questionsData?.PART_B}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        answersData={answersData}
                      />
                    ) : currentPart === "PART_C" ? (
                      <PartC
                        currentPart={currentPart}
                        questionsData={questionsData?.PART_C}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        answersData={answersData}
                      />
                    ) : (
                      <PartA
                        currentPart={currentPart}
                        questionsData={questionsData?.PART_A}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        getAnswerAndMarksData={getAnswerAndMarksData}
                        answersData={answersData}
                      />
                    )}
                  </div>
                </div>
                <div className="content-loading-error-tip ng-isolate-scope"></div>
              </div>
            </div>
            <Footer
              handleNext={handleNext}
              // setCurrentPart={setCurrentPart}
              // currentPart={currentPart}
              sectionTime={sectionTime}
              maxPage={2}
              currentPage={1}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListeningSection;
