import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import { AppContext } from "AppContext";
import Footer from "components/Footer";
import Introduction from "components/IntroductionSectionType";

const InstructionSection = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const typeOfPartsIndex = JSON.parse(localStorage.getItem("position"));

  const section = "";
  const sectionTime = "Introduction";
  const [sectionText, setSectionText] = useState("Intoduction");

  const [currentPage, setCurrentPage] = useState(1);
  const { theme } = useContext(AppContext);

  const maxPage = 10;
  // LocalStorage variable

  // Props variable
  const {} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Context state variable
  const { contextState } = useContext(AppContext);

  // Speaking section test and Question time
  // const [currTestTime, setCurrTestTime] = useState("");
  const [getCurrTestTime, setGetCurrTestTime] = useState();
  const [currTestTime, setCurrTestTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    localStorage.setItem(
      "position",
      JSON.stringify({
        typeOfPartsIndex: `InstructionSection`
      })
    );
  });

  useEffect(() => {
    handleTestStartTime();
  }, []);
  // Manage starting test time
  const handleTestStartTime = () => {
    if (
      userInfo &&
      userInfo?.testStatus === "P" &&
      userInfo?.session_time?.charAt(0) === "S"
    ) {
      let getTestTime = userInfo && userInfo?.session_time.split("_");
      let splitTestTime = getTestTime && getTestTime[1].split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    } else {
      let getTestTime = contextState.instructionSectionTime.timeWithMin;
      let splitTestTime = getTestTime && getTestTime.split(":");
      setCurrTestTime({
        hours: Math.floor(parseInt(splitTestTime[0])),
        minutes: Math.floor(parseInt(splitTestTime[1])),
        seconds: Math.floor(parseInt(splitTestTime[2]))
      });
    }
  };

  const handleClick = (index) => {
    setCurrentPage(index + 1);
  };

  const handleNext = (status) => {
    if (status === "Next" && currentPage < maxPage + 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (status === "Previous" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else {
      navigate("/oet/IntroductionSection", {
        state: {
          sectionType: sectionTime,
          sectionText: "Introduction"
        }
      });
    }
  };

  return (
    <>
      <div className="test-viewer-holder">
        <div className="test-viewer-main-container container-centre">
          <div className="test-viewer-container">
            <Header
              defaultTime={
                (currTestTime?.hours < 9
                  ? 0 + "" + currTestTime?.hours
                  : currTestTime?.hours) +
                ":" +
                (currTestTime?.minutes < 9
                  ? 0 + "" + currTestTime?.minutes
                  : currTestTime?.minutes) +
                ":" +
                (currTestTime?.seconds < 9
                  ? 0 + "" + currTestTime?.seconds
                  : currTestTime?.seconds)
              }
              currTestTime={currTestTime}
              currTesthours={currTestTime?.hours}
              currTestMinutes={currTestTime?.minutes}
              currTestSeconds={currTestTime?.seconds}
              setGetCurrTestTime={setGetCurrTestTime}
              handleNextSection={handleNext}
              sectionTime={sectionTime}
              sectionText={sectionText}
            />
            <div className="row content ng-scope">
              <div
                style={{ display: "inherit", width: "100%" }}
                className="ng-scope ng-isolate-scope"
              >
                <div className="white-background question-navigation-holder ng-scope">
                  <section>
                    <nav className="question-nav ng-star-inserted">
                      <div className="nav-button-holder">
                        <section className="ng-star-inserted">
                          <ul className="main-nav-list">
                            {[...Array(10)].map((_, index) => (
                              <li key={index} className="ng-star-inserted">
                                <section>
                                  <button
                                    onClick={() => handleClick(index)}
                                    style={{
                                      backgroundColor: `${theme.subHeader}`,
                                      color: `${theme.textColor}`,
                                      borderColor: `${theme.subHeader}`
                                    }}
                                    className={`btn nav-button theme-bordered item-selector-1intro ${
                                      index + 1 === currentPage
                                        ? "nav-active"
                                        : ""
                                    }`}
                                    aria-current="true"
                                  >
                                    <div>
                                      <span className="ng-star-inserted">
                                        {" "}
                                        {index + 1}{" "}
                                      </span>
                                    </div>
                                  </button>
                                </section>
                              </li>
                            ))}
                          </ul>
                        </section>
                      </div>
                    </nav>
                  </section>
                </div>
                <div
                  className="content-container white-background content-padding"
                  style={{ height: "100%" }}
                >
                  <div className="content-holder">
                    <Introduction currentPage={currentPage} />
                  </div>
                </div>
                <div className="content-loading-error-tip ng-isolate-scope"></div>
              </div>
            </div>

            <Footer
              handleNext={handleNext}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              sectionTime={sectionTime}
              maxPage={10}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructionSection;
