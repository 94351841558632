import React, { useState, useEffect } from "react";
import "./style.scss";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const ProgressBar = (props) => {
  const {
    key,
    countDownSize,
    strokeWidth,
    countDownColor,
    setCounter,
    initialCounter,
    image,
    handleComplete,
    setIsActive,
    isActive,
    onTimeUpdate,
    theme
  } = props;

  const [timerForRecord, setTimerForRecord] = useState(60);

  const renderTimeImage = ({ remainingTime }) => {
    setTimerForRecord(remainingTime);

    if (onTimeUpdate) {
      onTimeUpdate(remainingTime);
    }

    if (remainingTime === 0 && !image) {
      return <div className="timer">Time's Up!...</div>;
    }

    return (
      <div className="timer">
        {image ? (
          <svg
            alt="Record"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill="#04ABA2"
            style={{ width: "20px", height: "20px" }}
          >
            <path d="M192 0C139 0 96 43 96 96V256c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V216z" />
          </svg>
        ) : (
          <>
            <div className="value" style={{ color: theme.subHeader }}>
              {remainingTime}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="progressbar-container">
      <div
        id="pomodoro-timer"
        className="Progressbar"
        style={{
          position: "absolute",
          marginTop: "8rem",
          zIndex: "1",
          borderRadius: "8px"
        }}
      >
        <CountdownCircleTimer
          key={key}
          onComplete={handleComplete}
          isPlaying={isActive}
          duration={initialCounter}
          colors={countDownColor}
          size={countDownSize}
          strokeWidth={strokeWidth}
        >
          {renderTimeImage}
        </CountdownCircleTimer>
      </div>
      {image && (
        <div
          style={{ position: "absolute", marginTop: "14.5rem", zIndex: "2" }}
        >
          00:{timerForRecord}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
