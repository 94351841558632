import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Routing from "./Routing";
import { useLocation, useNavigate } from "react-router-dom";

import advancedOETDatabase from "./db";
import CustomAlert from "./components/Alert";
import AppLoader from "./components/CustomLoader";
import { IdleTimer } from "./utilities/IdleTimer";
import { defaultMessages } from "./utilities/Messages";
import {
  checkInstituteActiveStudent,
  submitSpeakingModuleScore
} from "./redux/action/AnswerAction";
import { useDispatch } from "react-redux";
import { doNotShowInspeact, doNotShowInspect } from "utilities/CommonFunc";

let queryString = "";

let isLoggedIn = null;

// const App = () => {
//   const typeOfPartsIndex = JSON.parse(localStorage.getItem("position"));
//   let navigate = useNavigate();
//   let location = useLocation();

//   // Get redirection URL Query String
//   let getURLQueryString = new URLSearchParams(window.location.search);
//   for (const entry of getURLQueryString.entries()) {
//     queryString = entry[0];
//   }

//   let searchParams = new URLSearchParams(queryString);
//   // For clear LocalStorage and IndexDB data
// const handleClearLocalStorageData = () => {
//   if (searchParams?.get("reload_Page") !== "R") {
//     localStorage.clear();
//     sessionStorage.clear();
//     advancedOETDatabase.speakingTestAnswer.clear();
//     advancedOETDatabase.writingTestAnswer.clear();
//     advancedOETDatabase.readingTestAnswer.clear();
//     advancedOETDatabase.listeningTestAnswer.clear();
//     advancedOETDatabase.customTestAnswer.clear();
//     advancedOETDatabase.speakingTestQuestions.clear();
//     advancedOETDatabase.writingTestQuestions.clear();
//     advancedOETDatabase.readingTestQuestions.clear();
//     advancedOETDatabase.listeningTestQuestions.clear();
//     advancedOETDatabase.customTestQuestions.clear();
//     advancedOETDatabase.retryQueTypeData.clear();
//   }
// };

//   // Get userInfo of local storage
//   isLoggedIn = localStorage.getItem("isLoggedIn");
//   let userInfo = JSON.parse(localStorage.getItem("userInfo"));
//   const stateFormageRelodeVariable = JSON.parse(localStorage.getItem("reload"));
//   const getCurrTestTimeForlocal = JSON.parse(
//     localStorage.getItem("currentTime")
//   );
//   const questionData = JSON.parse(localStorage.getItem("questionData"));
//   // For Handle alert modal
//   const [loading, setLoading] = useState(false);
//   const [loading1, setLoading1] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const handleCloseAlert = () => {
//     setShowAlert(false);
//   };

//   const dispatch = useDispatch();
//   //s_ra,s_rs,s_di,s_rtl,s_asq
//   //w_swt,w_es
//   //r_rwfb,r_mcma,r_rop,r_fb,r_mcsa
//   //l_sst,l_mcma,l_fb,l_hcs,l_mcsa,l_smw,l_hiw,l_wfd

//   const redirectHandelFuncations = () => {
//     if (
//       performance.navigation.type === performance.navigation.TYPE_RELOAD &&
//       location?.pathname !== "/oet/HeadphoneCheck" &&
//       location?.pathname !== "/oet/MicrophoneCheck" &&
//       location?.pathname !== "/thank-you"
//     ) {
//       let sectionType = localStorage.getItem("sectionType");
//       if (userInfo?.type === "P") {
//         localStorage.setItem("reload", JSON.stringify({ reloadStatus: "R" }));
//         localStorage.setItem("isLoggedIn", JSON.stringify(true));
//         let uri = `id=${userInfo?.id}&type=${userInfo?.type}&userType=${userInfo?.userType}
//         &token=${userInfo?.api_token}&scoreStatus=${userInfo?.scoreStatus}&testID=${userInfo?.testID}
//         &testStatus=P&sectionType=${sectionType[0]}&session_time=${getCurrTestTimeForlocal?.getCurrTestTime}
//         &session_que_time=&reload_Page=R&sl=${userInfo?.sl}&activeStudentCheck=${userInfo?.activeStudentCheck}
//         &oneLineStrategy=${userInfo?.oneLineStrategy}&grammarAi=${userInfo?.grammarAi}&test_token=${userInfo?.test_token}&favicon=${userInfo?.favicon}`;
//         let encoded = encodeURIComponent(uri);
//         let myPromise = new Promise(function (myResolve, myReject, mypending) {
//           window.location.replace(`${window.origin}/oet?${encoded}`);
//           mypending(setLoading1(true));
//         });
//         myPromise.then(() => setLoading1(false));
//       } else if (userInfo?.type === "M") {
//         console.log(userInfo?.oneLineStrategy);
//         let uri = `id=${userInfo?.id}&type=${userInfo?.type}&userType=${
//           userInfo?.userType
//         }&token=${userInfo?.api_token}&scoreStatus=No&testID=${
//           userInfo?.testID
//         }&testStatus=P&session_time=${
//           getCurrTestTimeForlocal?.getCurrTestTime
//         }&session_que_time=&reload_Page=R&question_no=${[
//           questionData
//         ]}&iSpeed=${userInfo?.internet_speed}&sl=${userInfo?.sl}&section_type=${
//           userInfo?.section_type
//         }&oneLineStrategy=${userInfo?.oneLineStrategy}
//           &grammarAi=${userInfo?.grammarAi}&test_token=${
//           userInfo?.test_token
//         }&favicon=${userInfo?.favicon}`;

//         localStorage.setItem("reload", JSON.stringify({ reloadStatus: "R" }));
//         localStorage.setItem("isLoggedIn", JSON.stringify(true));
//         let encoded = encodeURIComponent(uri);

//         let myPromise = new Promise(function (myResolve, myReject, mypending) {
//           window.location.replace(`${window.origin}/oet?${encoded}`);
//           mypending(setLoading1(true));
//         });
//         myPromise.then(() => setLoading1(false));
//       }
//     }

//     if (
//       (searchParams.get("reload_Page") === "R" ||
//         stateFormageRelodeVariable?.reloadStatus === "R") &&
//       searchParams.get("type") === "M"
//     ) {
//       localStorage.setItem(
//         "userInfo",
//         JSON.stringify({
//           id: searchParams.get("id"),
//           type: searchParams.get("type"),
//           userType: searchParams.get("userType"),
//           api_token: searchParams.get("token"),
//           test_token: searchParams.get("test_token"),
//           question_type: searchParams.get("question_type"),
//           time: searchParams.get("time"),
//           scoreStatus: searchParams.get("scoreStatus"),
//           testID: searchParams.get("testID"),
//           testStatus: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           session_que_time: searchParams.get("session_que_time"),
//           qtypResultScore: searchParams.get("qtypResultScore"),
//           qtypAnalysisScore: searchParams.get("qtypAnalysisScore"),
//           internet_speed: searchParams.get("iSpeed"),
//           title: searchParams.get("title")
//             ? searchParams.get("title")
//             : "Prep27",
//           sl: searchParams.get("sl"),
//           section_type: searchParams.get("section_type"),
//           activeStudentCheck: searchParams.get("activeStudentCheck"),
//           oneLineStrategy: searchParams.get("oneLineStrategy"),
//           grammarAi: searchParams.get("grammarAi"),
//           favicon: searchParams.get("favicon") //add by himanshi
//         })
//       );
//       let tempQuestionData = searchParams.get("question_no");
//       if (tempQuestionData && tempQuestionData.length > 0) {
//         localStorage.setItem(
//           "questionData",
//           JSON.stringify(tempQuestionData.split(","))
//         );
//       }
//       localStorage.setItem("isLoggedIn", JSON.stringify(true));
//       navigate("/oet/MockTest", {
//         state: {
//           sectionType: "Speaking",
//           testID: searchParams.get("testID"),
//           test_status: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           testType: searchParams.get("type")
//             ? searchParams.get("type")
//             : userInfo?.type,
//           sectionType: "Speaking",
//           PageReloadStatus: "R"
//         }
//       });
//     } else if (
//       searchParams.get("type") === "P" &&
//       searchParams.get("sectionType") === "L" &&
//       (searchParams.get("reload_Page") === "R" ||
//         stateFormageRelodeVariable?.reloadStatus === "R")
//     ) {
//       localStorage.setItem(
//         "userInfo",
//         JSON.stringify({
//           id: searchParams.get("id"),
//           type: searchParams.get("type"), // M = mock ,
//           userType: searchParams.get("userType"),
//           api_token: searchParams.get("token"),
//           test_token: searchParams.get("test_token"),
//           question_type: searchParams.get("question_type"),
//           time: searchParams.get("time"),
//           scoreStatus: searchParams.get("scoreStatus"),
//           testID: searchParams.get("testID"),
//           testStatus: searchParams.get("testStatus"), // A= Fresh Test, P= Resume Test
//           session_time: searchParams.get("session_time"), //To resume mock test and practice test with saved session time
//           session_que_time: searchParams.get("session_que_time"),
//           qtypResultScore: searchParams.get("qtypResultScore"),
//           qtypAnalysisScore: searchParams.get("qtypAnalysisScore"),
//           sl: searchParams.get("sl"),
//           activeStudentCheck: searchParams.get("activeStudentCheck"),
//           oneLineStrategy: searchParams.get("oneLineStrategy"),
//           grammarAi: searchParams.get("grammarAi"),
//           favicon: searchParams.get("favicon") //add by himanshi
//         })
//       );
//       let tempQuestionData = searchParams.get("question_no");
//       if (tempQuestionData && tempQuestionData.length > 0) {
//         localStorage.setItem(
//           "questionData",
//           JSON.stringify(tempQuestionData.split(","))
//         );
//       }
//       localStorage.setItem("isLoggedIn", JSON.stringify(true));

//       localStorage.setItem("sectionType", "Listening");
//       navigate("/oet/ListeningSection", {
//         state: {
//           sectionType: "Listening",
//           testID: searchParams.get("testID"),
//           test_status: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           testType: searchParams.get("type")
//             ? searchParams.get("type")
//             : userInfo?.type,
//           PageReloadStatus: "R"
//         }
//       });
//     } else if (
//       searchParams.get("type") === "P" &&
//       searchParams.get("sectionType") === "S" &&
//       stateFormageRelodeVariable?.reloadStatus === "R"
//     ) {
//       localStorage.setItem(
//         "userInfo",
//         JSON.stringify({
//           id: searchParams.get("id"),
//           type: searchParams.get("type"),
//           userType: searchParams.get("userType"),
//           api_token: searchParams.get("token"),
//           test_token: searchParams.get("test_token"),
//           question_type: searchParams.get("question_type"),
//           time: searchParams.get("time"),
//           scoreStatus: searchParams.get("scoreStatus"),
//           testID: searchParams.get("testID"),
//           testStatus: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           session_que_time: searchParams.get("session_que_time"),
//           qtypResultScore: searchParams.get("qtypResultScore"),
//           qtypAnalysisScore: searchParams.get("qtypAnalysisScore"),
//           sl: searchParams.get("sl"),
//           activeStudentCheck: searchParams.get("activeStudentCheck"),
//           oneLineStrategy: searchParams.get("oneLineStrategy"),
//           grammarAi: searchParams.get("grammarAi"),
//           favicon: searchParams.get("favicon") //add by himanshi
//         })
//       );
//       let tempQuestionData = searchParams.get("question_no");
//       if (tempQuestionData && tempQuestionData.length > 0) {
//         localStorage.setItem(
//           "questionData",
//           JSON.stringify(tempQuestionData.split(","))
//         );
//       }
//       localStorage.setItem("isLoggedIn", JSON.stringify(true));
//       localStorage.setItem("sectionType", "Speaking");
//       navigate("/oet/SpeakingSection", {
//         state: {
//           sectionType: "Speaking",
//           testID: searchParams.get("testID"),
//           test_status: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           testType: searchParams.get("type")
//             ? searchParams.get("type")
//             : userInfo?.type,
//           PageReloadStatus: "R"
//         }
//       });
//     } else if (
//       searchParams.get("type") === "P" &&
//       searchParams.get("sectionType") === "R" &&
//       (searchParams.get("reload_Page") === "R" ||
//         stateFormageRelodeVariable?.reloadStatus === "R")
//     ) {
//       localStorage.setItem(
//         "userInfo",
//         JSON.stringify({
//           id: searchParams.get("id"),
//           type: searchParams.get("type"),
//           userType: searchParams.get("userType"),
//           api_token: searchParams.get("token"),
//           test_token: searchParams.get("test_token"),
//           question_type: searchParams.get("question_type"),
//           time: searchParams.get("time"),
//           scoreStatus: searchParams.get("scoreStatus"),
//           testID: searchParams.get("testID"),
//           testStatus: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           session_que_time: searchParams.get("session_que_time"),
//           qtypResultScore: searchParams.get("qtypResultScore"),
//           qtypAnalysisScore: searchParams.get("qtypAnalysisScore"),
//           sl: searchParams.get("sl"),
//           activeStudentCheck: searchParams.get("activeStudentCheck"),
//           oneLineStrategy: searchParams.get("oneLineStrategy"),
//           grammarAi: searchParams.get("grammarAi"),
//           favicon: searchParams.get("favicon") //add by himanshi
//         })
//       );
//       let tempQuestionData = searchParams.get("question_no");
//       if (tempQuestionData && tempQuestionData.length > 0) {
//         localStorage.setItem(
//           "questionData",
//           JSON.stringify(tempQuestionData.split(","))
//         );
//       }
//       localStorage.setItem("isLoggedIn", JSON.stringify(true));
//       localStorage.setItem("sectionType", "Reading");
//       navigate("/oet/ReadingSection", {
//         state: {
//           sectionType: "Reading",
//           testID: searchParams.get("testID"),
//           test_status: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           testType: searchParams.get("type")
//             ? searchParams.get("type")
//             : userInfo?.type,
//           PageReloadStatus: "R"
//         }
//       });
//     } else if (
//       searchParams.get("type") === "P" &&
//       searchParams.get("sectionType") === "W" &&
//       (searchParams.get("reload_Page") === "R" ||
//         stateFormageRelodeVariable?.reloadStatus === "R")
//     ) {
//       localStorage.setItem(
//         "userInfo",
//         JSON.stringify({
//           id: searchParams.get("id"),
//           type: searchParams.get("type"),
//           userType: searchParams.get("userType"),
//           api_token: searchParams.get("token"),
//           test_token: searchParams.get("test_token"),
//           question_type: searchParams.get("question_type"),
//           time: searchParams.get("time"),
//           scoreStatus: searchParams.get("scoreStatus"),
//           testID: searchParams.get("testID"),
//           testStatus: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           session_que_time: searchParams.get("session_que_time"),
//           qtypResultScore: searchParams.get("qtypResultScore"),
//           qtypAnalysisScore: searchParams.get("qtypAnalysisScore"),
//           sl: searchParams.get("sl"),
//           activeStudentCheck: searchParams.get("activeStudentCheck"),
//           oneLineStrategy: searchParams.get("oneLineStrategy"),
//           grammarAi: searchParams.get("grammarAi"),
//           favicon: searchParams.get("favicon") //add by himanshi
//         })
//       );
//       let tempQuestionData = searchParams.get("question_no");
//       if (tempQuestionData && tempQuestionData.length > 0) {
//         localStorage.setItem(
//           "questionData",
//           JSON.stringify(tempQuestionData.split(","))
//         );
//       }
//       localStorage.setItem("isLoggedIn", JSON.stringify(true));
//       localStorage.setItem("sectionType", "Writing");
//       navigate("/oet/WritingSection", {
//         state: {
//           sectionType: "Writing",
//           testID: searchParams.get("testID"),
//           test_status: searchParams.get("testStatus"),
//           session_time: searchParams.get("session_time"),
//           testType: searchParams.get("type")
//             ? searchParams.get("type")
//             : userInfo?.type,
//           PageReloadStatus: "R"
//         }
//       });
//     }
//   };
//   useEffect(() => {
//     if (
//       typeOfPartsIndex &&
//       typeOfPartsIndex?.typeOfPartsIndex?.includes("KeyCode")
//     ) {
//       doNotShowInspect();
//       handleClearLocalStorageData();
//       navigate("/oet/KeyCode", {
//         // state: { nextsectionType: "KeyCode" }
//       });

//       // navigate("/oet/IntroductionSection", {
//       //   state: { nextsectionType: "SpeakingSection" }
//       // });
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem(
//       "position",
//       JSON.stringify({
//         typeOfPartsIndex: `KeyCode`
//       })
//     );
//   }, []);

//   const onTimeout = () => {
//     setShowAlert(true);
//   };
//   const onExpired = () => {
//     setShowAlert(true);
//   };
//   // student_id,institute_id,test_type,last_active_at

//   // useEffect(() => {
//   //   const interval = setInterval(() => {
//   //     let userInfo = JSON.parse(localStorage.getItem("userInfo"));

//   //     let dataObj = {
//   //       student_id: userInfo?.id,
//   //       test_type:
//   //         userInfo?.type === "P" ? "S" : userInfo?.type === "M" ? "M" : "Q"
//   //     };
//   //     if (
//   //       location.pathname !== "/oet/QuestionTypeTestResultView" &&
//   //       location.pathname !== "/oet"
//   //     ) {
//   //       checkInstituteActiveStudentAPI(dataObj);
//   //     }
//   //   }, 270000);

//   //   if (
//   //     location.pathname === "/oet/QuestionTypeTestResultView" ||
//   //     location.pathname === "/oet"
//   //   ) {
//   //     clearInterval(interval);
//   //   }
//   //   return () => clearInterval(interval);
//   // }, [location]);

//   const checkInstituteActiveStudentAPI = (requestObj) => {
//     let userInfo = JSON.parse(localStorage.getItem("userInfo"));

//     const header = {
//       Accept: "application/json",
//       "user-id": userInfo?.id,
//       "api-token": userInfo?.api_token,
//       "test-token": userInfo?.test_token
//     };

//     dispatch(
//       checkInstituteActiveStudent({
//         dataList: requestObj,
//         header: header,
//         onSuccess: (response) => {
//           // setTranScriptStatus("N");
//         },
//         onFailure: (error) => {
//           // setBtnLoading(false);
//           // setBtnIsDisable(false);
//           // setHandlecurrentStatus(true);
//           // setSelectedQautionIndex1(selectedQautionIndex);
//           // setIsHandelNext(false);
//         }
//       })
//     );
//   };

//   return (
//     <div className="prevent-select">
//       {loading === true ? (
//         <AppLoader title={defaultMessages.redirect} />
//       ) : loading1 === true ? (
//         <AppLoader
//           title={"Please wait you will redirect on your questions..."}
//         />
//       ) : (
//         <>{isLoggedIn === null && <Routing showAlert={showAlert} />}</>
//       )}
//       {showAlert === true && (
//         <CustomAlert
//           showAlert={showAlert}
//           handleCloseAlert={handleCloseAlert}
//           title={defaultMessages.timeOutTitle}
//           message={defaultMessages.sessionTimeOut}
//           status={defaultMessages.eventTimeOutStatus}
//           setResponseForSubmitLoading={""}
//         />
//       )}
//     </div>
//     // <div>hello</div>
//   );
// };

// export default App;

function App() {
  const typeOfPartsIndex = JSON.parse(localStorage.getItem("position"));
  const navigate = useNavigate();
  const location = useLocation();
  let getURLQueryString = new URLSearchParams(window?.location?.search);
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  for (const entry of getURLQueryString.entries()) {
    queryString = entry[0];
  }
  let searchParams = new URLSearchParams(queryString);
  // For clear LocalStorage and IndexDB data
  const handleClearLocalStorageData = () => {
    if (searchParams?.get("reload_Page") !== "R") {
      localStorage.clear();
      sessionStorage.clear();
      advancedOETDatabase.speakingTestAnswer.clear();
      advancedOETDatabase.writingTestAnswer.clear();
      advancedOETDatabase.readingTestAnswer.clear();
      advancedOETDatabase.listeningTestAnswer.clear();
      advancedOETDatabase.customTestAnswer.clear();
      advancedOETDatabase.speakingTestQuestions.clear();
      advancedOETDatabase.writingTestQuestions.clear();
      advancedOETDatabase.readingTestQuestions.clear();
      advancedOETDatabase.listeningTestQuestions.clear();
      advancedOETDatabase.customTestQuestions.clear();
      advancedOETDatabase.retryQueTypeData.clear();
    }
  };

  // useEffect(() => {
  //   if (
  //     typeOfPartsIndex &&
  //     typeOfPartsIndex?.typeOfPartsIndex?.includes("KeyCode")
  //   ) {
  //     doNotShowInspect();
  //     handleClearLocalStorageData();
  //     navigate("/oet/KeyCode", {
  //       // state: { nextsectionType: "KeyCode" }
  //     });

  //     // navigate("/oet/IntroductionSection", {
  //     //   state: { nextsectionType: "SpeakingSection" }
  //     // });
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem(
  //     "position",
  //     JSON.stringify({
  //       typeOfPartsIndex: `KeyCode`
  //     })
  //   );
  // }, []);

  React.useEffect(() => {
    doNotShowInspeact();
    if (performance.navigation.type !== performance.navigation.TYPE_RELOAD) {
      handleClearLocalStorageData();
    }
    if (searchParams.get("title")) {
      document.title = searchParams.get("title");
    } else if (userInfo?.title) {
      document.title = userInfo?.title;
    }

    if (location?.pathname === "/oet/" || location?.pathname === "/oet") {
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          id: searchParams.get("id"),
          type: searchParams.get("type"),
          userType: searchParams.get("userType"),
          api_token: searchParams.get("token"),
          scoreStatus: searchParams.get("scoreStatus"),
          testID: searchParams.get("testID"),
          testStatus: searchParams.get("testStatus"),
          session_time: searchParams.get("session_time"),
          session_que_time: searchParams.get("session_que_time"),
          candidateNo: searchParams.get("candidateNo"),
          birthday: searchParams.get("birthday"),
          internet_speed: searchParams.get("iSpeed"),
          name: searchParams.get("name"),
          sectionType: searchParams.get("sectionType"),
          section_type: searchParams.get("section_type"),
          title: searchParams.get("title")
            ? searchParams.get("title")
            : "Prep27",
          logo: searchParams.get("logo"),
          speakingTest: searchParams.get("speakingTest"),
          test_token: searchParams.get("test_token")
        })
      );
      if (searchParams.get("type") === "M") {
        if (
          searchParams.get("section_type") === "Listening" ||
          !searchParams.get("section_type")
        ) {
          navigate("/oet/IntroductionSection", {
            state: { nextsectionType: "ListeningSection" }
          });
        } else if (searchParams.get("section_type") === "Reading") {
          navigate("/oet/IntroductionSection", {
            state: { nextsectionType: "ReadingSection" }
          });
        } else if (searchParams.get("section_type") === "Writing") {
          navigate("/oet/IntroductionSection", {
            state: { nextsectionType: "WritingSection" }
          });
        } else if (searchParams.get("section_type") === "Speaking") {
          //add by himanshi
          navigate("/oet/IntroductionSection", {
            state: { nextsectionType: "SpeakingSection" }
          });
        }
      } else if (searchParams.get("type") === "P") {
        if (searchParams.get("sectionType") === "L") {
          navigate("/oet/HeadphoneCheck", {
            state: {
              sectionType: "Listening"
            }
          });
        } else if (searchParams.get("sectionType") === "R") {
          navigate("/oet/Instructions", {
            state: {
              sectionType: "Reading"
            }
          });
        } else if (searchParams.get("sectionType") === "W") {
          navigate("/oet/Instructions", {
            state: {
              sectionType: "Writing"
            }
          });
        } else if (searchParams.get("sectionType") === "S") {
          navigate("/oet/HeadphoneCheck", {
            state: {
              sectionType: "Speaking"
            }
          });
        }
      }
    }

    // disable cut copy paste in windows

    // document.onkeydown = function (e) {
    //   if (e.ctrlKey && e.key === "c") {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.key === "C") {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.key === "v") {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.key === "V") {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.key === "x") {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.key === "X") {
    //     return false;
    //   }
    // };
  }, []);

  return (
    <div>
      <Routing />
    </div>
  );
}

export default App;
