import axios from "axios";
import { ACTION_TYPE } from "./Type";
import { Constants } from "../../utilities/ApiConstant";

// Get question data
export const getAllQuestionsListTemp =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();

    formData.append("user_id", "15467");
    formData.append("test_type", "M");
    formData.append("test_id", "1");
    formData.append("section_type", "listening");
    formData.append("test_status", "A");
    // formData.append("internet_speed", userInfo?.internet_speed);
    // if (userInfo?.testStatus === "PART_A") {
    //   formData.append("test_start", true);
    // }

    const header = {
      Accept: "application/json",
      "user-id": 15467,
      "api-token":
        "0109f8766c82de2e2a5a7de4fb327193a8582a1a26cd7611189a8e359ed8a24d"
      // "test-token": userInfo?.test_token
    };
    dispatch({ type: ACTION_TYPE.GET_AllQUESTIONS_LIST_REQUEST });
    try {
      await axios
        .post(Constants.apiBaseUrl + Constants.ApiAction.questions, formData, {
          headers: header
        })
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.GET_AllQUESTIONS_LIST_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.GET_AllQUESTIONS_LIST_FAILURE,
        error: error
      });
      onFailure(error);
    }
  };

export const getAllQuestionsList =
  ({ dataList, header, onSuccess, onFailure }) =>
  async (dispatch) => {
    dispatch({ type: ACTION_TYPE.GET_AllQUESTIONS_LIST_REQUEST });

    const formData = new FormData();
    formData.append("user_id", dataList?.user_id);
    formData.append("test_id", dataList?.test_id);
    formData.append("section_type", dataList?.section_type);
    formData.append("test_type", dataList?.test_type);
    formData.append("test_status", dataList?.test_status);
    formData.append("part", dataList?.part);
    formData.append("internet_speed", dataList?.internet_speed);
    try {
      await axios
        .post(Constants.apiBaseUrl + Constants.ApiAction.questions, formData, {
          headers: header
        })
        .then((response) => {
          dispatch({
            type: ACTION_TYPE.GET_AllQUESTIONS_LIST_SUCCESS,
            payload: response.data,
            statusCode: response.statusCode
          });
          onSuccess(response);
        });
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.GET_AllQUESTIONS_LIST_FAILURE,
        error: error
      });

      onFailure(error);
    }
  };
