// All section names and All question type short and long names
export const questionSectionTypesTitle = {
  AITest: "AI Test",
  mockTest: "Mock Test",
  practiceTest: "Practice Test",
  questionTypeTest: "Question Type Test",
  speakingSection: {
    sectionType: "Speaking",
    s_ra: "s_ra",
    readAloud: "Read Aloud",
    s_rs: "s_rs",
    repeatSentence: "Repeat Sentence",
    s_di: "s_di",
    describeImage: "Describe Image",
    s_rtl: "s_rtl",
    retellLecture: "Retell Lecture",
    s_asq: "s_asq",
    answerShot: "Answer Short Questions"
  },
  writingSection: {
    sectionType: "Writing",
    w_es: "w_es",
    essay: "Essay Writing",
    w_swt: "w_swt",
    summarizeWrittenText: "Summarize Written"
  },
  readingSection: {
    sectionType: "Reading",
    r_rwfb: "r_rwfb",
    readingWritingFillBlanks: "Reading and Writing:Fill in the blanks",
    r_mcma: "r_mcma",
    choiceMultipleAns: "Multiple-choice,Choose multiple answers",
    r_rop: "r_rop",
    reOrderParagraph: "Re-order Paragraphs",
    r_fb: "r_fb",
    fillBlanks: "Reading:Fill in the blanks",
    r_mcsa: "r_mcsa",
    choiceSingleAns: "Multiple-choice,choose single answers"
  },
  listeningSection: {
    sectionType: "Listening",
    l_sst: "l_sst",
    summarizeSpoken: "Summarize Spoken item",
    l_mcma: "l_mcma",
    choiceMultipleAns: "Multiple-choice,Choose multiple answers",
    l_fb: "l_fb",
    fillBlanks: "Fill in the blanks",
    l_hcs: "l_hcs",
    highlightCorSummary: "Highlight correct summary",
    l_mcsa: "l_mcsa",
    choiceSingleAns: "Multiple-choice,choose single answers",
    l_smw: "l_smw",
    selectMissingWord: "Select missing word item",
    l_hiw: "l_hiw",
    highlightIncWords: "Highlight Incorrect Word",
    l_wfd: "l_wfd",
    writeDictation: "Write From Dictations"
  }
};

// Api calling, loader, and Alert popup messages
export const defaultMessages = {
  writingStart: "Please wait writing section will be start in few seconds...",
  readingStart: "Please wait reading section will be start in few seconds...",
  ListeningStart:
    "Please wait listening section will be start in few seconds...",
  userLogin: "You are not select any test. So please select test..!",
  examStart: "Please wait test will be started in few seconds...",
  answerSubmit: "Please wait your answer submission is under process...",
  resultGenerate: "Please wait Your Result is being generate...",
  redirect: "Please wait you are being redirection to your result page...",
  redirectDashboard:
    "Your test answers are being submitted to your institute for review and marking, you will be able to check the marks as its completed by your institute.",
  redirectManualScore:
    "Please wait you are being redirection to your manual score...",
  redirectNextPage: "Are you sure want to move to next question?",
  // Alert Titles
  timeOutTitle: "Time out",
  simpleAlertTitle: "Alert",
  queTimeOutTitle: "Oops... You didn't speak within 3 seconds",

  // Time out messages
  timeOutSpeaking: "Your 'Speaking' module time is over..!",
  timeOutListening: "Your 'Listening' module time is over..!",
  timeOutReading: "Your 'Reading' module time is over..!",
  timeOutWriting: "Your 'Writing' module time is over..!",
  timeOut: "Your test time is over..!",
  sessionTimeOut:
    "Your session has timed out..!  Or You are logged in another device with this same user.",
  questionTimeOut:
    "Oops... You didn't speak within 3 seconds. As no human voice detect in the first 3 seconds the question is marked unattempted. Either your Internet connection speed is slow or the microphone is failed. Please try again",

  // Test Status
  apiFailureStatus: "ApiFailure",
  testTimeOutStatus: "TestTimeOut",
  eventTimeOutStatus: "eventTimeOut",
  tokenExpiredStatus: "TokenExpired",
  queTimeOutStatus: "queTimeOutStatus",

  // API Types
  getQuestionAPI: "getQuestionAPI",
  submitAnswerAPI: "submitAnswerAPI",
  typeBySubmitAnsAPI: "typeBySubmitAnsAPI",
  typeByGetQuestionAPI: "typeByGetQuestionAPI",
  checkTypeBySubmitAnsAPI: "checkTypeBySubmitAnsAPI",

  // Microphone check message
  microphoneCheck: "Please setup your audio recording first...",

  // Feed back check message

  feedback_title_1: "What are the main reasons for your rating ?",
  feedback_title: "Feedback",
  feedback_description: "How was your Test Taking Experience? ",
  dont_refresh:
    "Please wait and don't refresh the page, your answers are submitting on server!!",

  most_repeated_titel: "Repeated questions",
  most_repeated_msg: "Do you have seen this question in Pearson PTE exam ?",
  statute_for_most_repeated: "statute_for_most_repeated",

  acc_pearson_popup:
    "According to Pearson if total number of words are, Less than < 40 or More than > 100, then question get zero score."
};
