import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faGear,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "AppContext";
import { rgbToHex } from "utilities/CommonFunc";
import SystemCheck from "views/SystemCheck";
import ConfirmDetails from "views/ConfirmDetails";
import { useLocation } from "react-router-dom";
import AppLoader from "components/CustomLoader";

const KeyCode = (props) => {
  let location = useLocation();

  const [isSystemCheckOpen, setSystemCheckOpen] = useState(false);
  const [isConfirmDetailsOpen, setConfirmDetailsOpen] = useState(false);
  const { theme, setTheme } = useContext(AppContext);

  const [password, setPassword] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [slidervalue, setSliderValue] = useState(100);
  const [storebackgroundColor, setStoreBackgroundColor] = useState("#04a49b");
  const [storeHeaderFooter, setStoreHeaderFooter] = useState("#04a49b");
  const [storeTextColor, setStoreTextColor] = useState("#ffffff");
  const [storeBtnColor, setStoreBtnColor] = useState("#dd9b10");
  const [storeSubHeaderColor, setStoreSubHeaderColor] = useState("#04625d");
  const [storeBTextColor, setStoreBTextColor] = useState("#000000");
  const [storeWTextColor, setStoreWTextColor] = useState("#ffffff");
  const [isClicked, setIsClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(Number(event.target.value));
  };

  const handleOkPreference = () => {
    setTheme(() => ({
      backgroundColor: storebackgroundColor,
      headerFooter: storeHeaderFooter,
      textColor: storeTextColor,
      btnColor: storeBtnColor,
      subHeader: storeSubHeaderColor,
      bTextColor: storeBTextColor,
      wTextColor: storeWTextColor
    }));
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getSliderPercentage = () => {
    if (slidervalue && slidervalue >= 20) {
      return ((slidervalue - 20) / 0.8).toFixed(0);
    }
    return 0;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const keycodeRegex = /^[a-zA-Z0-9]+$/;
    if (value.length <= 8 && keycodeRegex.test(value)) {
      setPassword(value);
    }
  };

  const handlePreferences = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSystemCheck = () => {
    setSystemCheckOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleButtonClick = (event) => {
    setIsClicked(!isClicked);
    const button = event.currentTarget;
    const tableWrapper = button.querySelector(".table-wrapper");

    const backgroundColorRgb =
      window.getComputedStyle(tableWrapper).backgroundColor;
    const textColorRgb = window.getComputedStyle(
      tableWrapper.querySelector("pas-translated-text")
    ).color;

    const backgroundColorHex = rgbToHex(backgroundColorRgb);
    const textColorHex = rgbToHex(textColorRgb);

    setStoreTextColor(textColorHex);
    setStoreBackgroundColor(backgroundColorHex);
  };

  const handleOK = () => {
    const keycodeRegex = /^[a-zA-Z0-9]+$/;
    if (password.length !== 8) {
      setErrorMsg(true);
      setErrorMessage("Please enter your 8 character HTML Keycode.");
    } else if (!keycodeRegex.test(password)) {
      setErrorMsg(true);
      setErrorMessage("Keycode can only contain letters and numbers.");
    } else {
      setErrorMsg(false);
      setErrorMessage("");

      setLoading(true);
      setTimeout(() => {
        setConfirmDetailsOpen(true);
        setLoading(false);
      }, 1500);
    }
  };

  const handleSystemCheckOk = () => {
    setSystemCheckOpen(false);
    setConfirmDetailsOpen(true);
  };

  const handleConfirmDetailsOk = () => {
    setConfirmDetailsOpen(false);
  };

  return (
    <>
      <div>
        <SystemCheck
          openModal={isSystemCheckOpen}
          setModalOpen={setSystemCheckOpen}
          onOk={handleSystemCheckOk}
          setConfirmDetailsOpen={setConfirmDetailsOpen}
        />
        <ConfirmDetails
          openModal={isConfirmDetailsOpen}
          setModalOpen={setConfirmDetailsOpen}
          onOk={handleConfirmDetailsOk}
          setSystemCheckOpen={setSystemCheckOpen}
        />
        <AppLoader modalShow={loading} setModalShow={setLoading} />

        {!isSystemCheckOpen && !isConfirmDetailsOpen && (
          <div className="key-code-screen">
            <div
              className={`sidebar ${isSidebarOpen ? "open" : ""}`}
              aria-labelledby="preferences-heading"
            >
              <div className="preferences-panel-header">
                <span tabIndex={1} className="title-text">
                  <h2 className="" aria-label="Preferences">
                    Preferences
                  </h2>
                </span>
                <button
                  className="mat-tooltip-trigger switcher theme-not-hover-reversed focusable"
                  aria-label="More Options"
                >
                  <span className="fa fa-cog fa-lg" />
                </button>
              </div>
              <div className="preferences-panel-content">
                <div className="color-tab">
                  <div className="predefined-colour-themes theme-picker">
                    <button
                      className={`small-colour-theme-button active-theme${
                        isClicked ? "clicked" : ""
                      }`}
                      onClick={handleButtonClick}
                    >
                      <div
                        className="table-wrapper"
                        style={{ backgroundColor: "#2b9ed8" }}
                      >
                        <pas-translated-text
                          className=""
                          style={{ color: "#ffffff" }}
                        >
                          <span className="translated-text">
                            Default Colour Scheme
                          </span>
                        </pas-translated-text>
                      </div>
                    </button>
                    <div style={{ display: "flex", padding: 0 }}>
                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#04a49b" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#ffffff" }}
                          >
                            <span className="translated-text">
                              Black Text with Cream Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>

                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#ffffff" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#000099" }}
                          >
                            <span className="translated-text">
                              Blue Text with White Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>
                    </div>
                    <div style={{ display: "flex", padding: 0 }}>
                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#ffff00" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#000000" }}
                          >
                            <span className="translated-text">
                              Black Text with Yellow Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>
                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#ffffff" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#000000" }}
                          >
                            <span className="translated-text">
                              Black Text with White Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>
                    </div>
                    <div style={{ display: "flex", padding: 0 }}>
                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#000000" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#ffff00" }}
                          >
                            <span className="translated-text">
                              Yellow Text with Black Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>
                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#000000" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#9898fe" }}
                          >
                            <span className="translated-text">
                              Light blue Text with Black Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>
                    </div>
                    <div style={{ display: "flex", padding: 0 }}>
                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#aefc92" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#000000" }}
                          >
                            <span className="translated-text">
                              Black Text with Light green Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>
                      <button
                        style={{ width: 132 }}
                        className="small-colour-theme-button"
                        onClick={handleButtonClick}
                      >
                        <div
                          className="table-wrapper"
                          style={{ backgroundColor: "#f9addc" }}
                        >
                          <pas-translated-text
                            className=""
                            style={{ color: "#000000" }}
                          >
                            <span className="translated-text">
                              Black Text with Light magenta Background
                            </span>
                          </pas-translated-text>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="contrast-panel">
                  <h3>Contrast</h3>
                  <div className="contrast-range-holder">
                    <span>20%</span>
                    <div className="slider-container">
                      <input
                        type="range"
                        min="20"
                        max="100"
                        value={slidervalue}
                        onChange={handleSliderChange}
                        className="slider"
                        aria-label="Contrast Slider"
                      />
                      <div
                        className="slider-thumb-value"
                        style={{ left: `${(slidervalue - 20) / 0.8}%` }}
                      >
                        <div className="slider-thumb-content"></div>
                        <div className="slider-value-label">{`${getSliderPercentage()}%`}</div>
                      </div>
                    </div>
                    <span>100%</span>
                  </div>
                </div>
              </div>
              <div className="preferences-panel-footer">
                <div className="table-wrapper">
                  <div className="centered-cell-wrapper">
                    <div className="pas-flat-button-container">
                      <button
                        className="mat-focus-indicator mat-tooltip-trigger mat-flat-button mat-button-base pas-flat-button mat-primary"
                        type="button"
                        aria-label="Preference Button"
                        onClick={handleOkPreference}
                      >
                        <span className="mat-button-wrapper">
                          <FontAwesomeIcon
                            icon={faCheck}
                            aria-hidden="true"
                            className="fa fa-check fa-lg"
                          />
                          <span className="translated-text">OK</span>
                        </span>
                        <span className="mat-ripple mat-button-ripple" />
                        <span className="mat-button-focus-overlay" />
                      </button>

                      {/* Cancel Button */}
                      <button
                        className="mat-focus-indicator mat-tooltip-trigger mat-flat-button mat-button-base pas-flat-button mat-primary"
                        type="button"
                        onClick={handleCloseSidebar}
                        aria-label="Cancel Button"
                      >
                        <span className="mat-button-wrapper">
                          <FontAwesomeIcon
                            icon={faTimes}
                            aria-hidden="true"
                            className="fa fa-times fa-lg"
                          />
                          <span className="translated-text">Cancel</span>
                        </span>
                        <span className="mat-ripple mat-button-ripple" />
                        <span className="mat-button-focus-overlay" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="route-container">
              <div className="">
                <div className="key-code-content">
                  <pas-dialog-header className="pas-dialog-header">
                    <div className="mat-dialog-title modal-header">
                      <div className="modal-title-wrapper">
                        <h1 className="modal-title">
                          <pas-translated-text>
                            <span className="translated-text">
                              Enter Keycode
                            </span>
                          </pas-translated-text>
                        </h1>
                        <div className="modal-title-additional-info" />
                      </div>
                    </div>
                  </pas-dialog-header>
                  <h2
                    className="theme-bordered"
                    style={{
                      backgroundColor: theme.backgroundColor,
                      color: theme.textColor
                    }}
                  >
                    <pas-translated-text>
                      <span className="translated-text">Test Delivery</span>
                    </pas-translated-text>
                  </h2>
                  <div id="keycode-form-container">
                    <form
                      className="ng-pristine ng-valid ng-touched"
                      id="enter-keycode"
                    >
                      <label>
                        <pas-translated-text className="translated-text">
                          Please enter your 8 character HTML Keycode.
                        </pas-translated-text>
                      </label>
                      <div className="input-wrapper">
                        <div className="input-text-wrapper highlighter">
                          <input
                            type="password"
                            value={password}
                            onChange={handleChange}
                            maxLength="8"
                            //   placeholder="Enter 8 characters"
                          />
                        </div>
                        <pas-flat-button className="pas-flat">
                          <button
                            className="mat-focus-indicator mat-tooltip-trigger mat-flat-button mat-button-base pas-flat-button pas-button-lg mat-primary"
                            type="button"
                            style={{
                              backgroundColor: theme.backgroundColor,
                              color: theme.textColor
                            }}
                            onClick={handleOK}
                          >
                            <span className="mat-button-wrapper">
                              <FontAwesomeIcon
                                icon={faCheck}
                                aria-hidden="true"
                              />
                              <pas-translated-text className="">
                                <span className="translated-text">OK</span>
                              </pas-translated-text>
                            </span>
                            <span className="mat-ripple mat-button-ripple" />
                            <span className="mat-button-focus-overlay" />
                          </button>
                        </pas-flat-button>
                      </div>
                    </form>
                  </div>
                  {errorMsg && (
                    <div className="keycore-alert-container">
                      <div className="keycore-alert">
                        <label style={{ fontWeight: "600", fontSize: "16px" }}>
                          Invalid Keycode:
                        </label>
                        <label
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: 1.5
                          }}
                        >
                          <pas-translated-text className="translated-text">
                            {errorMessage}
                          </pas-translated-text>
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="options">
                    <div className="common-buttons">
                      <pas-flat-button
                        className="pas-flat"
                        style={{ margin: "5px 7px" }}
                      >
                        <button
                          className="mat-focus-indicator mat-tooltip-trigger mat-flat-button mat-button-base pas-flat-button pas-button-small mat-accent"
                          type="button"
                          onClick={handleSystemCheck}
                        >
                          <span className="mat-button-wrapper">
                            <FontAwesomeIcon
                              icon={faCheck}
                              aria-hidden="true"
                            />
                            <pas-translated-text className="">
                              <span className="translated-text">
                                System Check
                              </span>
                            </pas-translated-text>
                          </span>
                          <span
                            matripple=""
                            className="mat-ripple mat-button-ripple"
                          />
                          <span className="mat-button-focus-overlay" />
                        </button>
                      </pas-flat-button>
                      <pas-flat-button
                        className="pas-flat"
                        style={{ margin: "5px 7px" }}
                      >
                        <button
                          className="mat-focus-indicator mat-tooltip-trigger mat-flat-button mat-button-base pas-flat-button pas-button-small mat-accent"
                          type="button"
                          disabled={isSidebarOpen}
                          onClick={handlePreferences}
                        >
                          <span className="mat-button-wrapper">
                            <span className="fa fa-cog fa-lg" />
                            <FontAwesomeIcon icon={faGear} aria-hidden="true" />

                            <pas-translated-text className="">
                              <span className="translated-text">
                                Preferences
                              </span>
                            </pas-translated-text>
                          </span>
                          <span className="mat-ripple mat-button-ripple" />
                          <span className="mat-button-focus-overlay" />
                        </button>
                      </pas-flat-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default KeyCode;
